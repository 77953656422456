<app-content-header title="{{ 'VIEWS.ADMIN_CONSOLE.TITLE' | translate }}"></app-content-header>

<accordion>
  <accordion-group #userGroup (isOpenChange)="updateUserData(userGroup.isOpen)" [isOpen]="!isMobileView()" class="mb-2">
    <div accordion-heading class="clearfix pointer">
      <span>
        <i [ngClass]="{ 'fa-minus': userGroup.isOpen , 'fa-plus': !userGroup.isOpen}" class="fa"></i>
        {{ 'VIEWS.ADMIN_CONSOLE.USER_MANAGEMENT' | translate }}
      </span>
    </div>

    <div>
      <button (click)="resetLoginAttempts()" [disabled]="blockedIpAdressCount===0" class="btn btn-sm btn-default "
              id="btn-reset-login-attempts">
        {{ 'VIEWS.ADMIN_CONSOLE.RESET_LOGIN_ATTEMPTS' | translate }}: {{blockedIpAdressCount}}
      </button>

      <button (click)="changeMaintenanceMode()" class="btn btn-sm btn-default ml-2" id="btn-switch-maintenance-mode">
        {{ 'VIEWS.ADMIN_CONSOLE.MAINTENANCE_' + (isMaintenanceMode() ? 'DEACTIVATE' : 'ACTIVATE') | translate }}
      </button>

      <button (click)="resetUserConditionsFlag()" class="btn btn-sm btn-default ml-2"
              id="btn-reset-use-conditions"
              title="{{'VIEWS.ADMIN_CONSOLE.RESET_USE_CONDITIONS_INFO' | translate }}">
        {{ 'VIEWS.ADMIN_CONSOLE.RESET_USE_CONDITIONS' | translate }}
      </button>

      <button (click)="setAllCustomerTriggerFlag()"
              class="btn btn-sm btn-default ml-2"
              id="btn-sync-customer-trigger"
              title="{{'VIEWS.ADMIN_CONSOLE.ALL_CUSTOMER_TRIGGER_FLAG_INFO' | translate }}">
        {{ 'VIEWS.ADMIN_CONSOLE.ALL_CUSTOMER_TRIGGER_FLAG' | translate }}
      </button>
    </div>

    <div class="mt-4 mb-2 ml-1 row ">
      <input (keyup)="filterUserTable()" [(ngModel)]="filterKundennummer" class="form-control col-4 mr-2"
             id="input-kundennummer-filter"
             placeholder="{{ 'VIEWS.ADMIN_CONSOLE.FILTER_KUNDENNUMMER' | translate }}" type="text">

      <input (keyup)="filterUserTable()" [(ngModel)]="filterBetriebsschluessel" class="form-control col-4"
             id="input-betriebnummer-filter"
             placeholder="{{ 'VIEWS.ADMIN_CONSOLE.FILTER_BETRIEBSSCHLUESSEL' | translate }}" type="text">
    </div>

    <ngx-datatable #userTable [columnMode]="'force'" [footerHeight]="50" [headerHeight]="40" [limit]="12"
                   [messages]="tableMessages" [reorderable]="false"
                   [rowHeight]="'auto'" [rows]="filteredUserRows" [scrollbarH]="true" class="material">

      <ngx-datatable-column [canAutoResize]="false" [draggable]="false" [resizeable]="false" [sortable]="false"
                            [width]="50">
        <ng-template let-expanded="expanded" let-row="row" ngx-datatable-cell-template>
          <i (click)="toggleUserTableExpandRow(row)" [id]="row.id+'-btn-table-toogle-row'"
             [ngClass]="{ 'fa-minus': expanded , 'fa-plus': !expanded}"
             class="fa pointer"></i>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="{{ 'VIEWS.ADMIN_CONSOLE.COMPANY_NUMBER' | translate }}" prop="kunde.kundennummer"
                            width="40"></ngx-datatable-column>
      <ngx-datatable-column name="{{ 'VIEWS.ADMIN_CONSOLE.COMPANY_BETRIEBSSCHLUESSEL' | translate }}"
                            prop="kunde.betriebsschluessel"
                            width="60"></ngx-datatable-column>
      <ngx-datatable-column name="{{ 'VIEWS.ADMIN_CONSOLE.USERNAME' | translate }}" prop="username"
                            width="50"></ngx-datatable-column>

      <ngx-datatable-column name="{{ 'VIEWS.ADMIN_CONSOLE.FULL_NAME' | translate }}">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.prename}} {{row.surname}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="{{ 'VIEWS.ADMIN_CONSOLE.FULL_NAME_COMPANY' | translate }}" prop="kunde">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{value.vorname}} {{value.name}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="{{ 'VIEWS.ADMIN_CONSOLE.COMPANY_NAME' | translate }}"
                            prop="kunde.firma"></ngx-datatable-column>
      <ngx-datatable-column name="{{ 'VIEWS.ADMIN_CONSOLE.EMAIL' | translate }}" prop="email"
                            width="300"></ngx-datatable-column>
      <ngx-datatable-column name="{{ 'VIEWS.ADMIN_CONSOLE.KUNDEN_STATUS' | translate }}" prop="kunde.status"
                            width="100">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div *ngIf="value">
            {{ ('VIEWS.ADMIN_CONSOLE.KUNDE_STATUS.' + value) | translate }}
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="{{ 'GLOBAL_MESSAGES.LAST_UPDATE_AT' | translate }}" prop="lastUpdateAt" width="100">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <small
            title="{{'GLOBAL_MESSAGES.LAST_LOGIN_AT'|translate}}:{{row.lastLoginAt| datexpipe:'DD.MM.YYYY HH:mm:ss'}}">{{value| datexpipe:'DD.MM.YYYY HH:mm:ss'}}</small>
        </ng-template>
      </ngx-datatable-column>

      <!-- Row Detail Template -->
      <ngx-datatable-row-detail [rowHeight]="50">
        <ng-template let-expanded="expanded" let-row="row" ngx-datatable-row-detail-template>
          <div
            *ngIf="!resetPwdRowMap.get(row.uuid) && !enableDisableRowMap.get(row.uuid) && !deleteRowMap.get(row.uuid)&& !deleteDocumentsRowMap.get(row.uuid)">
            <button (click)="showResetPwdInline(row)" [id]="row.id + '-btn-pre-reset-user'"
                    class="btn btn-sm btn-default mr-2">
              {{ 'VIEWS.ADMIN_CONSOLE.RESET_PASSWORD' | translate }}
            </button>
            <button (click)="showdDeleteDocumentsInline(row)" [id]="row.id + '-btn-pre-delete-documents'"
                    class="btn btn-sm btn-default mr-2">
              {{ 'VIEWS.ADMIN_CONSOLE.DELETE_DOCUMENTS' | translate }}
            </button>
            <button (click)="showEnableDisableInline(row)" [id]="row.id + '-btn-pre-deactivate-user'"
                    class="btn btn-sm btn-default mr-2">{{ (row.enabled ?
              'EDIT_MESSAGE.DEACTIVATE' : 'EDIT_MESSAGE.ACTIVATE')| translate }}
            </button>
            <button (click)="showDeleteInline(row)" [id]="row.id + '-btn-pre-delete-user'"
                    class="btn btn-sm btn-default mr-2">{{ 'EDIT_MESSAGE.DELETE' | translate}}
            </button>
            <button (click)="setCustomerTriggerFlag(row)" [id]="row.id + '-btn-pre-trigger-customer'"
                    class="btn btn-sm btn-default">{{ 'EDIT_MESSAGE.CUSTOMER_TRIGGER_FLAG' | translate}}
            </button>
          </div>


          <div>
            <div *ngIf="resetPwdRowMap.get(row.uuid)">
              <small class="mr-2"> {{ 'VIEWS.ADMIN_CONSOLE.PWD_RESET_MSG'|translate }}?</small>
              <button (click)="reinitInlineMaps()" [id]="row.id + '-btn-cancel-reset-user'"
                      class="btn btn-sm btn-secondary mr-2"> {{ 'EDIT_MESSAGE.CANCEL' |
                translate }}
              </button>
              <button (click)="resetPwd(row)" [id]="row.id + '-btn-reset-user'" class="btn btn-sm btn-danger">
                {{ 'EDIT_MESSAGE.RESET' | translate }}
              </button>
            </div>

            <div *ngIf="enableDisableRowMap.get(row.uuid)">
              <small class="mr-2"> {{ 'VIEWS.ADMIN_CONSOLE.ENABLE_DISABLE_MSG'|translate:{
                'action': (row.enabled ? 'EDIT_MESSAGE.DEACTIVATE' :
                  'EDIT_MESSAGE.ACTIVATE')| translate
              } }}?
              </small>
              <button (click)="reinitInlineMaps()" [id]="row.id + '-tn-cancel-deactivate-user'"
                      class="btn btn-sm btn-secondary mr-2">{{ 'EDIT_MESSAGE.CANCEL' |
                translate }}
              </button>
              <button (click)="enableDisable(row)" [id]="row.id + '-btn-deactivate-user'"
                      [ngClass]="{'btn-danger': row.enabled,'btn-success':!row.enabled}"
                      class="btn btn-sm ">
                {{ (row.enabled ? 'EDIT_MESSAGE.DEACTIVATE' : 'EDIT_MESSAGE.ACTIVATE') | translate }}
              </button>
            </div>

            <div *ngIf="deleteRowMap.get(row.uuid)">
              <small class="mr-2"> {{ 'VIEWS.ADMIN_CONSOLE.DELETE_MSG'|translate }}?</small>
              <button (click)="reinitInlineMaps()" [id]="row.id + '-tn-cancel-delete-user'"
                      class="btn btn-sm btn-secondary mr-2">{{ 'EDIT_MESSAGE.CANCEL' |
                translate }}
              </button>
              <button (click)="delete(row)" [id]="row.id + '-btn-delete-user'" class="btn btn-sm btn-danger">
                {{ 'EDIT_MESSAGE.DELETE' | translate }}
              </button>
            </div>

            <div *ngIf="deleteDocumentsRowMap.get(row.uuid)">
              <small class="mr-2"> {{ 'VIEWS.ADMIN_CONSOLE.DELETE_DOCUMENTS_MSG'|translate }}?</small>
              <button (click)="reinitInlineMaps()" [id]="row.id + '-btn-cancel-delete-document'"
                      class="btn btn-sm btn-secondary mr-2">{{ 'EDIT_MESSAGE.CANCEL'
                | translate }}
              </button>
              <button (click)="deleteDocuments(row)" [id]="row.id + '-btn-delete-user'" class="btn btn-sm btn-danger">
                {{ 'EDIT_MESSAGE.DELETE' | translate }}
              </button>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-row-detail>
    </ngx-datatable>
  </accordion-group>

  <accordion-group #kundeStatusDistributionGroup
                   (isOpenChange)="updateKundeStatusDistributionData(kundeStatusDistributionGroup.isOpen)"
                   [isOpen]="false"
                   class="mb-2">
    <div accordion-heading class="clearfix" class="pointer">
      <span>
        <i
          [ngClass]="{ 'fa-minus': kundeStatusDistributionGroup.isOpen , 'fa-plus': !kundeStatusDistributionGroup.isOpen}"
          class="fa"></i>
        {{ 'VIEWS.ADMIN_CONSOLE.KUNDE_STATUS_DISTRIBUTION' | translate }}
      </span>
    </div>

    <div class="w-100 chart-wrapper">
      <ngx-charts-pie-grid *ngIf="kundeStatusDistributionGroup.isOpen" [results]="kundeStatusDistributionResult"
                           [scheme]="chartConfig.colorScheme"
                           class="pie-grid-chart"></ngx-charts-pie-grid>
    </div>

  </accordion-group>

  <accordion-group #jobGroup [isOpen]="false" class="mb-2">
    <div accordion-heading class="clearfix" class="pointer">
      <span>
        <i [ngClass]="{ 'fa-minus': jobGroup.isOpen , 'fa-plus': !jobGroup.isOpen}" class="fa"></i>
        {{ 'VIEWS.ADMIN_CONSOLE.AUTO_JOBS' | translate }}
      </span>
    </div>
    <div class="row">
      <div class="col">
        <p class="job-description"> {{ 'VIEWS.ADMIN_CONSOLE.KUNDENSTAMM_JOB' | translate }}</p>
        <button (click)="triggerKundeImportJob()" class="btn btn-sm btn-default mr-2"
                id="btn-start-kundenimport">{{ 'EDIT_MESSAGE.RUN_NOW' | translate }}
        </button>
      </div>
      <div class="col">
        <p class="job-description"> {{ 'VIEWS.ADMIN_CONSOLE.DOCUMENT_JOB' | translate }}</p>
        <button (click)="triggerDocumentImportJob()" class="btn btn-sm btn-default mr-2"
                id="btn-start-dokumentenimport">{{ 'EDIT_MESSAGE.RUN_NOW' | translate}}
        </button>
      </div>

      <div class="col">
        <p class="job-description"> {{ 'VIEWS.ADMIN_CONSOLE.BESTAND_JOB' | translate }}</p>
        <button (click)="triggerBestandImportJob()" class="btn btn-sm btn-default mr-2"
                id="btn-start-bestandimport">{{ 'EDIT_MESSAGE.RUN_NOW' | translate }}
        </button>
      </div>

      <div class="col">
        <p class="job-description"> {{ 'VIEWS.ADMIN_CONSOLE.LOGGING_DELETION_JOB' | translate }}</p>
        <button (click)="triggerLoggingDeletionJob()" class="btn btn-sm btn-default mr-2"
                id="btn-start-logging-deletion">{{ 'EDIT_MESSAGE.RUN_NOW' | translate}}
        </button>
      </div>
    </div>
  </accordion-group>

  <accordion-group #consoleGroup (isOpenChange)="updateConsoleData(consoleGroup.isOpen)" [isOpen]="false" class="mb-2">
    <div accordion-heading class="clearfix pointer">
      <span>
        <i [ngClass]="{ 'fa-minus': consoleGroup.isOpen , 'fa-plus': !consoleGroup.isOpen}" class="fa"></i>
        {{ 'VIEWS.ADMIN_CONSOLE.CONSOLE_OUTPUT' | translate }}
      </span>
    </div>
    <div>
      <div class="row mb-2">
        <div class="col form-group">
          <button (click)="updateConsoleData(true)" class="btn btn-default btn-sm pointer" id="btn-update-console-data">
            <i class="fa fa-refresh"></i>
          </button>
          {{ 'VIEWS.ADMIN_CONSOLE.PERIOD' | translate }}({{'GLOBAL_MESSAGES.FROM_TO' | translate}})
          <div class="form-group mt-2">
            <input [(ngModel)]="bsLoggingRangeValue" [bsConfig]="datePickerConfig" bsDaterangepicker
                   class="form-control"
                   type="text">
          </div>
        </div>
        <div class="col form-group mt-2">
          <label for="log-level-btn-wrapper"> {{ 'VIEWS.ADMIN_CONSOLE.LOG_LEVEL_TITLE' | translate }}</label>
          <div class="btn-group d-flex" id="log-level-btn-wrapper" role="group">
            <button (click)="toggleLogLevel(logLevelItem);" *ngFor="let logLevelItem of logLevelItemList"
                    [id]="'btn-filter-'+logLevelItem.logLevel"
                    [ngClass]="{'log-level-btn-active': logLevelItem.isActive, 'log-level-btn-inactive' : !logLevelItem.isActive} "
                    class="btn btn-sm btn-outline-success w-100 mx-1"
                    type="button">
              {{'VIEWS.ADMIN_CONSOLE.LOG_LEVEL.' + logLevelItem.logLevel | translate}}
            </button>
          </div>
        </div>
        <div class="col form-group">
          <label for="slider-console-height"> {{ 'VIEWS.ADMIN_CONSOLE.CONSOLE_SIZE' | translate }}
            : {{consoleHeight}}</label>
          <nouislider [(ngModel)]="consoleHeight" [connect]="true" [max]="50" [min]="10" [step]="1"
                      id="slider-console-height"></nouislider>
        </div>
      </div>
      <textarea #console [ngModel]="consoleText" class="console" readonly rows="{{consoleHeight}}">
    </textarea>
    </div>
  </accordion-group>

  <accordion-group #monitoringGroup [isOpen]="false" class="mb-2">
    <div accordion-heading class="clearfix pointer">
      <span>
        <i [ngClass]="{ 'fa-minus': monitoringGroup.isOpen , 'fa-plus': !monitoringGroup.isOpen}" class="fa"></i>
        {{ 'VIEWS.ADMIN_CONSOLE.EXECUTION_TIME_MONITORING' | translate }}
      </span>
    </div>
    <div>
      <div class="col-sm-12 col-md-4 col-lg-3">
        <button (click)="updateMonitoringDurationData(true)" class="btn btn-default btn-sm pointer"
                id="btn-update-monitoring-data">
          <i class="fa fa-refresh"></i>
        </button>
        {{ 'VIEWS.ADMIN_CONSOLE.PERIOD' | translate }}({{'GLOBAL_MESSAGES.FROM_TO' | translate}})
        <div class="form-group mt-2">
          <input [(ngModel)]="bsMonitoringDurationFilterRangeValue" [bsConfig]="datePickerConfig" bsDaterangepicker
                 class="form-control" type="text">
        </div>
      </div>
      <ngx-datatable #exceutionDurationTable [columnMode]="'flex'" [footerHeight]="50"
                     [headerHeight]="40" [limit]="12"
                     [loadingIndicator]="monitoringIdicator" [messages]="tableMessages" [reorderable]="false"
                     [rowHeight]="'auto'"
                     [rows]="monitoringRows" class="material">

        <ngx-datatable-column [canAutoResize]="false" [draggable]="false" [resizeable]="false" [sortable]="false"
                              [width]="50">
          <ng-template let-expanded="expanded" let-row="row" ngx-datatable-cell-template>
            <i (click)="toggleExecutionDurationTableExpandRow(row)" [id]="row.id+'-btn-table-toogle-row'"
               [ngClass]="{ 'fa-minus': expanded , 'fa-plus': !expanded}"
               class="fa pointer"></i>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="2.5" name="{{ 'VIEWS.ADMIN_CONSOLE.SIGNATURE' | translate }}"
                              prop="signature"></ngx-datatable-column>
        <ngx-datatable-column [flexGrow]="1" name="{{ 'VIEWS.ADMIN_CONSOLE.CALL_COUNTER' | translate }}"
                              prop="callCounter"></ngx-datatable-column>
        <ngx-datatable-column [flexGrow]="1" name="{{ 'VIEWS.ADMIN_CONSOLE.MIN_EXECUTION_TIME' | translate }}"
                              prop="minExecutionTime">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{(value / 1000) | number : '1.2'}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [flexGrow]="1" name="{{ 'VIEWS.ADMIN_CONSOLE.MAX_EXECUTION_TIME' | translate }}"
                              prop="maxExecutionTime">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{(value / 1000) | number : '1.2'}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [flexGrow]="1" name="{{ 'VIEWS.ADMIN_CONSOLE.AVG_EXECUTION_TIME' | translate }}"
                              prop="avgExecutionTime">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{(value / 1000) | number : '1.2'}}
          </ng-template>
        </ngx-datatable-column>

        <!-- Row Detail Template -->
        <ngx-datatable-row-detail [rowHeight]="200">
          <ng-template let-expanded="expanded" let-row="row" ngx-datatable-row-detail-template>
            <ngx-charts-pie-grid *ngIf="expanded" [results]="executionDurationPieChartGridDataMap.get(row)"
                                 [scheme]="chartConfig.colorScheme"
                                 class="pie-grid-chart"></ngx-charts-pie-grid>
          </ng-template>
        </ngx-datatable-row-detail>
      </ngx-datatable>

    </div>
  </accordion-group>

  <accordion-group #deviceDistributionGroup
                   (isOpenChange)="updateDeviceDistributionData(deviceDistributionGroup.isOpen)"
                   [isOpen]="false"
                   class="mb-2">
    <div accordion-heading class="clearfix pointer">
      <span>
        <i [ngClass]="{ 'fa-minus': deviceDistributionGroup.isOpen , 'fa-plus': !deviceDistributionGroup.isOpen}"
           class="fa"></i>
        {{ 'VIEWS.ADMIN_CONSOLE.DEVICE_DISTRIBUTION' | translate }}
      </span>
    </div>

    <div class="w-100 chart-wrapper">
      <ngx-charts-pie-grid *ngIf="deviceDistributionGroup.isOpen" [results]="deviceDistributionResult"
                           [scheme]="chartConfig.colorScheme"
                           class="pie-grid-chart"></ngx-charts-pie-grid>
    </div>

  </accordion-group>
  <accordion-group #docFilterGroup (isOpenChange)="updateDocumentFilterData(docFilterGroup.isOpen)"
                   [isOpen]="false">
    <div accordion-heading class="clearfix pointer">
      <span>
        <i [ngClass]="{ 'fa-minus': docFilterGroup.isOpen , 'fa-plus': !docFilterGroup.isOpen}" class="fa"></i>
        {{ 'VIEWS.ADMIN_CONSOLE.DOCUMENT_FILTER.TITLE' | translate }}
      </span>
    </div>
    <div>
      <div class="row">
        <div class="col-2">
          {{ 'VIEWS.ADMIN_CONSOLE.DOCUMENT_FILTER.LABEL' | translate }}:
          <input [(ngModel)]="additionalDocumentFilter.label" class="form-control input-doc-filter"
                 id="addDocFilterLabel" type="text">
        </div>
        <div class="col-2">
          {{ 'VIEWS.ADMIN_CONSOLE.DOCUMENT_FILTER.VALUE' | translate }}:
          <input [(ngModel)]="additionalDocumentFilter.value" class="form-control input-doc-filter"
                 id="addDocFilterValue" type="text">
        </div>
        <div class="col-2">
          <button (click)="saveDocFilter()"
                  class="fa-plus btn btn-sm btn-default btn-add-docfilter"
                  id="btn-save-add-docfilter" title="{{'VIEWS.ADMIN_CONSOLE.DOCUMENT_FILTER.SAVE'|translate}}">
            {{'VIEWS.ADMIN_CONSOLE.DOCUMENT_FILTER.SAVE'|translate}}
          </button>
        </div>
      </div>
    </div>
    <div class="docfilter-area">
      <ngx-datatable #docFilterTable [columnMode]="'force'" [footerHeight]="50" [headerHeight]="40"
                     [limit]="12" [messages]="tableMessages" [reorderable]="false"
                     [rowHeight]="'auto'" [rows]="documentFilterList" [scrollbarH]="true" class="material">

        <ngx-datatable-column [draggable]="false" [sortable]="false"
                              name="{{ 'VIEWS.ADMIN_CONSOLE.DOCUMENT_FILTER.ID' | translate }}" prop="id">
        </ngx-datatable-column>

        <ngx-datatable-column [draggable]="false" [sortable]="false"
                              name="{{'VIEWS.ADMIN_CONSOLE.DOCUMENT_FILTER.LABEL' | translate }}"
                              prop="label"></ngx-datatable-column>
        <ngx-datatable-column [draggable]="false" [sortable]="false"
                              name="{{'VIEWS.ADMIN_CONSOLE.DOCUMENT_FILTER.VALUE' | translate }}"
                              prop="value"></ngx-datatable-column>
        <ngx-datatable-column [draggable]="false" [sortable]="false" [width]=100>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <i (click)="deleteDocFilter(row)" [id]="row.id+'-btn-delete-df'"
               [ngClass]="'fa-close delete-df-btn'" class="fa pointer"></i>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </accordion-group>
</accordion>
