<div class="content-wrapper">
  <div class="container py-5">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6 mx-auto">
            <div *ngIf="registrationSuccessfull">
              <span id="lbl-registration-successfull">{{ 'VIEWS.LOGIN.REGISTRATION_SUCCESSFULL' | translate }}</span>
              <div class=" [ mt-2 ] ">
                <a (click)="resetLoginMode()" class="[ auth-switcher-link ]" id="link-back-to-login">{{ ('VIEWS.LOGIN.BACK_TO_LOGIN') | translate }}</a>
              </div>
            </div>
            <div *ngIf="!registrationSuccessfull">
              <form #loginForm="ngForm" (keyup.enter)="loginForm.form.valid && onLoginClicked()" class="form" id="login-form" role="form">
                <div class="card rounded-0">
                  <div class="card-header">
                    <h4 class="mb-0">{{ 'VIEWS.LOGIN.PLEASE_LOGIN' | translate }}</h4>
                  </div>
                  <div class="card-body">
                    <div *ngIf="!isTokenLogin">
                      <div class="form-group">
                        <label for="login-username">{{ 'VIEWS.LOGIN.USER_NAME' | translate }}</label>
                        <input #loginUsername="ngModel" [(ngModel)]="username" [required]="!isTokenLogin" class="form-control" id="login-username"
                               name="loginUsername" placeholder="{{ 'VIEWS.LOGIN.USER_NAME' | translate }}" type="text">
                      </div>
                      <div class="form-group">
                        <label>{{ 'VIEWS.LOGIN.PASSWORD' | translate }}</label>
                        <input #loginPassword="ngModel" [(ngModel)]="password" [required]="!isTokenLogin" class="form-control" id="login-password"
                               name="loginPassword" placeholder="{{ 'VIEWS.LOGIN.PASSWORD' | translate }}" type="password">
                      </div>
                    </div>

                    <div *ngIf="isTokenLogin">
                      <div class="form-group">
                        <label for="login-token">{{ 'VIEWS.LOGIN.TOKEN' | translate }}</label>
                        <input #loginToken="ngModel" [(ngModel)]="token" [required]="isTokenLogin" class="form-control" id="login-token" name="loginToken"
                               placeholder="{{ 'VIEWS.LOGIN.TOKEN' | translate }}" type="text">
                      </div>
                      <div class="form-group">
                        <label for="login-kundennummer">{{ 'VIEWS.LOGIN.KUNDENNUMMER' | translate }}</label>
                        <input #loginKundennummer="ngModel" [(ngModel)]="kundennummer" [required]="isTokenLogin" class="form-control" id="login-kundennummer"
                               name="loginKundennummer" placeholder="{{ 'VIEWS.LOGIN.KUNDENNUMMER' | translate }}" type="text">
                      </div>
                      <div class="form-group">
                        <label for="login-email-one">{{ 'VIEWS.LOGIN.EMAIL' | translate }}</label>
                        <input #loginEmailOne="ngModel" (blur)="validateEmail()" [(ngModel)]="emailOne" [required]="isTokenLogin" class="form-control" id="login-email-one"
                               name="loginEmailOne" placeholder="{{ 'VIEWS.LOGIN.EMAIL' | translate }}" type="text">
                        <span *ngIf="showEmailError()" class="text-danger" id="email-one-error">{{ 'ERROR_MESSAGES.INVALID_EMAIL' | translate }}
                        </span>
                      </div>
                      <div class="form-group">
                        <label for="login-email-two">{{ 'VIEWS.LOGIN.EMAIL_CHECK' | translate }}</label>
                        <input #loginEmailTwo="ngModel" (blur)="validateEmail()" [(ngModel)]="emailTwo" [required]="isTokenLogin" class="form-control" id="login-email-two"
                               name="loginEmailTwo" placeholder="{{ 'VIEWS.LOGIN.EMAIL_CHECK' | translate }}" type="text">
                        <span *ngIf="!areEmailEqual()" class="text-danger" id="email-equal-error">{{ 'ERROR_MESSAGES.EMAIL_DIFFERENTLY' | translate }}
                        </span>
                        <span *ngIf="areEmailEqual() && !showEmailError &&  !isMailValid(emailTwo)" class="text-danger"
                              id="email-two-error">{{ 'ERROR_MESSAGES.INVALID_EMAIL' |
                          translate }}
                        </span>
                      </div>
                    </div>

                    <button (click)="onLoginClicked()"
                            [disabled]="!loginForm.form.valid || (isTokenLogin ? (!areEmailEqual() || !isMailValid(emailTwo) || !isMailValid(emailOne)):false)"
                            class="btn btn-outline-success btn-block mb-2" id="button-login" type="button">
                      {{ (isTokenLogin ? 'VIEWS.LOGIN.REGISTER' : 'VIEWS.LOGIN.LOGIN')| translate }}
                    </button>

                    <span *ngIf="!isMaintenanceMode() && !isTokenLogin && showLoginError" class="text-danger"
                          id="txt-login-error">{{'ERROR_MESSAGES.LOGIN_FAILED' | translate }}
                    </span>
                    <span *ngIf="!isMaintenanceMode() && isTokenLogin &&  showRegistrationError" class="text-danger"
                          id="txt-registartion-error">{{'ERROR_MESSAGES.REGISTRATION_FAILED' | translate }}
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <span *ngIf="isMaintenanceMode()" class="text-danger" id="txt-maintenance">{{ 'GLOBAL_MESSAGES.MAINTENANCE_MODE' | translate }}</span>
            <a (click)="toggleLoginMode()" *ngIf="!isMaintenanceMode() && !registrationSuccessfull" class="[ auth-switcher-link ]" id="link-switch-login-mode">
              {{ (isTokenLogin ? 'VIEWS.LOGIN.SWITCH_TO_NORMAL_LOGIN' : 'VIEWS.LOGIN.SWITCH_TO_ACTIVATION_CODE_LOGIN') | translate }}
            </a>
            <a *ngIf="!isMaintenanceMode()" [routerLink]="['/reset-pwd']" class="[ auth-switcher-link ] [ pull-right ]" id="link-forgot-pwd">
              {{  'VIEWS.LOGIN.FORGOT_PWD'  | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
