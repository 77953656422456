<div class="pt-3">
  <app-content-header title="{{ 'VIEWS.USER_PROFILE.ASSOCIATED_TAX_CONSULTANT'  | translate }}"></app-content-header>

  <div *ngIf="!enableDetail" class="toolbar">
    <button id="btn-new" (click)="resetFields()" type="button" class="btn btn-sm btn-default">
      {{'EDIT_MESSAGE.NEW' | translate}}
    </button>
  </div>

  <ngx-datatable *ngIf="!enableDetail" #userTable class="material" [rows]="filteredTaxUserRows" [columnMode]="'force'" [headerHeight]="40" [footerHeight]="50"
                 [limit]="12" [rowHeight]="'auto'" [messages]="tableMessages" [reorderable]="false" [scrollbarH]="true">

    <ngx-datatable-column [width]="200" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
      <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
        <i [id]="row.id+'btn-table-toogle-row'" (click)="toggleUserTableExpandRow(row)" class="fa pointer"
           [ngClass]="{ 'fa-minus': expanded , 'fa-plus': !expanded}"></i>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="{{ 'VIEWS.USER_PROFILE.USER_NAME' | translate }}" prop="username"></ngx-datatable-column>
    <ngx-datatable-column name="{{ 'VIEWS.USER_PROFILE.OFFICE' | translate }}" prop="office"></ngx-datatable-column>
    <ngx-datatable-column name="{{ 'VIEWS.USER_PROFILE.EMAIL' | translate }}" prop="email"></ngx-datatable-column>

    <ngx-datatable-row-detail [rowHeight]="50">
      <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
        <div *ngIf="!deleteRowMap.get(row.uuid)">
          <button [id]="row.id + '-btn-pre-delete-user'" (click)="showDeleteInline(row)"
                  class="btn btn-sm btn-default mr-2">{{ 'EDIT_MESSAGE.DELETE' | translate}}
          </button>
        </div>

        <div *ngIf="deleteRowMap.get(row.uuid)">
          <small class="mr-2"> {{ 'VIEWS.ADMIN_CONSOLE.DELETE_MSG'|translate }}?</small>
          <button [id]="row.id + '-btn-cancel-delete-user'" (click)="reinitDeleteInlineFlag()" class="btn btn-sm btn-secondary mr-2">{{ 'EDIT_MESSAGE.CANCEL' |
              translate }}
          </button>
          <button [id]="row.id + '-btn-delete-user'" (click)="deleteCustomerUser(row)" class="btn btn-sm btn-danger">
            {{ 'EDIT_MESSAGE.DELETE' | translate }}
          </button>
        </div>
      </ng-template>
    </ngx-datatable-row-detail>

  </ngx-datatable>

  <div class="clearfix detail-panel" [hidden]="!enableDetail">
    <div class="ml-2" [hidden]="!enableDetail">
      <div class="row">
        <div class="col">
          <app-new-tax-user (afterSave)="saveTaxUser(taxUser)" (onCancel)="cancelTaxUser()"></app-new-tax-user>
        </div>
      </div>
    </div>
  </div>

</div>
