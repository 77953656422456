import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../api/services/auth.service';
import {VostUtils} from '../../core/vost-utils';

@Component({
  selector: 'app-reset-pwd',
  templateUrl: './reset-pwd.component.html',
  styleUrls: ['./reset-pwd.component.css']
})
export class ResetPwdComponent implements OnInit {
  kundennummer: string;
  email: string;
  resetPwd: boolean = false;
  showError: boolean = false;


  constructor(private authService: AuthService) {
  }

  ngOnInit() {
  }

  onPwdResetClicked(): void {
    this.authService.resetPwd(this.email, this.kundennummer).subscribe(res => {
      this.resetPwd = true;
      this.showError = false;
    }, errorRes => {
      this.showError = true;
    });
  }

  isMailValid(mail: string): boolean {
    if (VostUtils.isNotEmpty(mail)) {
      return VostUtils.isMailValid(mail);
    }
    return true;
  }

}
