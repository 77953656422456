import {TranslateService} from '@ngx-translate/core';
import {SharedService} from '../../shared.service';

export class BaseComponent {
  tableMessages = {};

  constructor(private _sharedService: SharedService, private _translateService: TranslateService) {

    this.tableMessages = {
      emptyMessage: this._translateService.instant('TABLE.EMPTY_MESSAGE'),
      totalMessage: this._translateService.instant('TABLE.TOTAL_ROWS_MESSAGE'),
      selectedMessage: this._translateService.instant('TABLE.SELECTED_ROWS_MESSAGE')
    };

  }

  getMessage(key: string): string {
    return this._translateService.instant(key);
  }

  isMobileView() {
    return this._sharedService.isMobileView;
  }

  getNoDataMessage(): string {
    return this._translateService.instant('GLOBAL_MESSAGES.NO_DATA');
  }
}
