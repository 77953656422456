export class FilterItem<T> {
  id: number;
  name: string;
  data: T;
  isActive: boolean;


  constructor(id: number, name: string, data: T, isActive: boolean) {
    this.id = id;
    this.name = name;
    this.data = data;
    this.isActive = isActive;
  }
}
