export enum LogLevel {
  // @formatter:off
    TRACE = <any> 'TRACE',
    DEBUG = <any> 'DEBUG',
    INFO = <any> 'INFO',
    WARN = <any> 'WARN',
    ERROR = <any> 'ERROR',
    FATAL = <any> 'FATAL',
    // @formatter:on
}
