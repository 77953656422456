<app-content-header (onDownload)="downloadSelected()" [downloadBtnDisabled]="selectedRows?.length < 1" [showDownloadBtn]="!isMobileView()"
                    title="{{ 'VIEWS.DOCUMENT.'+ documentTypeKey +'.TITLE' | translate }}" >
  <app-document-search [hidden]=!useElasticSearch (onSearch)="onSearchEvent($event)" [documentGroup]="getDocumentGroup()"></app-document-search>
</app-content-header>

<div class="content-wrapper">
  <div>
    <div class="mb-2">

      <button id="btn-toggle-filter" (click)="filterToogle = !filterToogle;" class="btn btn-default btn-sm pointer filter-toggle-btn">
        <i *ngIf="!filterToogle" class="fa fa-plus"></i>
        <i *ngIf="filterToogle" class="fa fa-minus"></i>
        {{ 'GLOBAL_MESSAGES.FILTER_CLICK_HERE' | translate }}
      </button>
      <button id="btn-reset-filter" (click)="initFilter()" [disabled]="!customFilterActive"
              class="btn btn-default btn-sm mr-2">{{'GLOBAL_MESSAGES.FILTER_RESET' | translate }}
      </button>

      <app-dropdown id="dropdown-year" #filterYearDropdown (onSelectionChanged)="filterDateFromDropdownYear($event)" [items]="filterDateYearChoice"
                    [initValue]="filterDateYearInitValue" class="year-filter-wrapper mr-2 mt-2 mt-md-0"></app-dropdown>

      <app-dropdown id="dropdown-month" #filterMonthDropdown (onSelectionChanged)="filterDateFromDropdownMonth($event)" [items]="filterDateMonthChoice"
                    [initValue]="filterDateMonthInitValue" class="month-filter-wrapper mr-2 mt-2 mt-md-0"></app-dropdown>

      <button id="btn-mark-all-as-read" (click)="markAllAsRead()" *ngIf="unreadDocumentsAvailable()"
              class="btn btn-default btn-sm mr-2 mt-2 mt-md-0">{{'VIEWS.DOCUMENT.MARK_ALL_AS_READ' | translate }}
      </button>

      <button id="btn-reset-sort" (click)="resetCurrentSort()" *ngIf="showResetSortButton()"
              class="btn btn-default btn-sm mt-2 mt-md-0">{{'GLOBAL_MESSAGES.RESET_SORT' | translate }}
      </button>

      <!--Filter-->
      <div [hidden]="!filterToogle" class="row pt-4 pb-2">
        <div class="col-sm-12 col-md-3 col-lg-3"> {{ 'VIEWS.DOCUMENT.TYPE' | translate }}
          <ul class="filter-type-btn">
            <li>
              <button *ngFor="let documentArt of documentArtChoice" [id]="'btn-filter-'+documentArt.name" class="btn btn-sm btn-outline-success mr-2 mb-2"
                      (click)="filterDocumentType(documentArt);"
                      [ngClass]="{'filter-btn-active': documentArt.isActive, 'filter-btn-inactive' : !documentArt.isActive} ">
                {{documentArt.name}}
              </button>
            </li>
          </ul>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3">
          {{ 'VIEWS.DOCUMENT.BELEGDATUM' | translate }}({{'GLOBAL_MESSAGES.FROM_TO' | translate}})
          <div class="form-group">
            <input type="text" [bsConfig]="datePickerConfig" [(ngModel)]="bsRangeValue" (bsValueChange)="filterFromDateRangePicker($event)"
                   placeholder="{{ 'VIEWS.DOCUMENT.FILTER_TIME' | translate }}" class="form-control" bsDaterangepicker>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3" *ngIf="showDokumenteFilterView()"> {{ 'VIEWS.DOCUMENT.NAMENSFILTER' | translate }}
          <select id="input-dateiname-filter" [(ngModel)]="selectedDocumentFilter" (change)="filter()"
                  class="form-control">
            <ng-container *ngFor="let documentFilter of documentFilters">
              <option [value]="documentFilter.value">
               {{documentFilter.label}}
              </option>
            </ng-container>
          </select>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-3"> {{ 'VIEWS.DOCUMENT.RECEIPT' | translate }}
          <input id="input-belegnummer-filter" type="text" [(ngModel)]="belegnummerFilterValue" (keyup)="filter()"
                 placeholder="{{ 'VIEWS.DOCUMENT.FILTER_RECEIPT' | translate }}" class="form-control">
        </div>
        <div class="col-sm-12 col-md-5 col-lg-3" *ngIf="isTaxUser()"> {{ 'VIEWS.DOCUMENT.FILTER_KUNDE' | translate }}
          <ul class="filter-type-btn">
            <li>
              <button *ngFor="let kundeFilter of kundenFilterValue" [id]="'btn-filter-'+kundeFilter.data.vorname + ' ' + kundeFilter.data.name"
                      class="btn btn-sm btn-outline-success mr-2 mb-2" (click)="filterKunde(kundeFilter);"
                      [ngClass]="{'filter-btn-active': kundeFilter.isActive, 'filter-btn-inactive' : !kundeFilter.isActive} ">
                {{kundeFilter.data.vorname + ' ' + kundeFilter.data.name}}
              </button>
            </li>
          </ul>
        </div>

      </div>
      <ngx-datatable *ngIf="!isMobileView()" class="material document-table" [rows]="rows" [columnMode]="'flex'"
                     [headerHeight]="40" [footerHeight]="50" [rowHeight]="'auto'"
                     [messages]="tableMessages" [reorderable]="false" [selected]="selectedRows" [selectionType]="'checkbox'" [selectAllRowsOnPage]="false"
                     (select)="onSelect($event)" (page)="setPage($event)" (sort)="sort($event)" [limit]="currentPage.size" [externalPaging]="true"
                     [externalSorting]="true" [count]="currentPage.totalElements" [offset]="currentPage.number" [loadingIndicator]="tableIsLoading">

        <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false" [headerCheckboxable]="true"
                              [checkboxable]="true"></ngx-datatable-column>

        <ngx-datatable-column name="{{ 'VIEWS.DOCUMENT.FILENAME' | translate }}" [flexGrow]="2.5" prop="filename">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <div [ngClass]="{'bold-text': !row.read}" class="text-ellipsis">
              <a [id]="row.id + '-link-download'" (click)="downloadDocument(row)">{{value}}</a>
            </div>
          </ng-template>

        </ngx-datatable-column>
        <ngx-datatable-column name="{{ 'VIEWS.DOCUMENT.RECEIPT' | translate }}" [flexGrow]="1" prop="belegnummer"></ngx-datatable-column>
        <ngx-datatable-column name="{{ 'VIEWS.DOCUMENT.TYPE' | translate }}" [flexGrow]="1" prop="documentType">
          <ng-template let-value="value" ngx-datatable-cell-template class="text-ellipsis">
            {{ 'VIEWS.DOCUMENT.DOCUMENT_TYPE.' + value | translate}}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{ 'VIEWS.DOCUMENT.FILESIZE' | translate }}" [flexGrow]="1" prop="filesize">
          <ng-template let-value="value" ngx-datatable-cell-template class="text-ellipsis">
            {{value | filesizepipe}}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{ 'VIEWS.DOCUMENT.MIMETYPE' | translate }}" [flexGrow]="1" prop="mimetype">
          <ng-template let-value="value" ngx-datatable-cell-template class="text-ellipsis">
            {{ 'VIEWS.DOCUMENT.MIMETYPE_TYPES.' + value | translate}}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="{{ 'VIEWS.DOCUMENT.BELEGDATUM'| translate }}" [flexGrow]="1" prop="createdAt">
          <ng-template let-value="value" ngx-datatable-cell-template class="text-ellipsis">
            {{value | datexpipe:'DD.MM.YYYY'}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="isTaxUser()" name="{{ 'VIEWS.DOCUMENT.KUNDE'| translate }}" [flexGrow]="1" prop="kundeId">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template class="text-ellipsis">
            {{row.kundeName}}
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>

    <div *ngIf="isMobileView()" class="mt-4">
      <div *ngIf="rows?.length > 0">
        <ng-container *ngFor="let document of rows">
          <accordion>
            <accordion-group #accordionGroup [isOpen]="false" class="pointer mb-2">
              <div accordion-heading class="clearfix">
                <div class="row">
                  <div [ngClass]="{'bold-text': !document.read}" class="col text-ellipsis">
                    <span>
                      {{ document.belegnummer}}
                    </span>
                    <small class="listview-subtitle"> {{ 'VIEWS.DOCUMENT.DOCUMENT_TYPE.' + document.documentType | translate}} ({{ 'GLOBAL_MESSAGES.FROM'|
                      translate}} {{document.createdAt | datexpipe:'DD.MM.YYYY'}})
                    </small>
                  </div>
                  <div class="listview-item-chavron-wrapper">
                    <i class="fa" [ngClass]="{ 'fa-chevron-up': accordionGroup.isOpen , 'fa-chevron-down': !accordionGroup.isOpen}"></i>
                  </div>
                </div>
              </div>
              <div class="ml-2">
                <div class="row">
                  <small class="col">{{ 'VIEWS.DOCUMENT.FILENAME' | translate }}:</small>
                  <small class="col">{{document.filename}}</small>
                </div>
                <div class="row">
                  <small class="col">{{ 'VIEWS.DOCUMENT.MIMETYPE' | translate }}:</small>
                  <small class="col"> {{ 'VIEWS.DOCUMENT.MIMETYPE_TYPES.' + document.mimetype | translate}}</small>
                </div>
                <div class="row">
                  <small class="col">{{ 'VIEWS.DOCUMENT.FILESIZE' | translate }}:</small>
                  <small class="col">{{document.filesize | filesizepipe}}</small>
                </div>
                <div class="row">
                  <small class="col">{{ 'VIEWS.DOCUMENT.BELEGDATUM'| translate }}:</small>
                  <small class="col">{{document.createdAt | datexpipe:'DD.MM.YYYY'}}</small>
                </div>
                <div class="row">
                  <small class="col">{{ 'VIEWS.DOCUMENT.KUNDE' | translate }}:</small>
                  <small class="col">{{document.kundeName}}</small>
                </div>
                <div class="row">
                  <button [id]="'btn-download'+document.uuid" (click)="downloadDocument(document)" type="button"
                          class="btn btn-sm btn-default  mt-1 listview-item-download-button"> {{'EDIT_MESSAGE.DOWNLOAD' | translate}}
                  </button>
                </div>
              </div>
            </accordion-group>
          </accordion>
        </ng-container>
      </div>

      <div *ngIf="rows?.length === 0">
        {{getNoDataMessage()}}
      </div>
    </div>

  </div>
