<div class="content-wrapper">
  <div class="container py-5">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6 mx-auto">

            <div *ngIf="resetPwd">
              {{ 'VIEWS.RESET_PWD.MESSAGE_AFTER_RESET' | translate:{'mail': '' + email}  }}
            </div>

            <form #pwdForm="ngForm" (keyup.enter)="pwdForm.form.valid && onPwdResetClicked()" *ngIf="!resetPwd" class="form" id="pwd-form" role="form">
              <div class="card rounded-0">
                <div class="card-header">
                  <h4 class="mb-0">{{ 'VIEWS.RESET_PWD.RESET_PWD' | translate }}</h4>
                </div>
                <div class="card-body">
                  <div class="form-group">
                    <label>{{ 'VIEWS.RESET_PWD.KUNDENNUMMER' | translate }}</label>
                    <input [(ngModel)]="kundennummer" class="form-control" id="txt-kundennummer" name="txt-kundennummer"
                           placeholder="{{ 'VIEWS.RESET_PWD.KUNDENNUMMER' | translate }}" required type="text">
                  </div>
                  <div class="form-group">
                    <label>{{ 'VIEWS.RESET_PWD.EMAIL' | translate }}</label>
                    <input [(ngModel)]="email" class="form-control" id="txt-email" name="txt-email" placeholder="{{ 'VIEWS.RESET_PWD.EMAIL' | translate }}"
                           required type="text">
                  </div>

                  <button (click)="onPwdResetClicked()" [disabled]="!pwdForm.form.valid || !isMailValid(email) " class="btn btn-outline-success btn-block mb-2"
                          id="btn-reset-pwd" type="button">
                    {{ 'VIEWS.RESET_PWD.RESET_PWD' | translate }}
                  </button>
                </div>
              </div>
            </form>
            <span *ngIf="showError" class="text-danger" id="txt-reset-failed">{{ 'ERROR_MESSAGES.RESET_PASSWORD_FAILED' | translate }}</span>
            <a [routerLink]="['/login']" class="[ auth-switcher-link ] [ d-block ] [ mt-1 ]" id="link-forgot-pwd">
              {{  'VIEWS.RESET_PWD.BACK_TO_LOGIN'  | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
