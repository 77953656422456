import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Sequence} from './Sequence';

@Component({
  selector: 'app-password-policy',
  templateUrl: './password-policy.component.html',
  styleUrls: ['./password-policy.component.css']
})
export class PasswordPolicyComponent implements OnChanges {

  public static readonly checkClasses = ['fa-check', 'tick-green'];
  public static readonly errorClasses = ['fa-times', 'error-red'];
  @Input() password: string;
  @Output() onPasswordChanged = new EventEmitter<boolean>();

  ngOnChanges(changes: SimpleChanges) {
    // Notify parent when password is not valid
    this.onPasswordChanged.emit(this.evaluateLengthPolicy() &&
    this.evaluateUpperCasePolicy() &&
    this.evaluateNumberPolicy() &&
    this.evaluateSpecialCharPolicy() &&
    this.evaluateNoSequencePolicy() &&
    this.evaluateNoWhiteSpacesPolicy());
  }

  constructor() {
  }

  evaluateLengthPolicy(): boolean {
    return this.password && new RegExp('^.{8,30}$').test(this.password);
  }

  evaluateUpperCasePolicy(): boolean {
    return this.password && new RegExp('[A-Z]').test(this.password);
  }

  evaluateNumberPolicy(): boolean {
    return this.password && new RegExp('[0-9]').test(this.password);
  }

  evaluateSpecialCharPolicy(): boolean {
    return this.password && new RegExp('[!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~]').test(this.password);
  }

  evaluateNoSequencePolicy(): boolean {
      // Evaluate all sequences (Numerical, Alphabetical and Qwerty)
    return this.password && (this.evaluateSequence(this.password, Sequence.SEQUENCE_TYPES.NUMERICAL, 3, false) && this.evaluateSequence(this.password,
      Sequence.SEQUENCE_TYPES.ALPHABETICAL, 3, false) && this.evaluateSequence(this.password, Sequence.SEQUENCE_TYPES.QWERTY, 3, false) && this.password
           !== '');
  }

  evaluateNoWhiteSpacesPolicy(): boolean {
    return this.password && (this.password.indexOf(' ') === -1 && this.password.length > 0);
  }

  evaluateSequence(password = '', sequenceType, sequenceLength, wrapSequence): boolean {

    const max: number = password.length - sequenceLength + 1;
    let sequence: Sequence;
    let position: number = 0;
    let result: boolean = true;

    for (let i = 0; i < Sequence.getSequenceCountByType(sequenceType); i++) {
      for (let j = 0; j < max; j++) {
        sequence = Sequence.newSequence(Sequence.getSequenceByType(sequenceType, i), password[j], sequenceLength, wrapSequence);
        if (sequence != null) {
          position = j;
          sequence.checkForward(password, position);
          if (sequence.matchCount() === sequenceLength) {
            result = false;
          }
          sequence.reset();
          position = j;
          sequence.checkBackward(password, position);
          if (sequence.matchCount() === sequenceLength) {
            result = false;
          }
        }
      }
    }

    return result;
  }
}

