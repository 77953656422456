import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ApplicationService} from '../../api/services/application.service';
import {AuthService} from '../../api/services/auth.service';
import {VostUtils} from '../../core/vost-utils';
import {SharedService} from '../../shared.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  username: string = '';
  password: string = '';
  isTokenLogin: boolean = false;
  showLoginError: boolean = false;
  emailHasError: boolean = false;
  showRegistrationError: boolean = false;
  registrationSuccessfull: boolean = false;

  token: string;
  kundennummer: string;
  emailOne: string;
  emailTwo: string;

  constructor(private router: Router, private authService: AuthService, private sharedService: SharedService, private applicationService: ApplicationService) {

  }

  ngOnInit() {
    this.applicationService.getApplicationInformation().subscribe(res => {
      this.sharedService.applicationInformation = res;
    });
  }

  onLoginClicked(): void {
    this.showLoginError = false;

    if (this.isTokenLogin) {
      this.authService.registration(this.token, this.kundennummer, this.emailOne, this.emailTwo).subscribe(res => {
        this.registrationSuccessfull = true;
      }, errorRes => {
        this.showRegistrationError = true;
      });
    } else {
      this.authService.login(this.username, this.password).then(res => {
        this.router.navigate(['dashboard']);
      }).catch(reject => {
        this.showLoginError = true;
      });
    }
  }

  isMaintenanceMode(): boolean {
    if (this.sharedService && this.sharedService.applicationInformation) {
      return this.sharedService.applicationInformation.maintenanceActive;
    } else {
      return false;
    }
  }

  toggleLoginMode(): void {
    this.isTokenLogin = !this.isTokenLogin;
  }

  areEmailEqual(): boolean {
    if (VostUtils.isNotEmpty(this.emailOne) && VostUtils.isNotEmpty(this.emailTwo)) {
      return this.emailOne === this.emailTwo;
    }
    return true;
  }

  isMailValid(mail: string): boolean {
    if (VostUtils.isNotEmpty(mail)) {
      return VostUtils.isMailValid(mail);
    }
    return true;
  }

  resetLoginMode(): void {
    this.registrationSuccessfull = false;
    this.isTokenLogin = false;
    this.showRegistrationError = false;
    this.token = '';
    this.kundennummer = '';
    this.emailOne = '';
    this.emailTwo = '';
  }

  validateEmail(): void {
    this.emailHasError = !this.isMailValid(this.emailOne);
  }

  showEmailError(): boolean {
    return this.emailHasError;
  }

}
