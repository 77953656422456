import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {AdminService} from '../api/services/admin.service';
import {ApplicationService} from '../api/services/application.service';
import {AuthService} from '../api/services/auth.service';
import {JsonHttpService} from '../api/services/base/json-http';
import {VostHttpInterceptor} from '../api/services/base/vost-http-interceptor';
import {DocumentFilterService} from '../api/services/document-filter.service';
import {DocumentService} from '../api/services/document.service';
import {FileService} from '../api/services/file.service';
import {InventoryService} from '../api/services/inventory.service';
import {NewsService} from '../api/services/news.service';
import {SearchService} from '../api/services/search.service';
import {TaxUserService} from '../api/services/tax.user.service';
import {UserService} from '../api/services/user.service';
import {ContentHeaderComponent} from './component/content-header/content-header.component';
import {DropdownComponent} from './component/dropdown/dropdown.component';
import {PasswordPolicyComponent} from './component/password-policy/password-policy.component';
import {SpinnerComponent} from './component/spinner/spinner.component';
import {UiSwitchComponent} from './component/ui-switch.component';
import {CanActivateAdminGuard} from './guard/canActivateAdminGuard';
import {CanActivateGuard} from './guard/canActivateGuard';
import {LogService} from './log/LogService';
import {DatexPipe} from './pipes/datex.pipe';
import {FilesizePipe} from './pipes/filesize.pipe';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';

@NgModule({
  imports: [
    CommonModule,
    BsDropdownModule,
    TranslateModule],
  providers: [
    AuthService,
    JsonHttpService,
    HttpClient,
    CanActivateGuard,
    CanActivateAdminGuard,
    DatexPipe,
    SafeHtmlPipe,
    LogService,
    UserService,
    AdminService,
    DocumentService,
    ApplicationService,
    InventoryService,
    NewsService,
    FileService,
    SearchService,
    DocumentFilterService,
    TaxUserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VostHttpInterceptor,
      multi: true
    }],
  declarations: [
    ContentHeaderComponent,
    UiSwitchComponent,
    PasswordPolicyComponent,
    DropdownComponent,
    SpinnerComponent,
    FilesizePipe,
    SafeHtmlPipe,
    DatexPipe],
  exports: [
    ContentHeaderComponent,
    DropdownComponent,
    UiSwitchComponent,
    PasswordPolicyComponent,
    SpinnerComponent,
    FilesizePipe,
    SafeHtmlPipe,
    DatexPipe]
})
export class CoreModule {
}
