import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import 'rxjs/add/observable/of';
import {Observable} from 'rxjs/index';
import {DocumentDto} from '../../api/dto/document-dto';
import {Page} from '../../api/dto/sort-filter-paging/page';
import {DocumentService} from '../../api/services/document.service';

@Injectable()
export class DocumentFindAllResolver  {
  constructor(private documentService: DocumentService) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Page<DocumentDto>> {
    return this.documentService.findAllAsPage();
  }
}
