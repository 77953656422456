<div class='row mt-4'>
  <div class='pw-policy-wrapper col-12'>
    <small class='pw-policy-header'>
      <i class='fa fa-info pr-2'></i>
      {{ 'VIEWS.INITIAL_LOGIN.PASSWORD_POLICY_HEADER' | translate }}
    </small>
    <ul class='pt-2'>
      <li>
        <i id='i-password-policy-length' class='fa pr-2' [ngClass]='evaluateLengthPolicy() ? "fa-check tick-green" : "fa-times error-red"'></i>

        <small class='pw-policy-rule'>{{ 'VIEWS.INITIAL_LOGIN.PASSWORD_POLICY_LENGTH' | translate }}</small>
      </li>
      <li>
        <i id='i-password-policy-uppercase' class='fa pr-2' [ngClass]='evaluateUpperCasePolicy() ? "fa-check tick-green" : "fa-times error-red"'></i>
        <small class='pw-policy-rule'>{{ 'VIEWS.INITIAL_LOGIN.PASSWORD_POLICY_UPPERCASE' | translate }}</small>
      </li>
      <li>
        <i id='i-password-policy-number' class='fa pr-2' [ngClass]='evaluateNumberPolicy() ? "fa-check tick-green" : "fa-times error-red"'></i>
        <small class='pw-policy-rule'>{{ 'VIEWS.INITIAL_LOGIN.PASSWORD_POLICY_DIGITAL_CHAR' | translate }}</small>
      </li>
      <li>
        <i id='i-password-policy-special-char' class='fa pr-2' [ngClass]='evaluateSpecialCharPolicy() ? "fa-check tick-green" : "fa-times error-red"'></i>
        <small class='pw-policy-rule'>{{ 'VIEWS.INITIAL_LOGIN.PASSWORD_POLICY_SPECIAL_CHAR' | translate }}</small>
      </li>
      <li>
        <i id='i-password-policy-sequence' class='fa pr-2' [ngClass]='evaluateNoSequencePolicy() ? "fa-check tick-green" : "fa-times error-red"'></i>
        <small class='pw-policy-rule'>{{ 'VIEWS.INITIAL_LOGIN.PASSWORD_POLICY_NO_SEQUENCE' | translate }}</small>
      </li>
      <li>
        <i id='i-password-policy-no-whitespace' class='fa pr-2' [ngClass]='evaluateNoWhiteSpacesPolicy() ? "fa-check tick-green" : "fa-times error-red"'></i>
        <small class='pw-policy-rule'>{{ 'VIEWS.INITIAL_LOGIN.PASSWORD_POLICY_NO_WHITESPACES' | translate }}</small>
      </li>
    </ul>
  </div>
</div>
