import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {LogService} from '../../core/log/LogService';
import {SharedService} from '../../shared.service';
import {TaxUserDto} from '../dto/tax-user-dto';
import {UserDto} from '../dto/user-dto';
import {JsonHttpService} from './base/json-http';

@Injectable()
export class AuthService {
  public static apiUrl: string = '/api/auth';

  constructor(private jsonHttpService: JsonHttpService, private logService: LogService, private sharedService: SharedService) {
  }

  public getCurrentUser(): Observable<UserDto | TaxUserDto> {
    return this.jsonHttpService.get<UserDto | TaxUserDto>(AuthService.apiUrl + '/getCurrentUser');
  }

  login(username: string, password: string): Promise<boolean> {
    const body = {
      username,
      password
    };

    return new Promise<boolean>((resolve, reject) => {
      this.jsonHttpService.post<UserDto | TaxUserDto>(AuthService.apiUrl + '/login', body).subscribe(res => {
        this.sharedService.currentUser = res;
        this.sharedService.unreadGutschriftenCount = res.unreadGutschriften;
        this.sharedService.unreadRechnungenCount = res.unreadRechnungen;
        this.sharedService.unreadDocumentCount = res.unreadRechnungen + res.unreadGutschriften;
        this.logService.log(res);
        resolve(true);
      }, errorRes => {
        this.logService.log(errorRes);
        reject(false);
      });
    });
  }

  registration(token: string, kundennummer: string, emailOne: string, emailTwo: string): Observable<boolean> {
    const body = {
      token,
      kundennummer,
      emailOne,
      emailTwo
    };
    return this.jsonHttpService.post(AuthService.apiUrl + '/registration', body);
  }

  logout(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.jsonHttpService.post(AuthService.apiUrl + '/logout', '').subscribe(res => {
        this.sharedService.currentUser = null;
        this.logService.log(res);
        this.logService.log('logout successfully ');
        resolve(true);
      }, errorRes => {
        this.logService.log(errorRes);
        reject(false);
      });
    });
  }

  resetPwd(email: string, kundennummer: string): Observable<any> {
    return this.jsonHttpService.put(AuthService.apiUrl + '/reset?email=' + email + '&kundennummer=' + kundennummer, {});
  }
}
