import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import 'rxjs/add/observable/of';
import {Observable} from 'rxjs/index';
import {InventoryDto} from '../../api/dto/inventory-dto';
import {InventoryService} from '../../api/services/inventory.service';

@Injectable()
export class InventoryFindAllResolver  {
  constructor(private inventoryService: InventoryService) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InventoryDto[]> {
    return this.inventoryService.findAll();
  }
}
