import {Injectable} from '@angular/core';
import {ApplicationPropertiesDto} from './api/dto/application-properties-dto';
import {DocumentDto} from './api/dto/document-dto';
import {TaxUserDto} from './api/dto/tax-user-dto';
import {UserDto} from './api/dto/user-dto';
import {SpinnerComponent} from './core/component/spinner/spinner.component';
import VostDocumentGroup = DocumentDto.VostDocumentGroup;

export interface ISharedServiceDocumentCountChangedListener {
  documentCountChanged(unreadDocumentCount: number, unreadRechnungCount: number, unreadOtherCount: number, unreadGutschriftCount: number);
}

@Injectable()
export class SharedService {
  isMobileView: boolean;
  currentUser: UserDto | TaxUserDto;
  unreadDocumentCount: number = 0;
  unreadRechnungenCount: number = 0;
  unreadGutschriftenCount: number = 0;
  unreadOtherCount: number = 0;
  applicationInformation: ApplicationPropertiesDto;
  private globalSpinnerComponent: SpinnerComponent;
  private documentCountSubscribers: ISharedServiceDocumentCountChangedListener[] = [];

  constructor() {

  }

  isSignedIn(): boolean {
    return !!this.currentUser;
  }

  isSignedInAndPasswordIsChanged(): boolean {
    return this.currentUser ? !this.currentUser.hasToChangePassword : false;
  }

  isAdmin(): boolean {
    return this.currentUser && this.currentUser.role === UserDto.UserRole.VOST_SYS_ADMIN;
  }

  getUsernameAsString(): string {

    let username = '';

    if ((this.currentUser as UserDto).prename) {
      username = (this.currentUser as UserDto).prename + ' ' + (this.currentUser as UserDto).surname;
    }
    if ((this.currentUser as TaxUserDto).office) {
      username = (this.currentUser as TaxUserDto).office;
    }

    return username;
  }

  decreaseUnreadRechnungCount(decreaseRechnung: number): void {
    const newValue = this.unreadRechnungenCount - decreaseRechnung;
    this.unreadRechnungenCount = newValue >= 0 ? newValue : 0;
    this.unreadDocumentCount = this.unreadGutschriftenCount + this.unreadRechnungenCount + this.unreadOtherCount;
    this.notifyDocumentCountSubscribers();
  }

  decreaseUnreadGutschriftCount(decreaseGutschrift: number): void {
    const newValue = this.unreadGutschriftenCount - decreaseGutschrift;
    this.unreadGutschriftenCount = newValue >= 0 ? newValue : 0;
    this.unreadDocumentCount = this.unreadGutschriftenCount + this.unreadRechnungenCount + this.unreadOtherCount;
    this.notifyDocumentCountSubscribers();
  }

  decreaseUnreadOtherCount(decreaseOther: number): void {
    const newValue = this.unreadOtherCount - decreaseOther;
    this.unreadOtherCount = newValue >= 0 ? newValue : 0;
    this.unreadDocumentCount = this.unreadGutschriftenCount + this.unreadRechnungenCount + this.unreadOtherCount;
    this.notifyDocumentCountSubscribers();
  }

  decreaseUnreadTotalCount(decreaseRechnung: number, decreaseGutschrift: number, decreaseOther): void {
    let newValue = this.unreadRechnungenCount - decreaseRechnung;
    this.unreadRechnungenCount = newValue >= 0 ? newValue : 0;

    newValue = this.unreadGutschriftenCount - decreaseGutschrift;
    this.unreadGutschriftenCount = newValue >= 0 ? newValue : 0;

    newValue = this.unreadOtherCount - decreaseOther;
    this.unreadOtherCount = newValue >= 0 ? newValue : 0;
    this.unreadDocumentCount = this.unreadGutschriftenCount + this.unreadRechnungenCount + this.unreadOtherCount;
    this.notifyDocumentCountSubscribers();
  }

  setUnreadTotalCountToZero(): void {
    this.unreadRechnungenCount = 0;
    this.unreadOtherCount = 0;
    this.unreadGutschriftenCount = 0;
    this.unreadDocumentCount = 0;
    this.notifyDocumentCountSubscribers();
  }

  subscribeDocumentCount(subscriber: ISharedServiceDocumentCountChangedListener) {
    this.documentCountSubscribers.push(subscriber);
  }

  resetCounter(documentGroup: VostDocumentGroup): void {
    if (documentGroup === VostDocumentGroup.RECHNUNG) {
      this.unreadRechnungenCount = 0;
    } else if (documentGroup === VostDocumentGroup.ABRECHNUNG) {
      this.unreadGutschriftenCount = 0;
    } else if (documentGroup === VostDocumentGroup.ALLE) {
      this.unreadOtherCount = 0;
    }
    this.unreadDocumentCount = this.unreadGutschriftenCount + this.unreadRechnungenCount + this.unreadOtherCount;
    this.notifyDocumentCountSubscribers();
  }

  unsubscribeDocumentCount(subscriber: ISharedServiceDocumentCountChangedListener) {
    const index = this.documentCountSubscribers.indexOf(subscriber, 0);
    if (index > -1) {
      this.documentCountSubscribers.splice(index, 1);
    }
  }

  showLoadingSpinner(): void {
    if (this.globalSpinnerComponent) {
      this.globalSpinnerComponent.showSpinner(true);
    }
  }

  hideLoadingSpinner(): void {
    if (this.globalSpinnerComponent) {
      this.globalSpinnerComponent.showSpinner(false);
    }
  }

  setSpinnerComponent(spinner: SpinnerComponent): void {
    this.globalSpinnerComponent = spinner;
  }

  isTaxUser(): boolean {
    return this.currentUser.type === 'TaxUser';
  }

  private notifyDocumentCountSubscribers(): void {
    this.documentCountSubscribers.forEach(subscriber => {
      subscriber.documentCountChanged(this.unreadDocumentCount, this.unreadRechnungenCount, this.unreadOtherCount, this.unreadGutschriftenCount);
    });
  }
}
