import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {DocumentDto} from '../../api/dto/document-dto';
import {UserDto} from '../../api/dto/user-dto';
import {UserService} from '../../api/services/user.service';
import {BaseComponent} from '../../core/component/baseComponent';
import {LogService} from '../../core/log/LogService';
import {SharedService} from '../../shared.service';
import valueVostDocumentGroup = DocumentDto.valueVostDocumentGroup;
import VostDocumentGroup = DocumentDto.VostDocumentGroup;

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css']
})
export class NewUserComponent extends BaseComponent implements OnInit {

  documentGroups: string[];
  @Output() afterSave: EventEmitter<UserDto> = new EventEmitter<UserDto>();
  @Output() onCancel: EventEmitter<VoidFunction> = new EventEmitter<VoidFunction>();
  user: UserDto;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  usernamePattern = '[a-zA-Z]+';

  constructor(private sharedService: SharedService, private translateService: TranslateService, private logService: LogService,
              private userService: UserService, private toastr: ToastrService) {
    super(sharedService, translateService);
    this.user = new UserDto();
    this.user.creatorId = this.sharedService.currentUser.id;
  }

  ngOnInit() {
    this.documentGroups = valueVostDocumentGroup().map(dg => VostDocumentGroup[dg]);
  }

  saveUser(): void {
    const saveFunction = this.user.id ? this.userService.update : this.userService.createUserForUser;
    this.user.username = this.user.username.replace(/\s/g, '');
    saveFunction.call(this.userService, this.user).subscribe(res => {
      if (this.user.new) {
        this.toastr.success(this.translateService.instant('SUCCESS_MESSAGES.CREATED_MITBENUTZER', {email: this.user.email}));
      } else {
        this.toastr.success(this.translateService.instant('SUCCESS_MESSAGES.UDPATE'));
      }
      this.user = res;
      this.afterSave.emit(this.user);
    }, errorRes => {
      this.logService.log(errorRes);
      const errorMessage = errorRes.error;
      if (errorMessage.errorType && errorMessage.errorType === 'EMAIL_ALREADY_IN_USE') {
        this.toastr.error(this.translateService.instant('ERROR_MESSAGES.DUPLICATE_EMAIL'));
      } else if (errorMessage.errorType && errorMessage.errorType === 'INVALID_DATA') {
        this.toastr.error(this.translateService.instant('ERROR_MESSAGES.DUPLICATE_USER'));
      } else if (errorMessage.errorType && errorMessage.errorType === 'USERNAME_ALREADY_IN_USE') {
        this.toastr.error(this.translateService.instant('ERROR_MESSAGES.USERNAME_ALREADY_IN_USE'));
      } else {
        this.toastr.error(this.translateService.instant('ERROR_MESSAGES.CREATE'));
      }
    });
  }

  cancel(): void {
    this.onCancel.emit();
  }

  isAdmin() {
    return UserDto.UserRole.VOST_SYS_ADMIN === this.user.role;
  }

  setUser(row: UserDto) {
    this.user = row;
  }

  updateDocumentGroup(docGroup) {
    if (this.user.documentGroups.indexOf(docGroup) < 0) {
      this.user.documentGroups.push(docGroup);
    } else {
      this.user.documentGroups = this.user.documentGroups.filter(emp => emp.localeCompare(docGroup));
    }
  }

  isDocumentGroupChecked(docGroup) {
    return this.user.documentGroups.indexOf(docGroup) >= 0;
  }

  /*  checkFieldsValid() {
   return this.user.prename && this.user.surname && this.user.email && this.user.username && this.user.documentGroups != null
   && this.user.documentGroups.length > 0;
   }
   */
  getUser() {
    return this.user;
  }

  getErrorMessageForUsername(userName: string) {
    const regex = /\d/g;
    if (regex.test(userName)) {
      return 'VALIDATION_MESSAGES.USERNAME_NO_DIGITS';
    }
    if (userName.indexOf(' ') < 0) {
      return 'VALIDATION_MESSAGES.USERNAME_INVALID';
    }
    return 'VALIDATION_MESSAGES.USERNAME_NO_WHITESPACE';
  }
}
