import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {DocumentDto} from '../../api/dto/document-dto';
import {UserDto} from '../../api/dto/user-dto';
import {SharedService} from '../../shared.service';

@Injectable()
export class CanActivateGuard  {

  constructor(private sharedService: SharedService, private router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let res = true;
    if (!this.sharedService.isSignedIn()) {
      this.router.navigateByUrl('login');
    } else if (!this.sharedService.isSignedInAndPasswordIsChanged()) {
      this.router.navigateByUrl('initial-login');
    } else if (this.sharedService.currentUser.role === UserDto.UserRole.VOST_USER) {
      if (state.url === '/document/other' && !this.sharedService.currentUser.documentGroups.includes(DocumentDto.VostDocumentGroup.ALLE.toString())) {
        res = false;
      } else if (state.url === '/document/bill' && !this.sharedService.currentUser.documentGroups.includes(DocumentDto.VostDocumentGroup.RECHNUNG.toString())) {
        res = false;
      } else if (state.url === '/document/credit' && !this.sharedService.currentUser.documentGroups.includes(
        DocumentDto.VostDocumentGroup.ABRECHNUNG.toString())) {
        res = false;
      }
    }

    return res;
  }
}
