<div [ngClass]="{'header-wrapper-mobile': isMobileView(),'header-wrapper': !isMobileView()}">
  <div class="row w-100">
    <div class="col-lg-8 col-md-8  col-7">
      <h3 class="title" id="txt-header-title">{{title}}</h3>
      <button (click)="onSaveClicked()" *ngIf="showSaveBtn" [disabled]="savedBtnDisabled" class="btn btn-sm btn-outline-success" id="btn-save" type="button">
        {{'EDIT_MESSAGE.SAVE' | translate}}
      </button>
      <button (click)="onDownloadClicked()" *ngIf="showDownloadBtn" [disabled]="downloadBtnDisabled" class="btn btn-sm btn-outline-success" id="btn-download"
              title="{{'TIPS.SELECT_MINIMUM_ONE_TABLE_ENTRY' | translate}}" type="button">
        {{'EDIT_MESSAGE.DOWNLOAD' | translate}}
      </button>
      <div *ngIf="subtitle" class="subtitle" id="txt-header-subtitle">{{subtitle}}</div>
    </div>
    <div class="col-4"><ng-content></ng-content></div>
  </div>
  <hr>
</div>
