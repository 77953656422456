export class VostUtils {
  static MAIL_REGEX: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  static isNotEmpty(msg: any | any[]): boolean {
    return msg !== undefined && msg !== null && msg.length > 0;
  }

  static isMailValid(mail: string): boolean {
    return VostUtils.MAIL_REGEX.test(mail);
  }
}
