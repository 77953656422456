import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class JsonHttpService {
  constructor(private http: HttpClient) {
  }

  public get<T>(url: string): Observable<T> {
    return this.http.get<T>(url);
  }

  public getAsArrayBuffer(url: string): Observable<ArrayBuffer> {
    return this.http.get(url, {
      responseType: 'arraybuffer'
    });
  }

  public post<T>(url: string, body: any): Observable<T> {
    return this.http.post<T>(url, body);
  }

  public put<T>(url: string, body: any): Observable<T> {
    return this.http.put<T>(url, body);
  }

  public delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(url);
  }

  public patch<T>(url: string, body: any): Observable<T> {
    return this.http.patch<T>(url, body);
  }

  public head<T>(url: string): Observable<T> {
    return this.http.head<T>(url);
  }


}
