import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {ToastrService} from 'ngx-toastr';
import {KundeDto} from '../../api/dto/kunde-dto';
import {TaxUserDto} from '../../api/dto/tax-user-dto';
import {UserDto} from '../../api/dto/user-dto';
import {UserService} from '../../api/services/user.service';
import {BaseComponent} from '../../core/component/baseComponent';
import {LogService} from '../../core/log/LogService';
import {SharedService} from '../../shared.service';
import {NewUserComponent} from '../new-user/new-user.component';
import UserRole = UserDto.UserRole;

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent extends BaseComponent implements OnInit {
  user: UserDto | TaxUserDto;
  kunde: KundeDto;
  isChangePassword: boolean;
  isNewPasswordEquals: boolean;
  isNewPasswordValid: boolean = false;
  enableDetail: boolean = false;

  @ViewChild(NewUserComponent) newUserComponent: NewUserComponent;
  @ViewChild('userTable') userTable: DatatableComponent;

  deleteRowMap: Map<string, boolean> = new Map<string, boolean>();
  userRows: UserDto[] = [];
  filteredUserRows: UserDto[] = [];

  constructor(private sharedService: SharedService, private translateService: TranslateService, private logService: LogService,
              private userService: UserService, private toastr: ToastrService, private logservice: LogService) {
    super(sharedService, translateService);
    this.user = this.sharedService.currentUser;
    this.isNewPasswordEquals = true;
  }

  ngOnInit() {
    // TaxUserDto ist von der Rolle VOST_USER
    if (UserDto.UserRole.VOST_SYS_ADMIN !== this.user.role) {
      this.kunde = (this.user as UserDto).kunde;
    }
    if (UserDto.UserRole.VOST_KUNDE_ADMIN === this.user.role) {
      this.loadUserForCurrentUser();
    }
  }

  passwordChangeValid(): boolean {
    return !this.isChangePassword || !(!this.user.password || !this.user.passwordNewOne || !this.user.passwordNewTwo) && this.isNewPasswordValid;
  }

  saveUser(): void {
    this.isNewPasswordEquals = this.checkNewPasswordEquals();
    if (!this.isChangePassword || (this.isChangePassword && this.isNewPasswordEquals)) {
      this.userService.update(this.user).subscribe(res => {
        this.user = res as UserDto;
        this.sharedService.currentUser = res;
        this.isChangePassword = false;
        this.toastr.success(this.translateService.instant('SUCCESS_MESSAGES.UDPATE'));
      }, errorRes => {
        this.logService.log(errorRes);
        this.toastr.error(this.translateService.instant('ERROR_MESSAGES.UDPATE'));
      });
    } else {
      this.isNewPasswordEquals = false;
    }
  }

  checkNewPasswordEquals(): boolean {
    return this.user.passwordNewOne === this.user.passwordNewTwo;
  }

  isAdmin() {
    return UserDto.UserRole.VOST_SYS_ADMIN === this.user.role;
  }

  setNewPasswordValid(valid: boolean): void {
    this.isNewPasswordValid = valid;
  }

  canCreateUser(user: UserDto | TaxUserDto): boolean {
    return user.role !== UserRole.VOST_USER && user.role !== UserRole.VOST_SYS_ADMIN;
  }

  private copyUserRowsAndResetUserFilter(userRows: UserDto[]): void {
    this.filteredUserRows = [...userRows];
  }

  loadUserForCurrentUser() {
    this.userService.findAll().subscribe(res => {
      this.userRows = res;
      this.copyUserRowsAndResetUserFilter(this.userRows);
    }, errorRes => {
      this.logservice.log(errorRes);
      this.toastr.error(this.translateService.instant('ERROR_MESSAGES.LOADING_USER_DATA'));
    });
  }

  toggleUserTableExpandRow(row: UserDto): void {
    this.userTable.rowDetail.toggleExpandRow(row);
    if (this.newUserComponent && this.newUserComponent.getUser() === row) {
      this.enableDetail = false;
    }
  }

  deleteCustomerUser(user: UserDto): void {
    this.userService.deleteCustomerUser(user).subscribe(res => {
      this.resetUserFilter(res);
    }, errorRes => {
      this.showMessage(false, this.translateService.instant('ERROR_MESSAGES.USER_DELETED'));
    });
  }

  private resetUserFilter(res) {
    this.showMessage(true, this.translateService.instant('SUCCESS_MESSAGES.USER_DELETED'));
    this.userRows = res;
    this.copyUserRowsAndResetUserFilter(this.userRows);
  }

  showMessage(success: boolean, msg: string): void {
    if (success) {
      this.toastr.success(msg);
    } else {
      this.toastr.error(msg);
    }
  }

  reinitInlineMaps(): void {
    this.reinitDeleteInlineFlag();
  }

  showDeleteInline(row: UserDto): void {
    this.reinitInlineMaps();
    this.deleteRowMap.set(row.uuid, true);
  }

  reinitDeleteInlineFlag(): void {
    for (const key of Array.from(this.deleteRowMap.keys())) {
      this.deleteRowMap.set(key, false);
    }
  }

  saveCustomUser(user: UserDto) {
    this.loadUserForCurrentUser();
    this.enableDetail = false;
  }

  onUserEditCancel(): void {
    this.enableDetail = false;
  }

  editInline(row: UserDto) {
    this.enableDetail = true;
    this.newUserComponent.setUser(row);
  }

  resetFields() {
    this.enableDetail = true;
    this.newUserComponent.setUser(new UserDto());
  }

  enableTableUser(user: UserDto) {
    return this.userRows !== undefined;
  }

  isTaxUser(): boolean {
    return this.sharedService.isTaxUser();
  }
}
