import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import 'rxjs/add/observable/of';
import {Observable} from 'rxjs/index';
import {UserDto} from '../../api/dto/user-dto';
import {UserService} from '../../api/services/user.service';

@Injectable()
export class UserFindAllResolver  {
  constructor(private userService: UserService) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserDto[]> {
    return this.userService.findAll();
  }
}
