import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SharedService} from '../../../shared.service';
import {BaseComponent} from '../baseComponent';

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.css']
})
export class ContentHeaderComponent extends BaseComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;

  @Input() showSaveBtn: boolean;
  @Input() showDownloadBtn: boolean;
  @Input() showMarkAllAsReadButton: boolean;

  @Input() savedBtnDisabled: boolean;
  @Input() downloadBtnDisabled: boolean;

  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() onDownload: EventEmitter<any> = new EventEmitter();
  @Output() onMarkAsAllRead: EventEmitter<any> = new EventEmitter();

  constructor(private sharedService: SharedService, private translateService: TranslateService) {
    super(sharedService, translateService);
    this.showSaveBtn = false;
    this.showDownloadBtn = false;
  }

  ngOnInit() {
  }

  onSaveClicked() {
    this.onSave.emit();
  }

  onDownloadClicked() {
    this.onDownload.emit();
  }
}
