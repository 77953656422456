export class NavigationItem {
  id: string;
  title: string;
  routerUrl: string;
  isActive: boolean;
  unreadInfos: number;

  constructor(id: string, title: string, routerUrl: string, unreadInfos: number) {
    this.id = id;
    this.title = title;
    this.routerUrl = routerUrl;
    this.isActive = false;
    this.unreadInfos = unreadInfos;
  }
}
