import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filesizepipe'
})
export class FilesizePipe implements PipeTransform {

  public transform(value: number): string {
    if (value < 1024) {
      return value + ' Byte';
    } else if (value < 99999) {
      return Math.round(value / 1024) + ' KB';
    } else {
      return (value / 1024 / 1024).toFixed(2) + ' MB';
    }
  }
}
