import {FilterCriteria} from './filter-criteria';
import {SortCriteria} from './sort-criteria';

export class SearchFilterPagingDto {
  pageSize: number;
  page: number;
  sortCriteriaList: SortCriteria[] = [];
  filterCriteriaList: FilterCriteria[] = [];

  resetSort(): void {
    this.sortCriteriaList = [];
  }

  hasSort(): boolean {
    return this.sortCriteriaList && this.sortCriteriaList.length > 0;
  }
}
