import {AfterViewInit, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import 'rxjs-compat/add/observable/interval';
import 'rxjs-compat/add/operator/mergeMap';
import {IntervalObservable} from 'rxjs-compat/observable/IntervalObservable';
import {ApplicationPropertiesDto} from './api/dto/application-properties-dto';
import {DocumentDto} from './api/dto/document-dto';
import {ApplicationService} from './api/services/application.service';
import {AuthService} from './api/services/auth.service';
import {BaseComponent} from './core/component/baseComponent';
import {SpinnerComponent} from './core/component/spinner/spinner.component';
import {LogService} from './core/log/LogService';
import {NavigationItem} from './navigationItem';
import {SharedService} from './shared.service';
import VostDocumentGroup = DocumentDto.VostDocumentGroup;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('globalLoadingSpinner') spinner: SpinnerComponent;
  navigationItems: NavigationItem[] = [];
  isBurgerButtonActive: boolean;
  isUserProfileActive: boolean;
  isAdminConsoleActive: boolean;
  mobileViewMaxWidth: number;
  homeNavigationItem: NavigationItem;
  dashboardInitialized: boolean = false;
  private readonly CHECK_STATUS_INTERVALL: number = 60000;

  constructor(private router: Router, private translate: TranslateService, private sharedService: SharedService, private authService: AuthService,
              private applicationService: ApplicationService, private logService: LogService) {
    super(sharedService, translate);
  }

  ngOnInit(): void {
    this.mobileViewMaxWidth = 991;
    this.sharedService.isMobileView = window.innerWidth <= this.mobileViewMaxWidth;
    this.initLanguage();

    this.applicationService.getApplicationInformation().subscribe(res => {
      this.sharedService.applicationInformation = res;
    });

    this.router.events.forEach((event) => {
      if (!this.dashboardInitialized && event instanceof NavigationEnd && (event.url === '/dashboard' || event.url === '/login')) {
        this.checkUserIsAlreadyLoggedIn();
      }
    });

    IntervalObservable.create(this.CHECK_STATUS_INTERVALL).subscribe(() => {
      this.applicationService.getApplicationInformation().subscribe(res => {
        this.sharedService.applicationInformation = res;
        if (this.sharedService.applicationInformation.maintenanceActive && !this.sharedService.isAdmin()) {
          this.logout();
          this.sharedService.currentUser = null;
          this.router.navigate(['login']);
        }
      });
    });

  }

  checkUserIsAlreadyLoggedIn() {
    this.authService.getCurrentUser().subscribe(res => {
      this.dashboardInitialized = true;
      this.sharedService.currentUser = res;
      this.sharedService.unreadRechnungenCount = res.unreadRechnungen;
      this.sharedService.unreadGutschriftenCount = res.unreadGutschriften;
      this.sharedService.unreadOtherCount = res.unreadAllgemeine;
      this.sharedService.unreadDocumentCount = res.unreadRechnungen + res.unreadGutschriften + res.unreadAllgemeine;
      this.initNavigation();
      this.router.navigateByUrl('/dashboard');
    }, errorRes => {
      this.logService.log(errorRes);
      this.logService.log('user not authenticated -> go to login');
      this.router.navigateByUrl('/login');
    });
  }

  getUsername(): string {
    return this.sharedService.getUsernameAsString();
  }

  isSignedInAndPasswordIsChanged(): boolean {
    return this.sharedService.isSignedInAndPasswordIsChanged();
  }

  isUserSignIn(): boolean {
    return this.sharedService.isSignedIn();
  }

  isAdmin(): boolean {
    return this.isUserSignIn() && this.sharedService.isAdmin();
  }

  isDevSystem(): boolean {
    if (this.sharedService.applicationInformation) {
      return this.sharedService.applicationInformation.showDevSystemHint;
    }
    return false;
  }

  logout(): void {
    this.authService.logout().then(res => {
      this.dashboardInitialized = false;
      this.sharedService.currentUser = null;
      this.router.navigate(['login']);
    }).catch(reject => {
      this.logService.log('fehler beim abmelden: ' + reject);
    });
  }

  @HostListener('window:unload', ['$event']) unloadHandler() {
    this.authService.logout();
  }

  @HostListener('window:resize', ['$event']) onResize(event): void {
    this.sharedService.isMobileView = window.innerWidth <= this.mobileViewMaxWidth;
    this.logService.log('isMobileView: ' + this.sharedService.isMobileView);
  }

  onNavigationItemClicked(navItem: NavigationItem): void {
    this.activateNavigationItem(navItem);
    this.router.navigate([navItem.routerUrl]);
    this.isBurgerButtonActive = false;
    this.isUserProfileActive = false;
    this.isAdminConsoleActive = false;
  }

  onUserProfileClicked(): void {
    this.router.navigate(['user-detail']);
    this.activateNavigationItem();
    this.isBurgerButtonActive = false;
    this.isAdminConsoleActive = false;
    this.isUserProfileActive = true;
  }

  onAdminConsoleClicked(): void {
    this.router.navigate(['admin-console']);
    this.activateNavigationItem();
    this.isBurgerButtonActive = false;
    this.isUserProfileActive = false;
    this.isAdminConsoleActive = true;
  }

  getApplicationInformation(): ApplicationPropertiesDto {
    return this.sharedService.applicationInformation;
  }

  ngAfterViewInit(): void {
    this.sharedService.setSpinnerComponent(this.spinner);
  }

  private initLanguage(): void {
    this.translate.addLangs([
      'en',
      'de']);
    this.translate.setDefaultLang('de');
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
    this.logService.log(browserLang.match(/en|de/) ? browserLang : 'de');
  }

  private activateNavigationItem(navItem?: NavigationItem): void {
    this.navigationItems.forEach(item => {
      item.isActive = item === navItem;
    });
  }

  private initNavigation(): void {
    this.navigationItems = [];
    this.translate.get('wait4TranslateLoaded').subscribe(() => {
      this.homeNavigationItem = new NavigationItem('dashboard', this.translate.instant('NAVIGATION.DASHBOARD'), 'dashboard', 0);
      this.homeNavigationItem.isActive = true;
      this.navigationItems.push(this.homeNavigationItem);
      let billItem = null;
      const currentUser = this.sharedService.currentUser;
      if ((currentUser.creatorId && currentUser.documentGroups.indexOf(VostDocumentGroup[VostDocumentGroup.RECHNUNG]) >= 0) || !currentUser.creatorId) {
        billItem = new NavigationItem('bill', this.translate.instant('NAVIGATION.BILL'), 'document/bill', this.sharedService.unreadRechnungenCount);
        this.navigationItems.push(billItem);
      }
      let creditItem = null;
      if ((currentUser.creatorId && currentUser.documentGroups.indexOf(VostDocumentGroup[VostDocumentGroup.ABRECHNUNG]) >= 0) || !currentUser.creatorId) {
        creditItem = new NavigationItem('credit', this.translate.instant('NAVIGATION.CREDIT'), 'document/credit', this.sharedService.unreadGutschriftenCount);
        this.navigationItems.push(creditItem);
      }
      if (!this.sharedService.isTaxUser()) {
        const inventoryItem = new NavigationItem('inventory', this.translate.instant('NAVIGATION.INVENTORY'), 'inventory', 0);
        this.navigationItems.push(inventoryItem);
      }
      let otherItem = null;
      if ((currentUser.creatorId && currentUser.documentGroups.indexOf(VostDocumentGroup[VostDocumentGroup.ALLE]) >= 0) || !currentUser.creatorId) {
        otherItem = new NavigationItem('other', this.translate.instant('NAVIGATION.OTHER'), 'document/other', this.sharedService.unreadOtherCount);
        this.navigationItems.push(otherItem);
      }

      this.sharedService.subscribeDocumentCount({
        documentCountChanged(unreadDocumentCount: number, unreadRechnungCount: number, unreadOtherCount: number, unreadGutschriftCount: number) {
          billItem.unreadInfos = unreadRechnungCount;
          creditItem.unreadInfos = unreadGutschriftCount;
          otherItem.unreadInfos = unreadOtherCount;
        }
      });
    });
  }
}
