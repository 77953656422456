<form id="user-form" #userForm="ngForm" class="form" role="form">

  <div class="row">
    <div class="col-12 col-sm-6 form-group">
      <label for="input-username-new">{{ 'VIEWS.USER_PROFILE.USER_NAME' | translate }}</label>
      <input id="input-username-new" placeholder="{{ 'VIEWS.USER_PROFILE.USER_NAME' | translate }}" [(ngModel)]="user.username" name="userName"
             #userName="ngModel" class="form-control" required [pattern]="usernamePattern">
      <small [style.visibility]="userName.errors ? 'visible' : 'hidden'" class="text-danger">
        <div *ngIf="userName.errors?.required">
          {{ 'VALIDATION_MESSAGES.VALUE_REQUIRED' | translate }}
        </div>
        <div *ngIf="userName.errors?.pattern">
          {{ getErrorMessageForUsername(userName.value) | translate }}
        </div>
      </small>
    </div>
    <div class="col-12 col-sm-6 form-group">
      <label for="input-email-new">{{ 'VIEWS.USER_PROFILE.EMAIL' | translate }}</label>
      <input id="input-email-new" placeholder="{{ 'VIEWS.USER_PROFILE.EMAIL' | translate }}" [(ngModel)]="user.email" [email]="true" name="userEmail"
             #userEmail="ngModel" class="form-control" required [pattern]="emailPattern">
      <small [style.visibility]="userEmail.errors ? 'visible' : 'hidden'" class="text-danger">
        <div [style.visibility]="userEmail.errors?.required ? 'visible' : 'hidden'">
          {{ 'VALIDATION_MESSAGES.VALUE_REQUIRED' | translate}}
        </div>
        <div [style.visibility]="userEmail.errors?.pattern ? 'visible' : 'hidden'">
          {{ 'ERROR_MESSAGES.INVALID_EMAIL' | translate }}
        </div>
      </small>
    </div>

  </div>

  <div class="row">
    <div class="col-12 col-sm-6 form-group">
      <label for="input-prename-new">{{ 'VIEWS.USER_PROFILE.PRENAME' | translate }}</label>
      <input id="input-prename-new" placeholder="{{ 'VIEWS.USER_PROFILE.PRENAME' | translate }}" [(ngModel)]="user.prename" name="userPrename"
             #userPrename="ngModel" class="form-control" required>
      <small id="userPrename-error" [style.visibility]="userPrename.errors ? 'visible' : 'hidden'" class="form-text text-danger">
        {{ 'VALIDATION_MESSAGES.VALUE_REQUIRED' | translate }}
      </small>
    </div>

    <div class="col-12 col-sm-6 form-group">
      <label for="input-surname-new">{{ 'VIEWS.USER_PROFILE.SURNAME' | translate }}</label>
      <input id="input-surname-new" placeholder="{{ 'VIEWS.USER_PROFILE.SURNAME' | translate }}" [(ngModel)]="user.surname" name="userSurname"
             #userSurname="ngModel" class="form-control" required>
      <small id="userSurname-error" [style.visibility]="userSurname.errors ? 'visible' : 'hidden'" class="form-text text-danger">
        {{ 'VALIDATION_MESSAGES.VALUE_REQUIRED' | translate }}
      </small>
    </div>

    <div class="col-sm-12 col-md-5 col-lg-5">
      <div>{{ 'VIEWS.USER_PROFILE.DOC_GROUPS' | translate }} </div>
      <div *ngFor="let docGroup of documentGroups" [id]="'btn-docGroup-'+docGroup" class="btn btn-sm btn-default mr-2" (click)="updateDocumentGroup(docGroup)"
           [ngClass]="{'btn-outline-success':isDocumentGroupChecked(docGroup)} ">
        {{docGroup | translate}}
      </div>
      <small id="userDocumentGroups-error" [style.visibility]="(!user.documentGroups || !user.documentGroups.length)  ? 'visible' : 'hidden'"
             class="form-text text-danger">
        {{ 'VALIDATION_MESSAGES.VALUE_REQUIRED' | translate }}
      </small>

    </div>
  </div>
  <div class="mt-4">
    <button id="btn-cancel" (click)="cancel()" type="button" class="btn btn-sm btn-primary mr-2">
      {{'EDIT_MESSAGE.CANCEL' | translate}}
    </button>

    <button id="btn-save-new" (click)="saveUser()" [disabled]="!userForm.valid || !user.documentGroups || !user.documentGroups.length" type="button"
            class="btn btn-sm btn-outline-success">
      {{'EDIT_MESSAGE.SAVE' | translate}}
    </button>
  </div>


</form>
