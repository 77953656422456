import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {InventoryDto} from '../../api/dto/inventory-dto';
import {FileService} from '../../api/services/file.service';
import {BaseComponent} from '../../core/component/baseComponent';
import {SharedService} from '../../shared.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent extends BaseComponent implements OnInit {

  rows: InventoryDto[] = [];
  dateOfInventory: Date;
  companyKey: number;

  constructor(public activatedRoute: ActivatedRoute, private sharedService: SharedService, private fileService: FileService,
              private translateService: TranslateService) {
    super(sharedService, translateService);
    this.tableMessages = {
      totalMessage: translateService.instant('TABLE.POSITIONS')
    };
  }

  ngOnInit(): void {
    this.activatedRoute.data
      .subscribe((data: {inventories: InventoryDto[]}) => {
        this.rows = data.inventories;
        if (this.rows && this.rows.length > 0) {
          this.dateOfInventory = this.rows[0].datumBis;
          this.companyKey = this.rows[0].betriebsschluessel;
        }
      });
  }
}
