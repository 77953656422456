export abstract class BaseDto {
  version?: number;

  uuid?: string = '';

  createdBy?: string;

  createdAt?: Date;

  lastUpdateBy?: string;

  lastUpdateAt?: Date;

  id?: number;

  new?: boolean = true;

}
