import {Component} from '@angular/core';
import {IAcceptableDialogCallback} from './i-acceptable-dialog-callback';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  templateUrl: './acceptable-dialog.html',
  styleUrls: ['./acceptable-dialog.component.css']
})
export class AcceptableDialogComponent {
  text: string;
  title: string;
  callback: IAcceptableDialogCallback;

  constructor(private bsModalRef: BsModalRef) {
  }

  onAcceptClicked() {
    this.callback.accept();
    this.bsModalRef.hide();
  }
}
