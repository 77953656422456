import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {ApplicationPropertiesDto} from '../dto/application-properties-dto';

@Injectable()
export class ApplicationService {
  public static apiUrl: string = '/api/application';
  public static API_INFORMATION_PATH: string = 'api/application/information';

  constructor(private  http: HttpClient) {
  }

  getApplicationInformation(): Observable<ApplicationPropertiesDto> {
    return this.http.get<ApplicationPropertiesDto>(ApplicationService.API_INFORMATION_PATH);
  }

}
