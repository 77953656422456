<app-content-header title="{{ 'VIEWS.DASHBOARD.TITLE' | translate }}"></app-content-header>
<div class="content-wrapper">
  <p>Guten Tag {{getUsername()}},</p>

  <a (click)="unreadLinkClicked()" *ngIf="getUnreadDocumentCount() > 0" [routerLinkActive]="'active'"
     [routerLink]="'/document/unread'" class="unread-link"
     id="lbl-unread-document-count">{{ 'VIEWS.DASHBOARD.UNREAD_DOCUMENT_MESSAGE' |
    translate:{'count': getUnreadDocumentCount()} }}</a>
  <p *ngIf="getUnreadDocumentCount() === 0"
     id="lbl-no-unread-document">{{ 'VIEWS.DASHBOARD.NO_UNREAD_MESSAGE' | translate }}</p>

  <div class="pt-3">
    <h5 class="dashboard-information-header-item pb-2">Aktuelle News rund um den VOST</h5>

    <div class="row">
      <ng-container *ngFor="let dashboardItem of dashboardItems">

        <div class="col-10 col-md-6 pb-4">
          <div class="row pl-3 mb-1">
            <a [href]=" dashboardItem.url" class="dashboard-information-header-item"
               target="_blank">{{dashboardItem.headerText}}</a>
          </div>
          <div class="row  dashboard-information-wrapper">
            <div class="col-4 col-md-4 col-lg-4 col-xl-3 pr-1">
              <a [href]=" dashboardItem.url" target="_blank">
                <img [src]="dashboardItem.imageUrl" class="img-fluid pointer"/>
              </a>
            </div>

            <div [ngClass]="{' block-with-text': newsFeedAvailable}" class="col-8 col-md-8 col-lg-8 col-xl-6 pl-1">
              <small>
                {{dashboardItem.text}}
              </small>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

  </div>
</div>
