<div [ngClass]="{'login': !isSignedInAndPasswordIsChanged()}" id="page">
  <div [ngClass]="{'dev-system-background':isDevSystem()}" id="header">
    <img *ngIf="!isUserSignIn() && !isMobileView()" [src]="'assets/images/vost_logo.png'" id="header-logo-login"/>
    <img (click)="onNavigationItemClicked(homeNavigationItem)" *ngIf="isUserSignIn() || isMobileView()" [src]="'assets/images/logo_kira.png'"
         class="navbar-logo pointer" id="header-logo"/>
    <h3 *ngIf="!isUserSignIn() && !isMobileView()" class="page-title-login " id="page-title-login">{{ 'GLOBAL_MESSAGES.TITLE' | translate }}</h3>
    <h3 (click)="onNavigationItemClicked(homeNavigationItem)" *ngIf="isUserSignIn()|| isMobileView()" class="page-title pointer"
        id="page-title">{{(isDevSystem() ? 'GLOBAL_MESSAGES.TITLE_DEV' : 'GLOBAL_MESSAGES.TITLE')| translate }}</h3>
  </div>

  <div *ngIf="isSignedInAndPasswordIsChanged()" class="navigation">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">

      <div class="btn-group" class="ml-auto float-right" dropdown placement="bottom right">
        <button (click)="isBurgerButtonActive = !isBurgerButtonActive" aria-controls="dropdown-alignment" class="navbar-toggler btn-lg" dropdownToggle
                id="button-alignment" type="button">
          <span [ngClass]="{'burger-button-active': isBurgerButtonActive}">
            <i class="fa fa-bars"></i>
          </span>
        </button>
        <ul *dropdownMenu aria-labelledby="button-alignment" class="dropdown-menu dropdown-menu-right mr-2" role="menu" style="text-align: center;">
          <ng-container *ngFor="let item of navigationItems">
            <li (click)="onNavigationItemClicked(item)" class="nav-item" role="menuitem">
              <a class="dropdown-item" id="nav-{{item.id}}">{{item.title}}</a>
            </li>
            <li class="divider dropdown-divider"></li>
          </ng-container>
          <li *ngIf="isAdmin()" role="menuitem ">
            <a (click)="onAdminConsoleClicked()" class="dropdown-item" id="nav-user-management">{{ 'NAVIGATION.ADMIN_CONSOLE' | translate }}</a>
          </li>
          <li class="divider dropdown-divider"></li>
          <li role="menuitem ">
            <a (click)="onUserProfileClicked()" class="dropdown-item" id="nav-user-profile">{{ 'NAVIGATION.EDIT_PROFILE' | translate }}</a>
          </li>
          <li class="divider dropdown-divider"></li>
          <li role="menuitem">
            <a (click)="logout()" class="dropdown-item" id="nav-logout">{{ 'NAVIGATION.LOGOUT' | translate }}</a>
          </li>
        </ul>
      </div>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li *ngFor="let navItem of navigationItems" class="nav-item mr-4">
            <a (click)="onNavigationItemClicked(navItem)" [ngClass]="{'nav-item-active': navItem.isActive}" class="nav-link" id="nav-{{navItem.id}}">
              {{navItem.title}}
              <span *ngIf="navItem.unreadInfos>0" [id]="'badge-'+navItem.title" class="badge badge-pill badge-info">{{navItem.unreadInfos}}</span>
            </a>
          </li>
        </ul>

        <div class="btn-group">
          <button (click)="onAdminConsoleClicked()" *ngIf="isAdmin()" [ngClass]="{'nav-item-active': isAdminConsoleActive}" class="btn btn-light mr-2"
                  id="btn-user-management" type="button">
            <i class="fa fa-gear pr-1"></i>
            <span>{{ 'NAVIGATION.ADMIN_CONSOLE' | translate }}</span>
          </button>

          <button (click)="onUserProfileClicked()" [ngClass]="{'nav-item-active': isUserProfileActive}" class="btn btn-light mr-2" id="btn-user-profile"
                  type="button">
            <i class="fa fa-user pr-1"></i>
            <span>{{getUsername()}}</span>
          </button>

          <button (click)="logout()" class="btn btn-light mr-1" id="btn-logout" type="button">
            <i class="fa fa-sign-out pr-1"></i>
            <span>{{ 'NAVIGATION.LOGOUT' | translate }}</span>
          </button>

        </div>
      </div>
    </nav>

  </div>

  <div [ngClass]="{'content-mobile': isMobileView(),'content-normal': !isMobileView()}" class="content">
    <app-spinner #globalLoadingSpinner [delay]="1000" message="{{ 'GLOBAL_MESSAGES.SPINNER_MESSAGE' | translate }}"></app-spinner>
    <router-outlet></router-outlet>
  </div>

  <div id="footer">
    <small *ngIf="getApplicationInformation()">Version: {{getApplicationInformation().projectVersion}} - {{getApplicationInformation().buildTimestamp}}</small>
    <ul class="pull-right">
      <li>
        <small>
          <a href="https://www.vostov.de/?m=1&sid=7ml3m3oj6f9b6ccojuhg4qepio7p9r1g&hid=61&bid=1020" target="_blank">{{ 'FOOTER.DATENSCHUTZ' | translate }}</a>
        </small>
      </li>
      <li>
        <small>
          <a href="https://www.vostov.de/index.php?sid=a58o3kg29lmb1mervpnsaff1i0svkk7s&m=1&hid=61" target="_blank">{{ 'FOOTER.IMPRESSUM' | translate }}</a>
        </small>
      </li>
      <li>
        <small>
          <a href="https://www.vostov.de/?m=1&sid=a58o3kg29lmb1mervpnsaff1i0svkk7s&hid=60&bid=436" target="_blank">{{ 'FOOTER.KONTAKT' | translate }}</a>
        </small>
      </li>
    </ul>
  </div>


</div>




