import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {throwError} from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import {Observable} from 'rxjs/Observable';
import {catchError, tap} from 'rxjs/operators';
import {SharedService} from '../../../shared.service';
import {ApplicationService} from '../application.service';

@Injectable()
export class VostHttpInterceptor implements HttpInterceptor {
  constructor(private sharedService: SharedService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf(ApplicationService.API_INFORMATION_PATH) === -1) {
      this.sharedService.showLoadingSpinner();
    }

    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse && request.url.indexOf(ApplicationService.API_INFORMATION_PATH) === -1) {
        this.sharedService.hideLoadingSpinner();
      }
      return event;
    })).pipe(catchError(response => {
      if (response instanceof HttpErrorResponse && request.url.indexOf(ApplicationService.API_INFORMATION_PATH) === -1) {
        this.sharedService.hideLoadingSpinner();
      }
      return throwError(response);
    }));
  }
}
