<form id="user-form" #userForm="ngForm" class="form" role="form">
  <div class="row">

    <div class="col-12 col-sm-6 form-group">
      <label for="input-email-new">{{ 'VIEWS.USER_PROFILE.EMAIL' | translate }}</label>
      <input id="input-email-new" placeholder="{{ 'VIEWS.USER_PROFILE.EMAIL' | translate }}" [(ngModel)]="taxUser.email"
             [email]="true" name="userEmail"
             #userEmail="ngModel" class="form-control" required [pattern]="emailPattern">
      <small [style.visibility]="userEmail.errors ? 'visible' : 'hidden'" class="text-danger">
        <div [style.visibility]="userEmail.errors?.required ? 'visible' : 'hidden'">
          {{ 'VALIDATION_MESSAGES.VALUE_REQUIRED' | translate}}
        </div>
        <div [style.visibility]="userEmail.errors?.pattern ? 'visible' : 'hidden'">
          {{ 'ERROR_MESSAGES.INVALID_EMAIL' | translate }}
        </div>
      </small>
    </div>
  </div>

  <div class="mt-4">
    <button id="btn-cancel" (click)="cancel()" type="button" class="btn btn-sm btn-primary mr-2">
      {{'EDIT_MESSAGE.CANCEL' | translate}}
    </button>

    <button id="btn-save-new" (click)="saveUser()" [disabled]="!userForm.valid " type="button"
            class="btn btn-sm btn-outline-success">
      {{'EDIT_MESSAGE.SAVE' | translate}}
    </button>
  </div>

</form>
