import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {NewsFeedDto} from '../dto/news-feed-dto';
import {JsonHttpService} from './base/json-http';

@Injectable()
export class NewsService {
  public static apiUrl: string = '/api/news';

  constructor(private jsonHttpService: JsonHttpService) {

  }

  getNewsFeed(): Observable<NewsFeedDto[]> {
    return this.jsonHttpService.get<NewsFeedDto[]>(NewsService.apiUrl + '/getNewsFeed');
  }

}
