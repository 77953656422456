import {BaseDto} from './base-dto';
import {KundeDto} from './kunde-dto';

export class UserDto extends BaseDto {
  username: string;
  email: string;
  prename: string;
  surname: string;
  password: string;
  passwordNewOne: string;
  passwordNewTwo: string;
  role: UserDto.UserRole;
  enabled: boolean;
  hasToChangePassword: boolean = true;
  kunde: KundeDto;
  useConditionsAccepted: boolean = false;
  unreadRechnungen: number;
  unreadGutschriften: number;
  unreadAllgemeine: number;
  creatorId: number;
  lastLoginAt: Date;
  documentGroups: string[] = [];
  type: string = 'User';
}

export namespace UserDto {
  export enum UserRole {
    // @formatter:off
    VOST_SYS_ADMIN = <any> 'VOST_SYS_ADMIN',
    VOST_KUNDE_ADMIN = <any> 'VOST_KUNDE_ADMIN',
    VOST_USER = <any> 'VOST_USER'
    // @formatter:on
  }
}
