import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {DocumentDto} from '../../api/dto/document-dto';
import {PageDto} from '../../api/dto/page-dto';
import {SearchService} from '../../api/services/search.service';
import {LogService} from '../../core/log/LogService';
import VostDocumentGroup = DocumentDto.VostDocumentGroup;

@Component({
  selector: 'app-document-search',
  templateUrl: './document-search.component.html',
  styleUrls: ['./document-search.component.css']
})
export class DocumentSearchComponent {

  @Input() documentGroup: VostDocumentGroup;
  @Output() onSearch = new EventEmitter<PageDto>();

  constructor(private searchService: SearchService, private logService: LogService, private translateService: TranslateService, private toastr: ToastrService) {
  }

  onSearchClick($event: MouseEvent, searchInput: HTMLInputElement) {
    this.doSearch(searchInput.value);
  }

  onKeyDown($event, searchInput: HTMLInputElement) {
    if ($event.key === 'Enter') {
      this.doSearch(searchInput.value);
    }
  }

  private doSearch(text: string) {
    const documentGroup = this.documentGroup.toString();
    this.searchService.findAll(text, documentGroup.toString())
      .subscribe(res => {
        this.onSearch.emit(res);
      }, errorRes => {
        this.logService.log(errorRes);
        this.toastr.error(this.translateService.instant('ERROR_MESSAGES.SEARCH_FAILED'));
      });
  }
}
