import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DocumentFilterDto} from '../dto/document-filter-dto';
import {JsonHttpService} from './base/json-http';

@Injectable()
export class DocumentFilterService {
  public static apiUrl: string = '/api/documentFilter';

  constructor(private jsonHttpService: JsonHttpService) {
  }

  public findAll(): Observable<DocumentFilterDto[]> {
    return this.jsonHttpService.get<DocumentFilterDto[]>(DocumentFilterService.apiUrl + '/findAll');
  }

  public save(documentFilter: DocumentFilterDto): Observable<DocumentFilterDto> {
    return this.jsonHttpService.post<DocumentFilterDto>(DocumentFilterService.apiUrl + '/save', documentFilter);
  }


  public delete(id: number): Observable<boolean> {
    return this.jsonHttpService.delete<boolean>(DocumentFilterService.apiUrl + '/delete/' + id);
  }
}
