import {Component, Input, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnDestroy {
  isDelayedRunning: boolean;
  @Input() public delay: number;
  @Input() public message: string;
  private currentTimeout: any;

  constructor() {
    this.message = 'Loading...';
  }

  showSpinner(value: boolean): void {
    if (!value) {
      this.cancelTimeout();
      this.isDelayedRunning = false;
      return;
    }

    if (this.currentTimeout) {
      return;
    }

    this.currentTimeout = setTimeout(() => {
      this.isDelayedRunning = value;
      this.cancelTimeout();
    }, this.delay);
  }

  ngOnDestroy(): void {
    this.cancelTimeout();
  }

  getHeight(): number {
    return document.body.scrollHeight;
  }

  private cancelTimeout(): void {
    clearTimeout(this.currentTimeout);
    this.currentTimeout = undefined;
  }

}
