import {BaseDto} from './base-dto';

export class DocumentDto extends BaseDto {
  filename: string;
  filesize: number;
  mimetype: string;
  belegnummer: string;
  read: boolean;
  kundeId: number;
  kundeName: string;
  documentType: DocumentDto.VostDocumentType;
  documentGroup: DocumentDto.VostDocumentGroup;
}

export namespace DocumentDto {
  export enum VostDocumentType {
    // @formatter:off
  GUTSCHRIFT = <any> 'GUTSCHRIFT',
  ARTIKEL_RECHNUNG = <any> 'ARTIKEL_RECHNUNG',
  BESAMUNGS_RECHNUNG = <any> 'BESAMUNGS_RECHNUNG',
  MEDIKAMENT_RECHNUNG = <any> 'MEDIKAMENT_RECHNUNG',
  VIEH_RECHNUNG = <any> 'VIEH_RECHNUNG',
  VIEH_ABRECHNUNG = <any> 'VIEH_ABRECHNUNG',
  SONSTIGES = <any> 'SONSTIGES',
  ALLGEMEINE = <any> 'ALLGEMEINE'
    // @formatter:on
  }

  export function valueVostDocumentType(): VostDocumentType[] {
    return [
      VostDocumentType.GUTSCHRIFT,
      VostDocumentType.ARTIKEL_RECHNUNG,
      VostDocumentType.BESAMUNGS_RECHNUNG,
      VostDocumentType.MEDIKAMENT_RECHNUNG,
      VostDocumentType.VIEH_RECHNUNG,
      VostDocumentType.VIEH_ABRECHNUNG,
      VostDocumentType.SONSTIGES,
      VostDocumentType.ALLGEMEINE];
  }

  export enum VostDocumentGroup {
    // @formatter:off
  RECHNUNG = <any> 'RECHNUNG',
  ABRECHNUNG = <any> 'ABRECHNUNG',
  ALLE = <any> 'ALLE'
    // @formatter:on
  }

  export function valueVostDocumentGroup(): VostDocumentGroup[] {
    return [
      VostDocumentGroup.RECHNUNG,
      VostDocumentGroup.ABRECHNUNG,
      VostDocumentGroup.ALLE];
  }
}
