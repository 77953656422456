import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {IDropdownSelectorItem} from './i-dropdown-selector-item';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent<T> implements AfterViewInit {
  @Input() id: string = '';
  @Input() disabled: boolean;
  @Input() allowNullValue: boolean;
  @Input() initValue: IDropdownSelectorItem<T>;
  @Input() items: Array<IDropdownSelectorItem<T>> = [];
  @Output() onSelectionChanged: EventEmitter<any> = new EventEmitter();
  private nullValueLabel: string = '- - -';
  private initialInitValue: IDropdownSelectorItem<T>;

  ngAfterViewInit(): void {
    if (this.allowNullValue) {
      this.insertNullValue();
    }
    this.initialInitValue = this.initValue;
  }

  onValueSelected(dropdownSelectorItem: IDropdownSelectorItem<T>): void {
    this.initValue = dropdownSelectorItem;
    if (dropdownSelectorItem !== undefined) {
      this.onSelectionChanged.emit(dropdownSelectorItem.value);
    }
  }

  resetSelection2InitValue(): void {
    this.initValue = this.initialInitValue;
  }

  private insertNullValue(): void {
    let insertNullValue: boolean = true;
    this.items.forEach(elem => {
      if (elem.label === this.nullValueLabel) {
        insertNullValue = false;
      }
    });
    if (insertNullValue) {
      this.items.unshift({
        label: this.nullValueLabel,
        value: undefined,
        id: 'null'
      });
    }
  }

}
