<div id="dropdown-wrapper" class="[ btn-group ]  [ w-100 ]" dropdown [isDisabled]="disabled">
  <button [id]="id + '-dropdownToggle'" dropdownToggle type="button" class="[ btn  btn-default btn-sm ]  [ dropdown-toggle ]  [ w-100 ]"
          [ngClass]="{ 'dropdown-disabled': disabled}">
    <span *ngIf="initValue != undefined" class="[ float-left ]">{{ initValue.label }}</span>
  </button>
  <ul *dropdownMenu class="[ dropdown-menu ]" role="menu">
    <li *ngFor="let item of items" role="menuitem">
      <a id="{{id}}-{{item.id}}" class="[ dropdown-item ] [ pointer ]" (click)="onValueSelected(item)">{{ item.label}}</a>
    </li>
  </ul>
</div>
