import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {DocumentDto} from '../dto/document-dto';
import {KundeDto} from '../dto/kunde-dto';
import {Page} from '../dto/sort-filter-paging/page';
import {SearchFilterPagingDto} from '../dto/sort-filter-paging/search-filter-paging-dto';
import {JsonHttpService} from './base/json-http';
import VostDocumentGroup = DocumentDto.VostDocumentGroup;

@Injectable()
export class DocumentService {
  public static apiUrl: string = '/api/document';

  constructor(private jsonHttpService: JsonHttpService) {
  }

  public findAllAsPage(searchFilterPagingDto?: SearchFilterPagingDto): Observable<Page<DocumentDto>> {
    return this.jsonHttpService.post<Page<DocumentDto>>(DocumentService.apiUrl + '/findAllAsPage', searchFilterPagingDto);
  }

  markAllDocumentAsRead(documentGroup: VostDocumentGroup): Observable<any> {
    return this.jsonHttpService.get(DocumentService.apiUrl + '/markAllDocumentAsRead?documentGroup=' + documentGroup);
  }

  deleteDocumentForKunde(kunde: KundeDto): Observable<any> {
    return this.jsonHttpService.delete(DocumentService.apiUrl + '/deleteDocumentForKunde?kundeUuid=' + kunde.uuid);
  }

  findAllByUuid(ids: string[]) {
    return this.jsonHttpService.post<DocumentDto[]>(DocumentService.apiUrl + '/findAllByUuid', ids);
  }
}
