<app-content-header title="{{ 'VIEWS.USER_PROFILE.TITLE' | translate }}" [showSaveBtn]="true" (onSave)="saveUser()"
                    [savedBtnDisabled]="!userForm.form.valid || !passwordChangeValid() || !isChangePassword"></app-content-header>
<div class="content-wrapper">
  <form id="user-form" (keyup.enter)="userForm.form.valid && passwordChangeValid() && saveUser()" #userForm="ngForm" class="form" role="form">

    <div class="row">
      <div class="col-12 col-sm-6 form-group">
        <label for="input-username">{{ 'VIEWS.USER_PROFILE.USER_NAME' | translate }}</label>
        <input class="form-control" id="input-username" [value]="user.username" [disabled]="true">
      </div>
      <div class="col-12 col-sm-6 form-group">
        <label for="input-email">{{ 'VIEWS.USER_PROFILE.EMAIL' | translate }}</label>
        <input id="input-email" type="email" placeholder="{{ 'VIEWS.USER_PROFILE.EMAIL' | translate }}" [(ngModel)]="user.email" [email]="true" name="userEmail"
               #userEmail="ngModel" [disabled]="true" class="form-control">
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6 form-group" *ngIf="!isTaxUser()">
        <label for="input-prename">{{ 'VIEWS.USER_PROFILE.PRENAME' | translate }}</label>
        <input id="input-prename" placeholder="{{ 'VIEWS.USER_PROFILE.PRENAME' | translate }}" [(ngModel)]="user.prename" name="userPrename"
               #userPrename="ngModel" [disabled]="true" class="form-control">
      </div>

      <div class="col-12 col-sm-6 form-group" *ngIf="!isTaxUser()">
        <label for="input-surname">{{ 'VIEWS.USER_PROFILE.SURNAME' | translate }}</label>
        <input id="input-surname" placeholder="{{ 'VIEWS.USER_PROFILE.SURNAME' | translate }}" [(ngModel)]="user.surname" name="userSurname"
               #userSurname="ngModel" [disabled]="true" class="form-control">
      </div>

      <div class="col-12 col-sm-6 form-group" *ngIf="isTaxUser()">
        <label for="input-office">{{ 'VIEWS.USER_PROFILE.OFFICE' | translate }}</label>
        <input id="input-office" placeholder="{{ 'VIEWS.USER_PROFILE.OFFICE' | translate }}" [(ngModel)]="user.office" name="office" #userOffice="ngModel"
               [disabled]="true" class="form-control">
      </div>
    </div>


    <div class="form-group mt-2 mt-sm-4">
      <app-ui-switch id="switch-change-password" size="small" color="#a2c100" #changePassword="ngModel" name="changePassword" [(ngModel)]="isChangePassword"
                     text="{{ 'VIEWS.USER_PROFILE.CHANGE_PASSWORD' | translate }}"></app-ui-switch>

      <div *ngIf="isChangePassword ">
        <div class="form-group">
          <label for="input-password">{{ 'VIEWS.USER_PROFILE.OLD_PASSWORD' | translate }}</label>
          <input id="input-password" placeholder="{{ 'VIEWS.USER_PROFILE.OLD_PASSWORD' | translate }}" [(ngModel)]="user.password" name="password"
                 #userPassword="ngModel" class="form-control" type="password" [required]="isChangePassword">
          <small id="input-password-error" [style.visibility]="!user.password ? 'visible' : 'hidden'" class="form-text text-danger">
            {{ 'VALIDATION_MESSAGES.VALUE_REQUIRED' | translate }}
          </small>
        </div>

        <div class="row mt-2">
          <div class="col-12 col-sm-6 form-group">
            <label for="input-password-new-one">{{ 'VIEWS.USER_PROFILE.NEW_PASSWORD' | translate }}</label>
            <input id="input-password-new-one" placeholder="{{ 'VIEWS.USER_PROFILE.NEW_PASSWORD' | translate }}" [(ngModel)]="user.passwordNewOne"
                   name="passwordNewOne" #userPasswordNewOne="ngModel" class="form-control" type="password" [required]="isChangePassword">
            <small id="input-password-new-error" *ngIf="!isNewPasswordEquals" class="form-text text-warning">
              {{ 'VALIDATION_MESSAGES.PASSWORD_NOT_EQUALS' | translate }}
            </small>
            <small id="input-password-new-one-error" [style.visibility]="!user.passwordNewOne ? 'visible' : 'hidden'" class="form-text text-danger">
              {{ 'VALIDATION_MESSAGES.VALUE_REQUIRED' | translate }}
            </small>
          </div>

          <div class="col-12 col-sm-6 form-group">
            <label for="input-password-new-two">{{ 'VIEWS.USER_PROFILE.REPEAT_NEW_PASSWORD' | translate }}</label>
            <input id="input-password-new-two" placeholder="{{ 'VIEWS.USER_PROFILE.REPEAT_NEW_PASSWORD' | translate }}" [(ngModel)]="user.passwordNewTwo"
                   name="passwordNewTwo" #userPasswordNewTwo="ngModel" class="form-control" type="password" [required]="isChangePassword">
            <small id="input-password-new-two-error" [style.visibility]="!user.passwordNewTwo ? 'visible' : 'hidden'" class="form-text text-danger">
              {{ 'VALIDATION_MESSAGES.VALUE_REQUIRED' | translate }}
            </small>
          </div>
        </div>
        <app-password-policy [password]="user.passwordNewOne" (onPasswordChanged)="setNewPasswordValid($event)"></app-password-policy>
      </div>

    </div>

    <div id="customer-section" class="pt-3" *ngIf="!isAdmin() && kunde">
      <app-content-header title="{{ 'VIEWS.USER_PROFILE.CUSTOMER'  | translate }}"></app-content-header>
      <div class="row">
        <div class="col-12 col-sm-6 col-lg-4 form-group">
          <label for="input-firma">{{ 'VIEWS.USER_PROFILE.CUSTOMER_FIRMA' | translate }}</label>
          <input id="input-firma" placeholder="{{ 'VIEWS.USER_PROFILE.CUSTOMER_FIRMA' | translate }}" [(ngModel)]="kunde.firma" name="userFirma"
                 [disabled]="true" class="form-control">
        </div>

        <div class="col-12 col-sm-6 col-lg-4 form-group">
          <label for="input-ort">{{ 'VIEWS.USER_PROFILE.CUSTOMER_ORT' | translate }}</label>
          <input id="input-ort" placeholder="{{ 'VIEWS.USER_PROFILE.CUSTOMER_ORT' | translate }}" [(ngModel)]="kunde.ort" name="userOrt" [disabled]="true"
                 class="form-control">
        </div>

        <div class="col-12 col-sm-6 col-lg-4 form-group">
          <label for="input-company-key">{{ 'VIEWS.USER_PROFILE.COMPANY_KEY' | translate }}</label>
          <input id="input-company-key" placeholder="{{ 'IEWS.USER_PROFILE.COMPANY_KEY' | translate }}" [(ngModel)]="kunde.betriebsschluessel"
                 name="userBetriebsschluessel" [disabled]="true" class="form-control">
        </div>
      </div>

    </div>
  </form>

  <div class="pt-3" *ngIf="canCreateUser(user)">
    <app-content-header title="{{ 'VIEWS.USER_PROFILE.ASSOCIATED_CUSTOMER'  | translate }}"></app-content-header>

    <div class="toolbar">
      <button *ngIf="!enableDetail" id="btn-new" (click)="resetFields()" type="button" class="btn btn-sm btn-default">
        {{'EDIT_MESSAGE.NEW' | translate}}
      </button>
    </div>

    <ngx-datatable *ngIf="!enableDetail" #userTable class="material" [rows]="filteredUserRows" [columnMode]="'force'" [headerHeight]="40" [footerHeight]="50"
                   [limit]="12" [rowHeight]="'auto'" [messages]="tableMessages" [reorderable]="false" [scrollbarH]="true">

      <ngx-datatable-column [width]="200" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
          <i [id]="row.id+'btn-table-toogle-row'" (click)="toggleUserTableExpandRow(row)" class="fa pointer"
             [ngClass]="{ 'fa-minus': expanded , 'fa-plus': !expanded}"></i>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="{{ 'VIEWS.USER_PROFILE.USER_NAME' | translate }}" prop="username"></ngx-datatable-column>
      <ngx-datatable-column name="{{ 'VIEWS.USER_PROFILE.PRENAME' | translate }}" prop="prename"></ngx-datatable-column>
      <ngx-datatable-column name="{{ 'VIEWS.USER_PROFILE.SURNAME' | translate }}" prop="surname"></ngx-datatable-column>
      <ngx-datatable-column name="{{ 'VIEWS.USER_PROFILE.EMAIL' | translate }}" prop="email"></ngx-datatable-column>

      <!-- Row Detail Template -->
      <ngx-datatable-row-detail [rowHeight]="50">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
          <div *ngIf="!deleteRowMap.get(row.uuid)">
            <button [id]="row.id + '-btn-pre-delete-user'" (click)="showDeleteInline(row)"
                    class="btn btn-sm btn-default mr-2">{{ 'EDIT_MESSAGE.DELETE' | translate}}
            </button>
            <button [id]="row.id + '-btn-edit-user'" (click)="editInline(row)" class="btn btn-sm btn-default">{{ 'EDIT_MESSAGE.EDIT' | translate}}
            </button>
          </div>

          <div *ngIf="deleteRowMap.get(row.uuid)">
            <small class="mr-2"> {{ 'VIEWS.ADMIN_CONSOLE.DELETE_MSG'|translate }}?</small>
            <button [id]="row.id + '-btn-cancel-delete-user'" (click)="reinitInlineMaps()" class="btn btn-sm btn-secondary mr-2">{{ 'EDIT_MESSAGE.CANCEL' |
                translate }}
            </button>
            <button [id]="row.id + '-btn-delete-user'" (click)="deleteCustomerUser(row)" class="btn btn-sm btn-danger">
              {{ 'EDIT_MESSAGE.DELETE' | translate }}
            </button>
          </div>

        </ng-template>
      </ngx-datatable-row-detail>
    </ngx-datatable>

    <div class="clearfix detail-panel" [hidden]="!enableDetail">

      <div class="ml-2" [hidden]="!enableDetail">
        <div class="row">
          <div class="col">
            <app-new-user (afterSave)="saveCustomUser(user)" (onCancel)="onUserEditCancel()"></app-new-user>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Steuerberater-->
  <div class="tax user detail-panel" *ngIf="canCreateUser(user)">
    <app-tax-user-detail></app-tax-user-detail>
  </div>
</div>
