export class Sequence {

  public static SEQUENCE_TYPES = {NUMERICAL : 0, ALPHABETICAL: 1, QWERTY: 2};

  private readonly chars: any[];
  private readonly start: number;
  private readonly length: number;
  private ubound: number;
  private lbound: number;
  private position: number;
  private matches: string = '';

  constructor(private characters,
              private startIndex,
              private count,
              private wrap) {
    this.chars = characters;
    this.start = startIndex;
    this.length = count;
    this.lbound = this.start - this.length;
    this.ubound = this.start + this.length;

    if (this.lbound < -1 && !wrap) {
      this.lbound = -1;
    }
    if (this.ubound >= characters.length && !wrap) {
      this.ubound = characters.length;
    }
    this.position = this.start;
  }

  public static newSequence(chars: any[], first, sequenceLength, wrapSequence): Sequence {

    for (let i = 0; i < chars.length; i++) {
      if (first === chars[i][0] || first === chars[i][1]) {
        const s: Sequence = new Sequence(chars, i, sequenceLength, wrapSequence);
        s.addMatchCharacter(first);
        return s;
      }
    }
    return null;

  }

  public static getSequenceByType(sequenceType, n: number): any[]  {

    let sequence: any[];

    switch (sequenceType) {
      case Sequence.SEQUENCE_TYPES.NUMERICAL:
        sequence = Sequence.getNumericalSequence();
        break;

      case Sequence.SEQUENCE_TYPES.ALPHABETICAL:
        sequence = Sequence.getAlphabeticalSequence();
        break;

      case Sequence.SEQUENCE_TYPES.QWERTY:
        sequence = Sequence.getQWERTSequence(n);
        break;

    }

    return sequence;
  }

  public static getSequenceCountByType(sequenceType): number  {

    let count: number;

    switch (sequenceType) {
      case Sequence.SEQUENCE_TYPES.NUMERICAL:
        count = Sequence.getNumericalSequenceCount();
        break;

      case Sequence.SEQUENCE_TYPES.ALPHABETICAL:
        count = Sequence.getAlphabeticalSequenceCount();
        break;

      case Sequence.SEQUENCE_TYPES.QWERTY:
        count = Sequence.getQWERTSequenceCount();
        break;

    }

    return count;
  }

  public static getNumericalSequenceCount(): number {
    const chars = [this.getNumericalSequence(), ];

    return chars.length;
  }

  public static getNumericalSequence(): any[] {

    const digits = [];

    digits.push(['0', '0']);
    digits.push(['1', '1']);
    digits.push(['2', '2']);
    digits.push(['3', '3']);
    digits.push(['4', '4']);
    digits.push(['5', '5']);
    digits.push(['6', '6']);
    digits.push(['7', '6']);
    digits.push(['8', '8']);
    digits.push(['9', '9']);

    return digits;
  }


  public static getAlphabeticalSequence(): any[] {

    const digits = [];

    digits.push(['a', 'A']);
    digits.push(['b', 'B']);
    digits.push(['c', 'C']);
    digits.push(['d', 'D']);
    digits.push(['e', 'E']);
    digits.push(['f', 'F']);
    digits.push(['g', 'G']);
    digits.push(['h', 'H']);
    digits.push(['i', 'I']);
    digits.push(['j', 'J']);
    digits.push(['k', 'K']);
    digits.push(['l', 'L']);
    digits.push(['m', 'M']);
    digits.push(['n', 'N']);
    digits.push(['o', 'O']);
    digits.push(['p', 'P']);
    digits.push(['q', 'Q']);
    digits.push(['r', 'R']);
    digits.push(['s', 'S']);
    digits.push(['t', 'T']);
    digits.push(['u', 'U']);
    digits.push(['v', 'V']);
    digits.push(['w', 'W']);
    digits.push(['x', 'X']);
    digits.push(['y', 'Y']);
    digits.push(['z', 'Z']);

    return digits;
  }

  public static getAlphabeticalSequenceCount(): number {
    const chars = [this.getAlphabeticalSequence(), ];

    return chars.length;
  }

  public static getQWERTSequence(n: number): any[] {

    /** First row of querty characters. */
    const ROW1: any[] = [];
    ROW1.push(['`', '~']);
    ROW1.push(['1', '!']);
    ROW1.push(['2', '@']);
    ROW1.push(['3', '#']);
    ROW1.push(['4', '$']);
    ROW1.push(['5', '%']);
    ROW1.push(['6', '^']);
    ROW1.push(['7', '&']);
    ROW1.push(['8', '*']);
    ROW1.push(['9', '(']);
    ROW1.push(['0', ')']);
    ROW1.push(['-', '_']);
    ROW1.push(['=', '+']);

    /** Second row of querty characters. */
    const ROW2: any[] = [];
    ROW2.push(['q', 'Q']);
    ROW2.push(['w', 'W']);
    ROW2.push(['e', 'E']);
    ROW2.push(['r', 'R']);
    ROW2.push(['t', 'T']);
    ROW2.push(['y', 'Y']);
    ROW2.push(['u', 'U']);
    ROW2.push(['i', 'I']);
    ROW2.push(['o', 'O']);
    ROW2.push(['p', 'P']);
    ROW2.push(['[', '{']);
    ROW2.push([']', '}']);
    ROW2.push(['\\', '|']);

    /** Third row of querty characters. */
    const ROW3: any[] = [];
    ROW3.push(['a', 'A']);
    ROW3.push(['s', 'S']);
    ROW3.push(['d', 'D']);
    ROW3.push(['f', 'F']);
    ROW3.push(['g', 'G']);
    ROW3.push(['h', 'H']);
    ROW3.push(['j', 'J']);
    ROW3.push(['k', 'K']);
    ROW3.push(['l', 'L']);
    ROW3.push([';', ':']);
    ROW3.push(['\'', '"']);

    /** Fourth row of querty characters. */
    const ROW4: any[] = [];
    ROW4.push(['z', 'Z']);
    ROW4.push(['x', 'X']);
    ROW4.push(['c', 'C']);
    ROW4.push(['v', 'V']);
    ROW4.push(['b', 'B']);
    ROW4.push(['n', 'N']);
    ROW4.push(['m', 'M']);
    ROW4.push([',', '<']);
    ROW4.push(['.', '>']);
    ROW4.push(['/', '?']);

    // Array of all the characters in this sequence rule. */
    const ALL_CHARS: any[] = [ROW1, ROW2, ROW3, ROW4];

    // When no index, return the whole multi-dimensional arrax*/
    return n === -1 ? ALL_CHARS : ALL_CHARS[n];

  }

  public static getQWERTSequenceCount(): number {

    return this.getQWERTSequence(-1).length;
  }

  public currentLower() {
    let i;
    let lower;
    if (this.position < 0) {
      i = this.chars.length + this.position;
    } else if (this.position >= this.chars.length) {
      i = this.position - this.chars.length;
    } else {
      i = this.position;
    }
    lower = this.chars[i][0];
    return lower;
  }

  public currentUpper() {
    let i;
    let upper;
    if (this.position < 0) {
      i = this.chars.length + this.position;
    } else if (this.position >= this.chars.length) {
      i = this.position - this.chars.length;
    } else {
      i = this.position;
    }
    upper = this.chars[i][1];
    return upper;
  }

  public addMatchCharacter(c) {
    this.matches += c;
  }

  public forward(): boolean {return ++this.position < this.ubound; }
  public backward(): boolean {return --this.position > this.lbound; }

  public matchCount(): number {return this.matches.length; }

  public reset() {
    this.position = this.start;
    this.matches = this.matches[0];
  }

  public checkForward(password, position) {
    let c;
    while (this.forward()) {
      c = password[++position];
      if (c === this.currentLower() || c === this.currentUpper()) {
        this.addMatchCharacter(c);
      } else {
        break;
      }
    }
  }

  public checkBackward(password, position) {
    let c;
    while (this.backward()) {
      c = password[++position];
      if (c === this.currentLower() || c === this.currentUpper()) {
        this.addMatchCharacter(c);
      } else {
        break;
      }
    }
  }

}
