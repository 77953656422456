import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {DeviceDistributionDto} from '../dto/device-distribution-dto';
import {JobResultDto} from '../dto/job-result-dto';
import {KundeStatusDistributionDto} from '../dto/kunde-status-distribution-dto';
import {LogLevel} from '../dto/log-level';
import {MonitoringExecutionDurationDto} from '../dto/monitoring-execution-duration-dto';

@Injectable()
export class AdminService {
  public static apiUrl: string = '/api/admin';

  constructor(private http: HttpClient) {
  }

  startKundenImportManuell(): Observable<JobResultDto> {
    return this.http.get<JobResultDto>(AdminService.apiUrl + '/startKundenImportManuell');
  }

  startDocumentImportManuell(): Observable<JobResultDto> {
    return this.http.get<JobResultDto>(AdminService.apiUrl + '/startDocumentImportManuell');
  }

  startBestandImportManuell(): Observable<JobResultDto> {
    return this.http.get<JobResultDto>(AdminService.apiUrl + '/startBestandImportManuell');
  }

  startLoggingDeletionManuell(): Observable<JobResultDto> {
    return this.http.post<JobResultDto>(AdminService.apiUrl + '/startLoggingDeletionManuell', {});
  }

  getKundeStatusDistribution(): Observable<KundeStatusDistributionDto[]> {
    return this.http.get<KundeStatusDistributionDto[]>(AdminService.apiUrl + '/kundeStatusDistribution');
  }

  changeMaintenanceMode(activate: boolean): Observable<any> {
    return this.http.post<any>(AdminService.apiUrl + '/changeMaintenanceMode?activate=' + activate, {});
  }

  getMonitoringExecutionDurations(startDate: Date, endDate: Date): Observable<MonitoringExecutionDurationDto[]> {
    return this.http.get<MonitoringExecutionDurationDto[]>(
      AdminService.apiUrl + '/aggregateMonitoringExecutionDurations?startTime=' + startDate.getTime() + '&endTime=' + endDate.getTime());
  }

  getDeviceDistribution(): Observable<DeviceDistributionDto> {
    return this.http.get<DeviceDistributionDto>(AdminService.apiUrl + '/deviceDistribution');
  }

  getBlockedIpAdressCount(): Observable<string> {
    return this.http.get(AdminService.apiUrl + '/getBlockedIpAdressCount', {
      responseType: 'text'
    });
  }

  resetLoginAttempts(): Observable<any> {
    return this.http.get(AdminService.apiUrl + '/resetLoginAttempts');
  }

  getLogs(logLevels: LogLevel[], from: Date, to: Date): Observable<string> {
    return this.http.get(AdminService.apiUrl + '/logging?' + 'logLevels=' + logLevels + '&from=' + from.getTime() + '&to=' + to.getTime(), {
      responseType: 'text'
    });
  }

}
