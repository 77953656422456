import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {ToastrService} from 'ngx-toastr';
import {DocumentFilterDto} from '../../api/dto/document-filter-dto';
import {LogLevel} from '../../api/dto/log-level';
import {MonitoringExecutionDurationDto} from '../../api/dto/monitoring-execution-duration-dto';
import {UserDto} from '../../api/dto/user-dto';
import {AdminService} from '../../api/services/admin.service';
import {ApplicationService} from '../../api/services/application.service';
import {DocumentFilterService} from '../../api/services/document-filter.service';
import {DocumentService} from '../../api/services/document.service';
import {UserService} from '../../api/services/user.service';
import {BaseComponent} from '../../core/component/baseComponent';
import {LogService} from '../../core/log/LogService';
import {PieGridChartConfig} from '../../core/pie-grid-chart-config';
import {VostUtils} from '../../core/vost-utils';
import {SharedService} from '../../shared.service';
import {LogLevelItem} from './LogLevelItem';
import {BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-admin-console',
  templateUrl: './admin-console.component.html',
  styleUrls: ['./admin-console.component.css']
})
export class AdminConsoleComponent extends BaseComponent implements OnInit {
  monitoringRows: MonitoringExecutionDurationDto[] = [];
  monitoringIdicator: boolean = false;
  resetPwdRowMap: Map<string, boolean> = new Map<string, boolean>();
  enableDisableRowMap: Map<string, boolean> = new Map<string, boolean>();
  deleteRowMap: Map<string, boolean> = new Map<string, boolean>();
  deleteDocumentsRowMap: Map<string, boolean> = new Map<string, boolean>();
  blockedIpAdressCount: number = 0;

  userRows: UserDto[] = [];
  filteredUserRows: UserDto[] = [];
  filterBetriebsschluessel: string = '';
  filterKundennummer: string = '';

  consoleHeight: number = 20;
  lines2ShowInConsole: number = 500;
  consoleText: string;
  documentFilterList: DocumentFilterDto[] = [];
  additionalDocumentFilter: DocumentFilterDto = new DocumentFilterDto('', '');

  datePickerConfig: Partial<BsDatepickerConfig>;
  bsMonitoringDurationFilterRangeValue: Date[];

  bsLoggingRangeValue: Date[];
  logLevelItemList: LogLevelItem[];

  chartConfig: PieGridChartConfig = new PieGridChartConfig();
  deviceDistributionResult: any[] = [];
  kundeStatusDistributionResult: any[] = [];

  @ViewChild('userTable') userTable: DatatableComponent;
  @ViewChild('exceutionDurationTable') exceutionDurationTable: DatatableComponent;
  executionDurationPieChartGridDataMap: Map<MonitoringExecutionDurationDto, any[]> = new Map<MonitoringExecutionDurationDto, any[]>();

  @ViewChild('console') private consoleElementRef: ElementRef;

  constructor(private activatedRoute: ActivatedRoute, private sharedService: SharedService, private userService: UserService,
              private applicationService: ApplicationService, private adminService: AdminService, private toastr: ToastrService,
              private documentService: DocumentService, private documentFilterService: DocumentFilterService,
              private translateService: TranslateService, private logservice: LogService, private _localeService: BsLocaleService) {
    super(sharedService, translateService);
    this._localeService.use('de');
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 14);
    this.bsMonitoringDurationFilterRangeValue = [
      startDate,
      endDate];

  }

  ngOnInit() {
    this.activatedRoute.data
      .subscribe((data: { users: UserDto[] }) => {
        this.userRows = data.users;
        this.copyUserRowsAndResetUserFilter(this.userRows);
      });
    this.updateBlockedIpAdressCount();
    this.initLoggingValues();
  }

  updateKundeStatusDistributionData(update?: boolean) {
    if (update) {
      this.adminService.getKundeStatusDistribution().subscribe(res => {
        this.kundeStatusDistributionResult = [];
        res.forEach(kundeStatusDistributionDto => {
          const kundeStatus = 'VIEWS.ADMIN_CONSOLE.KUNDE_STATUS.' + (kundeStatusDistributionDto.kundeStatus ?
            kundeStatusDistributionDto.kundeStatus.toString() : 'NONE');
          this.kundeStatusDistributionResult.push({
            'name': this.translateService.instant(kundeStatus),
            'value': kundeStatusDistributionDto.count
          });
        });
      }, errorRes => {
        this.logservice.log(errorRes);
        this.toastr.error(this.translateService.instant('ERROR_MESSAGES.LOADING_KUNDE_STATUS_DISTRIBUTION'));
      });
    }
  }

  updateDeviceDistributionData(update?: boolean) {
    if (update) {
      this.adminService.getDeviceDistribution().subscribe(res => {
        this.deviceDistributionResult = this.getDeviceDistributionChartList(res.callCounterNormal, res.callCounterTablet, res.callCounterTablet);
      }, errorRes => {
        this.logservice.log(errorRes);
        this.toastr.error(this.translateService.instant('ERROR_MESSAGES.LOADING_MONITORING_DATA'));
      });
    }
  }

  updateMonitoringDurationData(update?: boolean) {
    if (update) {
      this.monitoringIdicator = true;
      this.adminService.getMonitoringExecutionDurations(this.bsMonitoringDurationFilterRangeValue[0], this.bsMonitoringDurationFilterRangeValue[1])
        .subscribe(res => {
          this.monitoringRows = res;
          this.monitoringIdicator = false;
          this.monitoringRows.forEach(row => {
            this.executionDurationPieChartGridDataMap.set(row,
              this.getDeviceDistributionChartList(row.callCounterNormal, row.callCounterTablet, row.callCounterMobile));
          });
        }, errorRes => {
          this.monitoringIdicator = false;
          this.logservice.log(errorRes);
          this.toastr.error(this.translateService.instant('ERROR_MESSAGES.LOADING_MONITORING_DATA'));
        });
    }
  }

  updateConsoleData(update?: boolean): void {
    if (update) {

      const activeLogLevels: LogLevel[] = [];
      this.logLevelItemList.forEach(logLevelItem => {
        if (logLevelItem.isActive) {
          activeLogLevels.push(logLevelItem.logLevel);
        }
      });

      this.adminService.getLogs(activeLogLevels, this.bsLoggingRangeValue[0], this.bsLoggingRangeValue[1]).subscribe(res => {
        this.consoleText = res;
        this.consoleElementRef.nativeElement.scrollTop = this.consoleElementRef.nativeElement.scrollHeight;
      }, errorRes => {
        this.logservice.log(errorRes);
        this.toastr.error(this.translateService.instant('ERROR_MESSAGES.UPDATE_CONSOLE'));
      });
    }
  }

  updateUserData(update?: boolean) {
    if (update) {
      this.userService.findAll().subscribe(res => {
        this.userRows = res;
        this.copyUserRowsAndResetUserFilter(this.userRows);
      }, errorRes => {
        this.logservice.log(errorRes);
        this.toastr.error(this.translateService.instant('ERROR_MESSAGES.LOADING_USER_DATA'));
      });
    }
  }

  resetLoginAttempts(): void {
    this.adminService.resetLoginAttempts().subscribe(res => {
      this.toastr.success(this.translateService.instant('SUCCESS_MESSAGES.RESET_LOGIN_ATTEMPTS'));
      this.updateBlockedIpAdressCount();
    }, errorRes => {
      this.toastr.error(this.translateService.instant('ERROR_MESSAGES.RESET_LOGIN_ATTEMPTS'));
    });
  }

  delete(user: UserDto): void {
    this.userService.deleteUser(user).subscribe(res => {
      this.showMessageAndUpdateConsole(true, this.translateService.instant('SUCCESS_MESSAGES.USER_DELETED'));
      this.userRows = res;
      this.copyUserRowsAndResetUserFilter(this.userRows);
    }, errorRes => {
      this.showMessageAndUpdateConsole(false, this.translateService.instant('ERROR_MESSAGES.USER_DELETED'));
    });
  }

  deleteDocuments(user: UserDto): void {
    this.documentService.deleteDocumentForKunde(user.kunde).subscribe(res => {
      this.showMessageAndUpdateConsole(true, this.translateService.instant('SUCCESS_MESSAGES.DELETE_DOCUMENTS'));
    }, errorRes => {
      this.logservice.log(errorRes);
      this.showMessageAndUpdateConsole(false, this.translateService.instant('ERROR_MESSAGES.DELETE_DOCUMENTS'));
    });
    this.reinitDeleteDocumentsInlineFlags();
  }

  triggerDocumentImportJob(): void {
    this.adminService.startDocumentImportManuell().subscribe(res => {
      this.showMessageAndUpdateConsole(res.successfull, res.message);
    }, errorRes => {
      this.logservice.log(errorRes);
      this.showMessageAndUpdateConsole(false, this.translateService.instant('ERROR_MESSAGES.DOCUMENT_IMPORT_JOB'));
    });
  }

  triggerKundeImportJob(): void {
    this.adminService.startKundenImportManuell().subscribe(res => {
      this.showMessageAndUpdateConsole(res.successfull, res.message);
      this.updateUserData(true);
      this.updateKundeStatusDistributionData(true);
    }, errorRes => {
      this.logservice.log(errorRes);
      this.showMessageAndUpdateConsole(false, this.translateService.instant('ERROR_MESSAGES.KUNDENSTAMM_IMPORT_JOB'));
    });
  }

  triggerBestandImportJob(): void {
    this.adminService.startBestandImportManuell().subscribe(res => {
      this.showMessageAndUpdateConsole(res.successfull, res.message);
    }, errorRes => {
      this.showMessageAndUpdateConsole(false, this.translateService.instant('ERROR_MESSAGES.BESTAND_IMPORT_JOB'));
      this.logservice.log(errorRes);
    });
  }

  triggerLoggingDeletionJob(): void {
    this.adminService.startLoggingDeletionManuell().subscribe(res => {
      this.showMessageAndUpdateConsole(res.successfull, res.message);
    }, errorRes => {
      this.showMessageAndUpdateConsole(false, this.translateService.instant('ERROR_MESSAGES.LOGGING_DELETION_JOB'));
      this.logservice.log(errorRes);
    });
  }

  showMessageAndUpdateConsole(success: boolean, msg: string): void {
    if (success) {
      this.toastr.success(msg);
    } else {
      this.toastr.error(msg);
    }
    this.updateConsoleData();
  }

  reinitInlineMaps(): void {
    this.reinitResetPwdInlineFlags();
    this.reinitDeleteInlineFlag();
    this.reinitEnableDisableFlags();
    this.reinitDeleteDocumentsInlineFlags();
  }

  showResetPwdInline(row: UserDto): void {
    this.reinitInlineMaps();
    this.resetPwdRowMap.set(row.uuid, true);
  }

  reinitResetPwdInlineFlags(): void {
    for (const key of Array.from(this.resetPwdRowMap.keys())) {
      this.resetPwdRowMap.set(key, false);
    }
  }

  showDeleteInline(row: UserDto): void {
    this.reinitInlineMaps();
    this.deleteRowMap.set(row.uuid, true);
  }

  reinitDeleteInlineFlag(): void {
    for (const key of Array.from(this.deleteRowMap.keys())) {
      this.deleteRowMap.set(key, false);
    }
  }

  showdDeleteDocumentsInline(row: UserDto): void {
    this.reinitInlineMaps();
    this.deleteDocumentsRowMap.set(row.uuid, true);
  }

  reinitDeleteDocumentsInlineFlags(): void {
    for (const key of Array.from(this.deleteDocumentsRowMap.keys())) {
      this.deleteDocumentsRowMap.set(key, false);
    }
  }

  showDeleteDocumentsInline(row: UserDto): void {
    this.reinitInlineMaps();
    this.enableDisableRowMap.set(row.uuid, true);
  }

  resetPwd(user: UserDto): void {
    this.userService.resetPassword(user).subscribe(res => {
      this.userRows = res;
      this.copyUserRowsAndResetUserFilter(this.userRows);
      this.updateKundeStatusDistributionData(true);
      this.showMessageAndUpdateConsole(true, this.translateService.instant('SUCCESS_MESSAGES.RESET_PASSWORD'));
    }, errorRes => {
      this.logservice.log(errorRes);
      this.showMessageAndUpdateConsole(false, this.translateService.instant('ERROR_MESSAGES.RESET_PASSWORD'));
    });
    this.reinitResetPwdInlineFlags();
  }

  showEnableDisableInline(row: UserDto): void {
    this.reinitInlineMaps();
    this.enableDisableRowMap.set(row.uuid, true);
  }

  enableDisable(user: UserDto): void {
    user.enabled = !user.enabled;
    this.userService.enableDisable(user).subscribe(res => {
      this.userRows = res;
      this.updateKundeStatusDistributionData(true);
      this.copyUserRowsAndResetUserFilter(this.userRows);
      this.showMessageAndUpdateConsole(true, this.translateService.instant(user.enabled ? 'SUCCESS_MESSAGES.USER_ENABLED' : 'SUCCESS_MESSAGES.USER_DISABLED'));
    }, errorRes => {
      this.logservice.log(errorRes);
      this.showMessageAndUpdateConsole(false, this.translateService.instant(user.enabled ? 'ERROR_MESSAGES.USER_ENABLED' : 'ERROR_MESSAGES.USER_DISABLED'));
    });
    this.reinitEnableDisableFlags();
  }

  toggleLogLevel(logLevelItem: LogLevelItem): void {
    this.logLevelItemList.forEach(currentLogLevelItem => {
      if (logLevelItem === currentLogLevelItem) {
        currentLogLevelItem.isActive = !currentLogLevelItem.isActive;
      }
    });
  }

  reinitEnableDisableFlags(): void {
    for (const key of Array.from(this.enableDisableRowMap.keys())) {
      this.enableDisableRowMap.set(key, false);
    }
  }

  toggleUserTableExpandRow(row): void {
    this.userTable.rowDetail.toggleExpandRow(row);
  }

  toggleExecutionDurationTableExpandRow(row): void {
    this.exceutionDurationTable.rowDetail.toggleExpandRow(row);
  }

  isMaintenanceMode(): boolean {
    if (this.sharedService && this.sharedService.applicationInformation) {
      return this.sharedService.applicationInformation.maintenanceActive;
    } else {
      return false;
    }
  }

  changeMaintenanceMode(): void {
    const switchedMode: boolean = !this.isMaintenanceMode();
    this.adminService.changeMaintenanceMode(switchedMode).subscribe(res => {
      this.sharedService.applicationInformation.maintenanceActive = switchedMode;
      this.toastr.success(this.translateService.instant('SUCCESS_MESSAGES.MAINTENANCE_MODE_' + (switchedMode ? 'ACTIVATED' : 'DEACTIVATED')));
    }, error1 => {
      this.toastr.error(this.translateService.instant('ERROR_MESSAGES.MAINTENANCE_MODE_' + (switchedMode ? 'ACTIVATED' : 'DEACTIVATED')));
    });
  }

  copyUserRowsAndResetUserFilter(userRows: UserDto[]): void {
    this.filteredUserRows = [...userRows];
    this.filterBetriebsschluessel = '';
    this.filterKundennummer = '';
  }

  filterUserTable(): void {
    this.filteredUserRows = [];
    const kundennummerFilterActive: boolean = VostUtils.isNotEmpty(this.filterKundennummer);
    const betriebsschluesselFilterActive: boolean = VostUtils.isNotEmpty(this.filterBetriebsschluessel);

    this.userRows.forEach(userEntry => {

      const kundennummerMatch: boolean = kundennummerFilterActive ? (userEntry.kunde && userEntry.kunde.kundennummer && userEntry.kunde.kundennummer.toString()
        .indexOf(this.filterKundennummer) !== -1) : true;

      const betriebsschluesselMatch: boolean = betriebsschluesselFilterActive ?
        (userEntry.kunde && userEntry.kunde.betriebsschluessel && userEntry.kunde.betriebsschluessel.toString()
          .indexOf(this.filterBetriebsschluessel) !== -1) : true;

      if (kundennummerMatch && betriebsschluesselMatch) {
        this.filteredUserRows.push(userEntry);
      }
    });
  }

  resetUserConditionsFlag(): void {
    this.userService.resetUserConditionsFlag().subscribe(res => {
      this.toastr.success(this.translateService.instant('SUCCESS_MESSAGES.RESET_USE_CONDITIONS_FLAG'));
    }, errorRes => {
      this.toastr.error(this.translateService.instant('ERROR_MESSAGES.RESET_USE_CONDITIONS_FLAG'));
    });
  }

  setAllCustomerTriggerFlag(): void {
    this.userService.setAllCustomerTriggerFlag().subscribe(res => {
      this.toastr.success(this.translateService.instant('SUCCESS_MESSAGES.ALL_CUSTOMER_TRIGGER_FLAG'));
    }, errorRes => {
      this.toastr.error(this.translateService.instant('ERROR_MESSAGES.ALL_CUSTOMER_TRIGGER_FLAG'));
    });
  }

  setCustomerTriggerFlag(user: UserDto) {
    this.userService.setCustomerTriggerFlag(user).subscribe(res => {
      this.toastr.success(this.translateService.instant('SUCCESS_MESSAGES.CUSTOMER_TRIGGER_FLAG', {'username': user.username}));
    }, errorRes => {
      this.toastr.error(this.translateService.instant('ERROR_MESSAGES.CUSTOMER_TRIGGER_FLAG', {'username': user.username}));
    });
  }

  updateDocumentFilterData(isOpen: boolean) {
    if (isOpen) {
      this.documentFilterService.findAll().subscribe(documentFilters =>
        this.documentFilterList = documentFilters);
    }
  }

  deleteDocFilter(row: DocumentFilterDto) {
    this.documentFilterService.delete(row.id).subscribe(isSuccess => {
      if (isSuccess) {
        const index = this.documentFilterList.indexOf(row);
        if (index > -1) {
          this.documentFilterList.splice(index, 1);
        }
        this.toastr.success(this.translateService.instant('VIEWS.ADMIN_CONSOLE.DOCUMENT_FILTER.DELETION_SUCCESS'));
      } else {
        this.toastr.error(this.translateService.instant('VIEWS.ADMIN_CONSOLE.DOCUMENT_FILTER.DELETION_FAILED'));
      }
    });
  }

  saveDocFilter() {
    if (this.additionalDocumentFilter.value !== '' && this.additionalDocumentFilter.label !== '') {
      this.documentFilterService.save(this.additionalDocumentFilter)
        .subscribe(() => {
          this.toastr.success(this.translateService.instant('VIEWS.ADMIN_CONSOLE.DOCUMENT_FILTER.SAVE_SUCCESS'));
          this.additionalDocumentFilter = new DocumentFilterDto('', '');

          this.updateDocumentFilterData(true);
        }, () => this.toastr.error(this.translateService.instant('VIEWS.ADMIN_CONSOLE.DOCUMENT_FILTER.SAVE_FAILED')));
    } else {
      this.toastr.warning(this.translateService.instant('VIEWS.ADMIN_CONSOLE.DOCUMENT_FILTER.SAVE_REQUIREMENT'));
    }
  }

  private initLoggingValues(): void {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 2);
    this.bsLoggingRangeValue = [
      startDate,
      endDate];

    this.logLevelItemList = [];
    this.logLevelItemList.push({
      logLevel: LogLevel.INFO,
      isActive: false
    }, {
      logLevel: LogLevel.DEBUG,
      isActive: false
    }, {
      logLevel: LogLevel.ERROR,
      isActive: true
    }, {
      logLevel: LogLevel.FATAL,
      isActive: false
    }, {
      logLevel: LogLevel.TRACE,
      isActive: false
    }, {
      logLevel: LogLevel.WARN,
      isActive: true
    });
  }

  private getDeviceDistributionChartList(counterNormal: number, counterTablet: number, counterMobile: number) {
    return [
      {
        'name': this.translateService.instant('DEVICE_TYPE.NORMAL'),
        'value': counterNormal
      },
      {
        'name': this.translateService.instant('DEVICE_TYPE.TABLET'),
        'value': counterTablet
      },
      {
        'name': this.translateService.instant('DEVICE_TYPE.MOBILE'),
        'value': counterMobile
      }];
  }

  private updateBlockedIpAdressCount() {
    this.adminService.getBlockedIpAdressCount().subscribe(res => {
      this.blockedIpAdressCount = Number(res);
    });
  }
}
