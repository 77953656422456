export class DashboardInformation {
  imageUrl: string;
  headerText: string;
  text: string;
  url: string;

  constructor(imageUrl: string, headerText: string, text: string, url: string) {
    this.imageUrl = imageUrl;
    this.headerText = headerText;
    this.text = text;
    this.url = url;
  }
}
