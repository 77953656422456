import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TaxUserDto} from '../dto/tax-user-dto';
import {UserDto} from '../dto/user-dto';
import {JsonHttpService} from './base/json-http';

@Injectable()
export class TaxUserService {
  public static apiUrl: string = '/api/tax';

  constructor(private jsonHttpService: JsonHttpService) {
  }

  registerTaxUserForKunde(email: string, kundennummer: string): Observable<TaxUserDto> {
    return this.jsonHttpService.post<TaxUserDto>(TaxUserService.apiUrl + '/createTaxUserForKunde' + '/' + kundennummer + '/' + email, {});
  }

  findAllTaxUserForKunde(kundennummer: string): Observable<TaxUserDto[]> {
    return this.jsonHttpService.get<TaxUserDto[]>(TaxUserService.apiUrl + '/findAllTaxUserForKunde' + '/' + kundennummer);
  }

  deleteTaxUserForKunde(email: string, kundennummer: string): Observable<TaxUserDto[]> {
    return this.jsonHttpService.get<TaxUserDto[]>(TaxUserService.apiUrl + '/deleteTaxUserForKunde' + '/' + kundennummer + '/' + email);
  }

  isUserNameInUse(user: UserDto | TaxUserDto): Observable<boolean> {
    return this.jsonHttpService.post<boolean>(TaxUserService.apiUrl + '/isUsernameAlreadyInUse', user);
  }
}
