import {Injectable} from '@angular/core';

import {SharedService} from '../../shared.service';


@Injectable()
export class CanActivateAdminGuard  {

  constructor(private sharedService: SharedService) {

  }

  canActivate(): boolean {
    return this.sharedService.isSignedIn() && this.sharedService.isAdmin();
  }
}
