import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {TaxUserDto} from '../dto/tax-user-dto';
import {UserDto} from '../dto/user-dto';
import {JsonHttpService} from './base/json-http';

@Injectable()
export class UserService {
  public static apiUrl: string = '/api/user';

  constructor(private jsonHttpService: JsonHttpService) {
  }

  findAll(): Observable<UserDto[]> {
    return this.jsonHttpService.get<UserDto[]>(UserService.apiUrl + '/findAll');
  }

  createUserForUser(user: UserDto): Observable<UserDto> {
    return this.jsonHttpService.post<UserDto>(UserService.apiUrl + '/createUser', user);
  }

  update(user: UserDto | TaxUserDto): Observable<UserDto | TaxUserDto> {
    return this.jsonHttpService.put<UserDto | TaxUserDto>(UserService.apiUrl + '/update', user);
  }

  resetPassword(user: UserDto): Observable<UserDto[]> {
    return this.jsonHttpService.post<UserDto[]>(UserService.apiUrl + '/resetPassword', user);
  }

  enableDisable(user: UserDto): Observable<UserDto[]> {
    return this.jsonHttpService.post<UserDto[]>(UserService.apiUrl + '/enableDisabledUser', user);
  }

  deleteUser(user: UserDto): Observable<UserDto[]> {
    return this.jsonHttpService.delete<UserDto[]>(UserService.apiUrl + '/delete?uuid=' + user.uuid);
  }

  deleteCustomerUser(user: UserDto): Observable<UserDto[]> {
    return this.jsonHttpService.delete<UserDto[]>(UserService.apiUrl + '/deleteCustomerUser/' + user.uuid);
  }

  setUseConditionsAccepted(): Observable<boolean> {
    return this.jsonHttpService.put(UserService.apiUrl + '/useconditions', true);
  }

  resetUserConditionsFlag(): Observable<any> {
    return this.jsonHttpService.put(UserService.apiUrl + '/resetUseConditionsAcceptedFlag', {});
  }

  setAllCustomerTriggerFlag(): Observable<any> {
    return this.jsonHttpService.put(UserService.apiUrl + '/setAllCustomerTriggerFlag', {});
  }

  setCustomerTriggerFlag(user: UserDto): Observable<any> {
    return this.jsonHttpService.put(UserService.apiUrl + '/setCustomerTriggerFlag', user);
  }
}

