import {BaseDto} from './base-dto';
import {KundeDto} from './kunde-dto';
import {UserDto} from './user-dto';

export class TaxUserDto extends BaseDto {

  username: string;
  email: string;
  password: string;
  passwordNewOne: string;
  passwordNewTwo: string;
  role: UserDto.UserRole;
  enabled: boolean;
  hasToChangePassword: boolean = true;
  useConditionsAccepted: boolean = false;
  unreadRechnungen: number;
  unreadGutschriften: number;
  unreadAllgemeine: number;
  creatorId: number;
  lastLoginAt: Date;
  office: string;
  kunden: KundeDto[];
  documentGroups: string[] = [];
  type: string = 'TaxUser';
}
