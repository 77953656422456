<app-content-header [showSaveBtn]="false" title="{{ 'VIEWS.INITIAL_LOGIN.TITLE' | translate }}"></app-content-header>

<div class="content-wrapper">
  <div *ngIf="!isTaxUser()" class="mt-5 mb-3">
    <h5>{{'VIEWS.INITIAL_LOGIN.MESSAGE' | translate}}</h5>
  </div>
  <div *ngIf="isTaxUser() && isInitialLoginForTaxUser" class="mt-2 mb-3">
    <h5>Während der ersten Nutzung müssen Sie für Ihr Steuerbüro ein persönliches Passwort vergeben.
      <br>
      Sobald Sie sich eingeloggt haben, stehen Ihnen in die Dokumente Ihres Mandanten im Bereich (Ab-)Rechnungen zur Verfügung!
      <br>
      Bei auftretenden Fragen wenden Sie sich bitte an Ihren Mandanten oder den VOST in Leer unter der Telefonnummer 0491/8004-555. Wir helfen Ihnen gerne
      weiter!
    </h5>
  </div>

  <div class="row">
    <div class="col-12 form-group">
      <label for="input-password">{{ 'VIEWS.INITIAL_LOGIN.INITIAL_PASSWORD' | translate }}</label>
      <input id="input-password" placeholder="{{ 'VIEWS.INITIAL_LOGIN.INITIAL_PASSWORD' | translate }}" [(ngModel)]="currentUser.password" name="password"
             #userPassword="ngModel" class="form-control" type="password">
      <small id="input-password-error" [style.visibility]="!currentUser.password ? 'visible' : 'hidden'" class="form-text text-danger">
        {{ 'VALIDATION_MESSAGES.VALUE_REQUIRED' | translate }}
      </small>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-sm-6 col-md-4 form-group">
      <label for="input-password-new-one">{{ 'VIEWS.INITIAL_LOGIN.NEW_PASSWORD' | translate }}</label>
      <input id="input-password-new-one" placeholder="{{ 'VIEWS.INITIAL_LOGIN.NEW_PASSWORD' | translate }}" [(ngModel)]="currentUser.passwordNewOne"
             name="passwordNewOne" #userPasswordNewOne="ngModel" class="form-control" type="password">
      <small id="input-password-new-error" *ngIf="!isNewPasswordEquals" class="form-text text-warning">
        {{ 'VALIDATION_MESSAGES.PASSWORD_NOT_EQUALS' | translate }}
      </small>
      <small id="input-password-new-one-error" [style.visibility]="!currentUser.passwordNewOne ? 'visible' : 'hidden'" class="form-text text-danger">
        {{ 'VALIDATION_MESSAGES.VALUE_REQUIRED' | translate }}
      </small>
    </div>

    <div class="col-12 col-sm-6 col-md-4 form-group">
      <label for="input-password-new-two">{{ 'VIEWS.INITIAL_LOGIN.REPEAT_NEW_PASSWORD' | translate }}</label>
      <input id="input-password-new-two" placeholder="{{ 'VIEWS.INITIAL_LOGIN.REPEAT_NEW_PASSWORD' | translate }}" [(ngModel)]="currentUser.passwordNewTwo"
             name="passwordNewTwo" #userPasswordNewTwo="ngModel" class="form-control" type="password">
      <small id="input-password-new-two-error" [style.visibility]="!currentUser.passwordNewTwo ? 'visible' : 'hidden'" class="form-text text-danger">
        {{ 'VALIDATION_MESSAGES.VALUE_REQUIRED' | translate }}
      </small>
    </div>

    <div class="col-12 col-sm-6 form-group" *ngIf="isTaxUser() && isInitialLoginForTaxUser">
      <label for="input-office">{{ 'VIEWS.USER_PROFILE.OFFICE' | translate }} {{ 'VIEWS.USER_PROFILE.OFFICE_TAX_HINT' | translate }}</label>
      <input id="input-office" placeholder="{{ 'VIEWS.USER_PROFILE.OFFICE' | translate }} {{ 'VIEWS.USER_PROFILE.OFFICE_TAX_HINT' | translate }}"
             [(ngModel)]="currentUser.office" name="office" #userOffice="ngModel" class="form-control">
      <small id="input-office_error" [style.visibility]="!currentUser.office ? 'visible' : 'hidden'" class="form-text text-danger">
        {{ 'VALIDATION_MESSAGES.OFFICE_INVALID' | translate }}
      </small>
    </div>

    <div class="col-12 col-sm-6 form-group" *ngIf="isTaxUser() && isInitialLoginForTaxUser">
      <label for="input-username-new">{{ 'VIEWS.USER_PROFILE.USER_NAME' | translate }}</label>
      <input id="input-username-new" placeholder="{{ 'VIEWS.USER_PROFILE.USER_NAME' | translate }}" [(ngModel)]="displayUsername" name="userName"
             #userName="ngModel" class="form-control" required [pattern]="usernamePattern">
      <small [style.visibility]="userName.errors ? 'visible' : 'hidden'" class="text-danger">
        <div *ngIf="userName.errors?.pattern || !isTaxUserNameValid()">
          {{ getErrorMessageForUsername(userName.value) | translate }}
        </div>
        <div *ngIf="userName.errors?.required">
          {{ 'VALIDATION_MESSAGES.VALUE_REQUIRED' | translate }}
        </div>
      </small>
    </div>

    <app-password-policy *ngIf="isTaxUser() && isInitialLoginForTaxUser" [password]="currentUser.passwordNewOne"
                         (onPasswordChanged)="setNewPasswordValid($event)"></app-password-policy>

    <div class="col-12 col-sm-12 col-md-4 form-group">
      <button id="btn-save" [disabled]="!passwordChangeValid()" (click)="saveUser()" type="button" class="btn btn-outline-success w-100">
        {{'EDIT_MESSAGE.SAVE' | translate}}
      </button>
    </div>

    <app-password-policy *ngIf="!(isTaxUser() && isInitialLoginForTaxUser)" [password]="currentUser.passwordNewOne"
                         (onPasswordChanged)="setNewPasswordValid($event)"></app-password-policy>

  </div>
</div>
