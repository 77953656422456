import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {ToastrService} from 'ngx-toastr';
import {TaxUserDto} from '../../api/dto/tax-user-dto';
import {UserDto} from '../../api/dto/user-dto';
import {TaxUserService} from '../../api/services/tax.user.service';
import {BaseComponent} from '../../core/component/baseComponent';
import {LogService} from '../../core/log/LogService';
import {SharedService} from '../../shared.service';
import {NewTaxUserComponent} from '../new-tax-user/new-tax-user.component';

@Component({
  selector: 'app-tax-user-detail',
  templateUrl: './tax-user-detail.component.html',
  styleUrls: ['./tax-user-detail.component.css']
})
export class TaxUserDetailComponent extends BaseComponent implements OnInit {
  enableDetail: boolean = false;
  taxUserRows: TaxUserDto[] = [];
  filteredTaxUserRows: TaxUserDto[] = [];
  taxUser: TaxUserDto;

  @ViewChild(NewTaxUserComponent) newTaxUserComponent: NewTaxUserComponent;
  @ViewChild('userTable') userTable: DatatableComponent;

  deleteRowMap: Map<string, boolean> = new Map<string, boolean>();

  constructor(private sharedService: SharedService, private taxUserService: TaxUserService, private translateService: TranslateService,
              private toastr: ToastrService, private logservice: LogService) {
    super(sharedService, translateService);
  }

  ngOnInit() {
    this.loadTaxUserForCurentUser();
  }

  resetFields(): void {
    this.enableDetail = true;
    this.newTaxUserComponent.setUser(new TaxUserDto());
  }

  reinitDeleteInlineFlag(): void {
    for (const key of Array.from(this.deleteRowMap.keys())) {
      this.deleteRowMap.set(key, false);
    }
  }

  toggleUserTableExpandRow(row: TaxUserDto): void {
    this.userTable.rowDetail.toggleExpandRow(row);

    if (this.newTaxUserComponent && this.newTaxUserComponent.getUser() === row) {
      this.enableDetail = false;
    }
  }

  showDeleteInline(row: TaxUserDto): void {
    this.reinitDeleteInlineFlag();
    this.deleteRowMap.set(row.uuid, true);
  }

  loadTaxUserForCurentUser() {
    // Nur der UserDto kann sich alle Steuerberater anzeigen lassen
    this.taxUserService.findAllTaxUserForKunde((this.sharedService.currentUser as UserDto).kunde.kundennummer).subscribe(res => {
      this.taxUserRows = res;
      this.copyUserRowsAndResetUserFilter(this.taxUserRows);
    }, errorRes => {
      this.logservice.log(errorRes);
      this.toastr.error(this.translateService.instant('ERROR_MESSAGES.LOADING_USER_DATA'));
    });
  }

  onUserEditCancel() {
    this.enableDetail = false;
  }

  deleteCustomerUser(taxUser: TaxUserDto) {
    // nur UserDtos können TaxUserDtos löschen
    let currentUser: UserDto;
    currentUser = this.sharedService.currentUser as UserDto;
    const kundennummer = currentUser.kunde.kundennummer;
    this.taxUserService.deleteTaxUserForKunde(taxUser.email, kundennummer).subscribe(res => {
      this.enableDetail = false;
      this.resetUserFilter(res);
    }, errorRes => {
      this.showMessage(false, this.translateService.instant('ERROR_MESSAGES.TAXUSER_DELETED'));
    });
  }

  private copyUserRowsAndResetUserFilter(userRows: TaxUserDto[]) {
    this.filteredTaxUserRows = [...userRows];
  }

  private resetUserFilter(res) {
    this.showMessage(true, this.translateService.instant('SUCCESS_MESSAGES.TAXUSER_DELETED'));
    this.taxUserRows = res;
    this.copyUserRowsAndResetUserFilter(this.taxUserRows);
  }

  showMessage(success: boolean, msg: string): void {
    if (success) {
      this.toastr.success(msg);
    } else {
      this.toastr.error(msg);
    }
  }

  cancelTaxUser() {
    this.enableDetail = false;
  }

  saveTaxUser(taxUser: TaxUserDto) {
    this.enableDetail = false;
    this.loadTaxUserForCurentUser();
  }

}
