import {Injectable, isDevMode} from '@angular/core';

@Injectable()
export class LogService {

  constructor() {
    this.log('LogService created');
  }


  log(msg: any): void {
    if (isDevMode()) {
      /* tslint:disable */
      console.log(msg);
      /* tslint:enable */
    }
  }
}
