import {FilterCriteriaConjunction} from './filter-criteria-conjunction';
import {FilterType} from './filter-type';

export class FilterCriteria {
  key: string;
  value: any;
  filterType: FilterType;
  filterCriteriaConjunction: FilterCriteriaConjunction = FilterCriteriaConjunction.AND;

  constructor(key: string, value: any, filterType: FilterType, filterCriteriaConjunction: FilterCriteriaConjunction = FilterCriteriaConjunction.AND) {
    this.key = key;
    this.value = value;
    this.filterType = filterType;
    this.filterCriteriaConjunction = filterCriteriaConjunction;
  }

}
