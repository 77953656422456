import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {TaxUserDto} from '../../api/dto/tax-user-dto';
import {UserDto} from '../../api/dto/user-dto';
import {TaxUserService} from '../../api/services/tax.user.service';
import {BaseComponent} from '../../core/component/baseComponent';
import {LogService} from '../../core/log/LogService';
import {SharedService} from '../../shared.service';

@Component({
  selector: 'app-new-tax-user',
  templateUrl: './new-tax-user.component.html',
  styleUrls: ['./new-tax-user.component.css']
})
export class NewTaxUserComponent extends BaseComponent implements OnInit {
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';

  @Output() afterSave: EventEmitter<TaxUserDto> = new EventEmitter<TaxUserDto>();
  @Output() onCancel: EventEmitter<VoidFunction> = new EventEmitter<VoidFunction>();

  taxUser: TaxUserDto;

  constructor(private sharedService: SharedService, private translateService: TranslateService, private toastr: ToastrService, private logService: LogService,
              private taxUserService: TaxUserService) {
    super(sharedService, translateService);
    this.taxUser = new TaxUserDto();
    this.taxUser.creatorId = sharedService.currentUser.id;
  }

  ngOnInit() {
  }

  cancel() {
    this.onCancel.emit();
  }

  saveUser() {
    this.registerUserForKunde();
  }

  registerUserForKunde() {
    // Nur UserDto kann andere User anlegen
    this.taxUserService.registerTaxUserForKunde(this.taxUser.email, (this.sharedService.currentUser as UserDto).kunde.kundennummer).subscribe(res => {
      if (this.taxUser.new) {
        this.toastr.success(this.translateService.instant('SUCCESS_MESSAGES.CREATED_STEUERBERATER', {email: this.taxUser.email}));
      }
      this.afterSave.emit();
    }, errorRes => {
      this.logService.log(errorRes);
      const errorMessage = errorRes.error;
      if (errorMessage.errorType && errorMessage.errorType === 'EMAIL_ALREADY_IN_USE_FOR_KUNDE') {
        this.toastr.error(this.translateService.instant('ERROR_MESSAGES.DUPLICATE_EMAIL'));
      }
      if (errorMessage.errorType && errorMessage.errorType === 'EMAIL_ALREADY_IN_USE') {
        this.toastr.error(this.translateService.instant('ERROR_MESSAGES.DUPLICATE_EMAIL'));
      }
      this.toastr.error(this.translateService.instant('ERROR_MESSAGES.CREATE'));
    });
  }

  getUser(): TaxUserDto {
    return this.taxUser;
  }

  setUser(taxConsultantUserDto: TaxUserDto) {
    this.taxUser = taxConsultantUserDto;
  }

}
