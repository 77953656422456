<app-content-header title="{{ 'VIEWS.INVENTORY.TITLE' | translate }}" subtitle="{{'VIEWS.INVENTORY.SPERMA_BESTAND' | translate }}"></app-content-header>

<div class="content-wrapper">
  <div id="status-wrapper" class="mb-2" *ngIf="rows?.length > 0">
    <p>{{ 'VIEWS.INVENTORY.COMPANY_KEY_MESSAGE' | translate:{'companyKey':companyKey} }}
      <br>
      <small>{{ 'VIEWS.INVENTORY.DATE_MESSAGE' | translate:{'date': dateOfInventory| datexpipe:'DD.MM.YYYY'} }}</small>
    </p>
  </div>

  <div *ngIf="!isMobileView()">
    <ngx-datatable class="material" [rows]="rows" [columnMode]="'force'" [headerHeight]="40" [footerHeight]="50" [limit]="10" [rowHeight]="'auto'"
                   [messages]="tableMessages" [reorderable]="false" [selectAllRowsOnPage]="false">
      <ngx-datatable-column name="{{ 'VIEWS.INVENTORY.HERDBUCH_NR' | translate }}" prop="herdbuchNr"></ngx-datatable-column>
      <ngx-datatable-column name="{{ 'VIEWS.INVENTORY.BULLEN_NAME' | translate }}" prop="bulleName"></ngx-datatable-column>
      <ngx-datatable-column name="{{ 'VIEWS.INVENTORY.BEHANDLUNGSARTEN' | translate }}" prop="behandlungsarten"></ngx-datatable-column>
      <ngx-datatable-column name="{{ 'VIEWS.INVENTORY.MENGE_BESTAND' | translate }}" prop="mengeBestand"></ngx-datatable-column>
    </ngx-datatable>
  </div>

  <div *ngIf="isMobileView()">
    <div *ngIf="rows?.length > 0">
      <ng-container *ngFor="let bestand of rows">
        <accordion>
          <accordion-group #accordionGroup [isOpen]="false" class="pointer mb-2">
            <div accordion-heading class="clearfix">
              <div class="row">
                <div class="col">
                  <span>
                    {{ bestand.herdbuchNr}}
                  </span>
                  <small class="listview-subtitle"> {{bestand.bulleName}}</small>
                </div>
                <div class="listview-item-chavron-wrapper">
                  <i class="fa" [ngClass]="{ 'fa-chevron-up': accordionGroup.isOpen , 'fa-chevron-down': !accordionGroup.isOpen}"></i>
                </div>
              </div>
            </div>
            <div class="ml-2">
              <div class="row">
                <small class="col">{{ 'VIEWS.INVENTORY.BEHANDLUNGSARTEN' | translate }}:</small>
                <small class="col">{{bestand.behandlungsarten}}</small>
              </div>
              <div class="row">
                <small class="col">{{ 'VIEWS.INVENTORY.MENGE_BESTAND' | translate }}:</small>
                <small class="col">{{bestand.mengeBestand}}</small>
              </div>
            </div>
          </accordion-group>
        </accordion>
      </ng-container>
    </div>

    <div *ngIf="rows?.length === 0">
      {{getNoDataMessage()}}
    </div>
  </div>

  <div class="pt-2">
    <small>{{ 'VIEWS.INVENTORY.WITHOUT_GUARANTEE' | translate }}</small>
  </div>

</div>
