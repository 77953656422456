import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import * as FileSaver from 'file-saver';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {BsDatepickerConfig, BsLocaleService} from 'ngx-bootstrap/datepicker';
import {deLocale} from 'ngx-bootstrap/locale';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {DocumentDto} from '../../api/dto/document-dto';
import {DocumentFilterDto} from '../../api/dto/document-filter-dto';
import {KundeDto} from '../../api/dto/kunde-dto';
import {PageDto} from '../../api/dto/page-dto';
import {FilterCriteria} from '../../api/dto/sort-filter-paging/filter-criteria';
import {FilterType} from '../../api/dto/sort-filter-paging/filter-type';
import {Page} from '../../api/dto/sort-filter-paging/page';
import {SearchFilterPagingDto} from '../../api/dto/sort-filter-paging/search-filter-paging-dto';
import {SortCriteria} from '../../api/dto/sort-filter-paging/sort-criteria';
import {TaxUserDto} from '../../api/dto/tax-user-dto';
import {DocumentFilterService} from '../../api/services/document-filter.service';
import {DocumentService} from '../../api/services/document.service';
import {FileService} from '../../api/services/file.service';
import {BaseComponent} from '../../core/component/baseComponent';
import {DropdownComponent} from '../../core/component/dropdown/dropdown.component';
import {IDropdownSelectorItem} from '../../core/component/dropdown/i-dropdown-selector-item';
import {LogService} from '../../core/log/LogService';
import {SharedService} from '../../shared.service';
import {FilterItem} from './filter-item';
import VostDocumentGroup = DocumentDto.VostDocumentGroup;
import VostDocumentType = DocumentDto.VostDocumentType;

defineLocale('de', deLocale);

@Component({
  selector: 'app-credit',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('filterMonthDropdown') public dropdownComponentMonth: DropdownComponent<Date>;
  @ViewChild('filterYearDropdown') public dropdownComponentYear: DropdownComponent<Date>;
  @ViewChild(DatatableComponent) table: DatatableComponent;

  tableIsLoading: boolean = true;
  searchFilterPagingDto: SearchFilterPagingDto;
  currentPage: Page<DocumentDto>;
  currentPageBackup: Page<DocumentDto>;
  documentTypeKey: string;
  rowsBackup: DocumentDto[] = [];
  rows: DocumentDto[] = [];
  selectedRows: DocumentDto[] = [];
  customFilterActive: boolean = false;
  filterToogle: boolean = false;
  documentArtChoice: Array<FilterItem<VostDocumentType>> = [];

  belegnummerFilterValue: string = '';
  datePickerConfig: Partial<BsDatepickerConfig>;
  bsRangeValue: Date[];
  yearSelection: number;

  kundenFilterValue: Array<FilterItem<KundeDto>> = [];

  filterDateMonthChoice: Array<IDropdownSelectorItem<Date[]>> = [];
  filterDateYearChoice: Array<IDropdownSelectorItem<number>> = [];

  filterDateMonthInitValue: IDropdownSelectorItem<any> = {
    label: 'Monat auswählen',
    value: undefined,
    id: undefined
  };
  filterDateYearInitValue: IDropdownSelectorItem<number> = {
    label: 'Jahr auswählen',
    value: undefined,
    id: undefined
  };

  routeData: Subscription;
  useElasticSearch: boolean;
  selectedDocumentFilter: string;
  documentFilters: DocumentFilterDto[] = [];

  private DOCUMENT_TYPE_BILL: string = 'BILL';
  private DOCUMENT_TYPE_CREDIT: string = 'CREDIT';
  private DOCUMENT_TYPE_OTHER: string = 'OTHER';
  private UNREAD_DOCUMENTS: string = 'UNREAD';
  private readonly taxUser: TaxUserDto;

  constructor(public activatedRoute: ActivatedRoute, private router: Router, private fileService: FileService, private translateService: TranslateService,
              private toastr: ToastrService, private _localeService: BsLocaleService, private sharedService: SharedService,
              private documentService: DocumentService, private logservice: LogService, private documentFilterService: DocumentFilterService) {
    super(sharedService, translateService);

    if (this.isTaxUser()) {
      this.taxUser = sharedService.currentUser as TaxUserDto;
    }
    this.documentFilterService.findAll().subscribe(res => {
        this.documentFilters = res;
        this.documentFilters.unshift(new DocumentFilterDto('', ''));
      }
    );
  }

  private static createPage(searchResult: PageDto, result: DocumentDto[]): Page<DocumentDto> {
    const page = new Page<DocumentDto>();
    page.content = result;
    page.number = searchResult.number;
    page.numberOfElements = searchResult.totalElements > searchResult.size ? searchResult.size : searchResult.totalElements;
    page.totalElements = searchResult.totalElements;
    page.size = searchResult.size;
    return page;
  }

  ngOnInit(): void {
    this.useElasticSearch = this.sharedService.applicationInformation.useElasticSearch;
    this._localeService.use('de');
    this.searchFilterPagingDto = new SearchFilterPagingDto();
    this.routeData = this.activatedRoute.data
      .subscribe((data: { documents: Page<DocumentDto> }) => {
        this.updatePage(data.documents);
        this.initDocumentTypeKey();
        this.initFilter();
        this.initFilterDateChoice();
      });
  }

  ngAfterViewInit(): void {
    this.dropdownComponentYear.resetSelection2InitValue();
    this.dropdownComponentMonth.resetSelection2InitValue();
  }

  ngOnDestroy(): void {
    this.routeData.unsubscribe();
  }

  initDocumentTypeKey(): void {
    switch (this.activatedRoute.snapshot.params.type) {
      case this.DOCUMENT_TYPE_BILL.toLocaleLowerCase():
        this.documentTypeKey = this.DOCUMENT_TYPE_BILL;
        break;
      case this.DOCUMENT_TYPE_CREDIT.toLocaleLowerCase():
        this.documentTypeKey = this.DOCUMENT_TYPE_CREDIT;
        break;
      case this.UNREAD_DOCUMENTS.toLocaleLowerCase():
        this.documentTypeKey = this.UNREAD_DOCUMENTS;
        break;
      default :
        this.documentTypeKey = this.DOCUMENT_TYPE_OTHER;
    }
  }

  initFilter(): void {
    this.customFilterActive = false;
    this.documentArtChoice = [];
    this.bsRangeValue = [];
    this.belegnummerFilterValue = '';
    this.kundenFilterValue = [];
    this.selectedDocumentFilter = '';

    if (this.documentTypeKey === this.DOCUMENT_TYPE_BILL) {
      this.documentArtChoice.push(new FilterItem(1, this.translateService.instant('VIEWS.DOCUMENT.DOCUMENT_TYPE.ARTIKEL_RECHNUNG'),
        VostDocumentType.ARTIKEL_RECHNUNG, false));
      this.documentArtChoice.push(new FilterItem(2, this.translateService.instant('VIEWS.DOCUMENT.DOCUMENT_TYPE.BESAMUNGS_RECHNUNG'),
        VostDocumentType.BESAMUNGS_RECHNUNG, false));
      this.documentArtChoice.push(new FilterItem(3, this.translateService.instant('VIEWS.DOCUMENT.DOCUMENT_TYPE.VIEH_RECHNUNG'),
        VostDocumentType.VIEH_RECHNUNG, false));
      this.documentArtChoice.push(new FilterItem(4, this.translateService.instant('VIEWS.DOCUMENT.DOCUMENT_TYPE.SONSTIGES'),
        VostDocumentType.SONSTIGES, false));

    } else if (this.documentTypeKey === this.DOCUMENT_TYPE_CREDIT) {
      this.documentArtChoice.push(new FilterItem(1, this.translateService.instant('VIEWS.DOCUMENT.DOCUMENT_TYPE.GUTSCHRIFT'),
        VostDocumentType.GUTSCHRIFT, false));
      this.documentArtChoice.push(new FilterItem(2, this.translateService.instant('VIEWS.DOCUMENT.DOCUMENT_TYPE.VIEH_ABRECHNUNG'),
        VostDocumentType.VIEH_ABRECHNUNG, false));
    } else if (this.documentTypeKey === this.DOCUMENT_TYPE_OTHER) {
      this.documentArtChoice.push(new FilterItem(1, this.translateService.instant('VIEWS.DOCUMENT.DOCUMENT_TYPE.ALLGEMEINE'),
        VostDocumentType.ALLGEMEINE, false));
    }

    if (this.taxUser != null) {
      let id = 1;
      this.taxUser.kunden.forEach(kunde => {
        const name = kunde.vorname + ' ' + kunde.name;
        this.kundenFilterValue.push(new FilterItem<KundeDto>(id, name, kunde, false));
        id++;
      });
    }

    this.customFilterActive = false;
    this.filter();
  }

  filterDocumentType(selectedItem: FilterItem<VostDocumentType>): void {
    selectedItem.isActive = !selectedItem.isActive;
    this.filter(this.bsRangeValue);
  }

  filterFromDateRangePicker(dateRange: Date[]): void {
    this.filter(dateRange);
  }

  filterDateFromDropdownYear(yearSelection: number) {
    this.yearSelection = yearSelection;
    this.dropdownComponentMonth.resetSelection2InitValue();
    this.bsRangeValue = [
      new Date(yearSelection, 0, 1),
      new Date(yearSelection, 11, 31)];
  }

  filterDateFromDropdownMonth(monthRange: Date[]) {
    let startRange: Date = monthRange[0];
    let endRange: Date = monthRange[1];

    if (this.yearSelection) {
      startRange = new Date(startRange.setFullYear(this.yearSelection));
      endRange = new Date(endRange.setFullYear(this.yearSelection));
    }

    this.bsRangeValue = [
      startRange,
      endRange];
  }

  filter(date?: Date[]): void {
    this.customFilterActive = false;
    this.searchFilterPagingDto.filterCriteriaList = [];

    if (date && date.length === 2) {
      this.searchFilterPagingDto.filterCriteriaList.push(new FilterCriteria('createdAt', [
        date[0].getTime(),
        date[1].getTime()], FilterType.DATE_RANGE));
      this.customFilterActive = true;
    }

    if (this.selectedDocumentFilter) {
      this.searchFilterPagingDto.filterCriteriaList.push(new FilterCriteria('filename', this.selectedDocumentFilter, FilterType.STRING));
    }

    if (this.belegnummerFilterValue) {
      this.searchFilterPagingDto.filterCriteriaList.push(new FilterCriteria('belegnummer', this.belegnummerFilterValue, FilterType.STRING));
      this.customFilterActive = true;
    }

    if (this.activatedRoute.snapshot.params.type === this.UNREAD_DOCUMENTS.toLocaleLowerCase()) {
      const unreadFilterCriteria: FilterCriteria = new FilterCriteria('read', false, FilterType.BOOLEAN);
      this.searchFilterPagingDto.filterCriteriaList.push(unreadFilterCriteria);
    }

    this.initActiveDocuentArtChoiceEntries();
    this.kundenFilterValue.forEach(kundeFilter => {
      if (kundeFilter.isActive) {
        this.searchFilterPagingDto.filterCriteriaList.push(new FilterCriteria('kundeId', kundeFilter.data.id, FilterType.ENTITY_FK));
      }
    });

    this.refreshData(this.searchFilterPagingDto);
  }

  sort(event) {
    this.tableIsLoading = true;
    this.searchFilterPagingDto.resetSort();
    this.searchFilterPagingDto.sortCriteriaList.push(new SortCriteria(event.column.prop, event.newValue.toUpperCase()));
    this.searchFilterPagingDto.page = 0;
    this.refreshData(this.searchFilterPagingDto);
  }

  refreshData(searchFilterPagingDto: SearchFilterPagingDto): void {
    this.rows = [];
    this.selectedRows = [];
    this.tableIsLoading = true;
    this.documentService.findAllAsPage(searchFilterPagingDto).subscribe(res => {
      this.updatePage(res);
    }, () => {
      this.tableIsLoading = false;
      this.toastr.error(this.translateService.instant('ERROR_MESSAGES.REFRESH_DATA'));
    });
  }

  updatePage(page: Page<DocumentDto>): void {
    this.rows = page.content;
    this.rowsBackup = this.rows;
    this.searchFilterPagingDto.page = page.number;
    this.currentPage = page;
    this.currentPageBackup = page;
    this.tableIsLoading = false;
  }

  setPage(pageInfo) {
    this.tableIsLoading = true;
    this.searchFilterPagingDto.page = pageInfo.offset;
    this.refreshData(this.searchFilterPagingDto);
  }

  onSelect({selected}): void {
    this.selectedRows = [];
    this.selectedRows.push(...selected);
  }

  downloadSelected(): void {
    if (this.selectedRows.length > 1) {
      const selectedUuids: string[] = [];

      let unreadCounter = 0;
      this.selectedRows.forEach(selectedElement => {
        if (!selectedElement.read) {
          unreadCounter++;
        }
        selectedUuids.push(selectedElement.uuid);
      });

      this.fileService.downloadFilesAsZip(selectedUuids).subscribe(res => {
        this.saveDownload('vost_dokumente.zip', 'application/zip', [res]);
        this.updateReadFlag(this.selectedRows, unreadCounter);
      }, () => {
        this.showFileDownloadErrorMsg();
      });

    } else if (this.selectedRows.length === 1) {
      this.fileService.downloadFile(this.selectedRows[0].uuid).subscribe(res => {
        this.saveDownload(this.selectedRows[0].filename, 'application/pdf', [res]);
        this.updateReadFlag([this.selectedRows[0]], this.selectedRows[0] ? 0 : 1);
        this.selectedRows[0].read = true;
      }, () => {
        this.showFileDownloadErrorMsg();
      });
    }
  }

  downloadDocument(document: DocumentDto): void {

    this.fileService.downloadFile(document.uuid).subscribe(res => {
      const blob: any = new Blob([res], {
        type: document.mimetype
      });
      FileSaver.saveAs(blob, document.filename);
      this.toastr.info(this.translateService.instant('SUCCESS_MESSAGES.PDF_FILE_DOWNLOADED'), '', {timeOut: 30000});
      this.updateReadFlag([document], document.read ? 0 : 1);
    }, () => {
      this.showFileDownloadErrorMsg();
    });
  }

  saveDownload(filename: string, type: string, res: any[]) {
    const file = new Blob(res, {type});
    FileSaver.saveAs(file, filename);
  }

  unreadDocumentsAvailable(): boolean {
    return this.documentTypeKey === this.DOCUMENT_TYPE_BILL ? this.sharedService.unreadRechnungenCount > 0 : this.sharedService.unreadGutschriftenCount > 0;
  }

  markAllAsRead(): void {
    let documentGroup = VostDocumentGroup.ALLE;
    switch (this.documentTypeKey) {
      case this.DOCUMENT_TYPE_BILL:
        documentGroup = VostDocumentGroup.RECHNUNG;
        break;
      case this.DOCUMENT_TYPE_CREDIT:
        documentGroup = VostDocumentGroup.ABRECHNUNG;
        break;
      default :
        break;
    }
    this.documentService.markAllDocumentAsRead(documentGroup).subscribe(() => {
      this.toastr.success(this.translateService.instant('SUCCESS_MESSAGES.MARK_ALL_AS_READ'));
      this.sharedService.resetCounter(documentGroup);
      this.updateReadFlag(this.rows, 0, true);
    });
  }

  getDocumentGroup(): VostDocumentGroup {
    let docGroup = VostDocumentGroup.ALLE;
    if (this.documentTypeKey === this.DOCUMENT_TYPE_BILL) {
      docGroup = VostDocumentGroup.RECHNUNG;
    } else if (this.documentTypeKey === this.DOCUMENT_TYPE_CREDIT) {
      docGroup = VostDocumentGroup.ABRECHNUNG;
    }
    return docGroup;
  }

  onSearchEvent(searchResult: PageDto) {
    if (searchResult && searchResult.size > 0) {
      const ids = searchResult.content.filter(d => d.documentId !== null).map(d => d.documentId);
      if (ids && ids.length > 0) {
        this.documentService.findAllByUuid(ids).subscribe(result => {
          this.selectedRows = [];
          this.rows = result;
          this.currentPage = DocumentComponent.createPage(searchResult, result);
        }, errorRes => {
          this.searchEmpty(false);
          this.logservice.log(errorRes);
          this.toastr.error(this.translateService.instant('ERROR_MESSAGES.SEARCH_FAILED'));
        });
      } else {
        this.searchEmpty(false);
      }
    } else {
      this.searchEmpty(true);
    }
  }

  isTaxUser(): boolean {
    return this.sharedService.isTaxUser();
  }

  filterKunde(kundeFilter: FilterItem<KundeDto>): void {
    this.kundenFilterValue.forEach(kundeFilterVal => {
      if (kundeFilterVal.id !== kundeFilter.id) {
        kundeFilterVal.isActive = false;
      }
    });
    kundeFilter.isActive = !kundeFilter.isActive;
    this.filter(this.bsRangeValue);
  }

  showResetSortButton(): boolean {
    return this.searchFilterPagingDto.hasSort();
  }

  resetCurrentSort(): void {
    this.searchFilterPagingDto.resetSort();
    this.searchFilterPagingDto.page = 0;
    this.table.sorts = [];
    this.refreshData(this.searchFilterPagingDto);
  }

  showDokumenteFilterView() {
    return this.documentTypeKey === this.DOCUMENT_TYPE_OTHER && this.documentFilters && this.documentFilters.length > 0;
  }

  private initActiveDocuentArtChoiceEntries() {
    const activeDocumentArtChoiceEntries: VostDocumentType[] = [];
    this.documentArtChoice.forEach(documentArtChoiceEntry => {
      if (documentArtChoiceEntry.isActive) {
        activeDocumentArtChoiceEntries.push(documentArtChoiceEntry.data);
        if (documentArtChoiceEntry.data === VostDocumentType.SONSTIGES) {
          activeDocumentArtChoiceEntries.push(VostDocumentType.MEDIKAMENT_RECHNUNG);
        } else if (documentArtChoiceEntry.data === VostDocumentType.ALLGEMEINE) {
          activeDocumentArtChoiceEntries.push(VostDocumentType.ALLGEMEINE);
        }
      }
    });
    if (activeDocumentArtChoiceEntries.length > 0) {
      this.searchFilterPagingDto.filterCriteriaList.push(new FilterCriteria('documentType', activeDocumentArtChoiceEntries, FilterType.ENUM));
      this.customFilterActive = this.customFilterActive || this.searchFilterPagingDto.filterCriteriaList.length > 0;
    } else {
      this.documentArtChoice.forEach(documentArtChoiceEntry => {
        activeDocumentArtChoiceEntries.push(documentArtChoiceEntry.data);
        if (documentArtChoiceEntry.data === VostDocumentType.SONSTIGES) {
          activeDocumentArtChoiceEntries.push(VostDocumentType.MEDIKAMENT_RECHNUNG);
        } else if (documentArtChoiceEntry.data === VostDocumentType.ALLGEMEINE) {
          activeDocumentArtChoiceEntries.push(VostDocumentType.ALLGEMEINE);
        }
      });

      this.customFilterActive = this.customFilterActive || this.searchFilterPagingDto.filterCriteriaList.length > 0;
      this.searchFilterPagingDto.filterCriteriaList.push(new FilterCriteria('documentType', activeDocumentArtChoiceEntries, FilterType.ENUM));
    }
  }

  private initFilterDateChoice(): void {
    this.filterDateMonthChoice = [];
    this.filterDateYearChoice = [];
    const today = new Date();
    const monthNames: string[] = [
      'JANUARY',
      'FEBRUARY',
      'MARCH',
      'APRIL',
      'MAY',
      'JUNE',
      'JULY',
      'AUGUST',
      'SEPTEMBER',
      'OCTOBER',
      'NOVEMBER',
      'DECEMBER'];

    for (let month = 0; month < 12; ++month) {
      const translatedMonth = this.getMessage('MONTH.' + monthNames[month]);
      this.filterDateMonthChoice.push({
        id: translatedMonth,
        label: translatedMonth,
        value: [
          new Date(today.getFullYear(), month, 1, 0, 0, 0, 0),
          new Date(today.getFullYear(), month + 1, 0, 23, 59, 59, 0)]
      });
    }

    const currentYear: number = today.getFullYear();
    for (let counter = 0; counter <= 10; ++counter) {
      this.filterDateYearChoice.push({
        id: counter.toString(),
        label: (currentYear - counter).toString(),
        value: currentYear - counter
      });
    }
  }

  private updateReadFlag(documents: DocumentDto[], unreadLength: number, readAll: boolean = false): void {
    if (this.documentTypeKey === this.UNREAD_DOCUMENTS) {
      this.setReadTrueAndRefreshCounters(documents);
    } else {
      documents.forEach(elem => {
        this.rows.forEach(rowElem => {
          if (elem.uuid === rowElem.uuid) {
            rowElem.read = true;
          }
        });
      });

      if (readAll) {
        this.sharedService.setUnreadTotalCountToZero();
      }
      if (this.documentTypeKey === this.DOCUMENT_TYPE_BILL) {
        this.sharedService.decreaseUnreadRechnungCount(unreadLength);
      } else if (this.documentTypeKey === this.DOCUMENT_TYPE_CREDIT) {
        this.sharedService.decreaseUnreadGutschriftCount(unreadLength);
      } else {
        this.sharedService.decreaseUnreadOtherCount(unreadLength);
      }
    }
  }

  private setReadTrueAndRefreshCounters(documents: DocumentDto[]): void {
    let countRechnung = 0;
    let countGutschrift = 0;
    let countSonstige = 0;
    documents.forEach(elem => {
      this.rows.forEach(rowElem => {
        if (elem.uuid === rowElem.uuid) {
          rowElem.read = true;
          switch (elem.documentGroup) {
            case VostDocumentGroup.RECHNUNG:
              countRechnung++;
              break;
            case VostDocumentGroup.ABRECHNUNG:
              countGutschrift++;
              break;
            case VostDocumentGroup.ALLE:
              countSonstige++;
              break;
          }
        }
      });
    });
    this.sharedService.decreaseUnreadTotalCount(countRechnung, countGutschrift, countSonstige);
  }

  private showFileDownloadErrorMsg(): void {
    this.toastr.error(this.translateService.instant('ERROR_MESSAGES.ERROR_DOWNLOADING_DOCUMENTS'));
  }

  private searchEmpty(resetSearch: boolean) {
    this.selectedRows = [];
    if (resetSearch) {
      this.rows = this.rowsBackup;
      this.currentPage = this.currentPageBackup;
    } else {
      this.rows = [];
      this.currentPage = new Page();
      this.toastr.success(this.translateService.instant('ERROR_MESSAGES.SEARCH_EMPTY'));
    }
  }
}
