import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {InventoryDto} from '../dto/inventory-dto';
import {JsonHttpService} from './base/json-http';

@Injectable()
export class InventoryService {
  public static apiUrl: string = '/api/bestand';

  constructor(private jsonHttpService: JsonHttpService) {
  }

  public findAll(): Observable<InventoryDto[]> {
    return this.jsonHttpService.get<InventoryDto[]>(InventoryService.apiUrl + '/findAll');
  }
}
