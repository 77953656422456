import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {JsonHttpService} from './base/json-http';

@Injectable()
export class FileService {
  public static apiUrl: string = '/api/files';

  constructor(private jsonHttpService: JsonHttpService) {
  }

  public downloadFile(uuid: string): Observable<ArrayBuffer> {
    return this.jsonHttpService.getAsArrayBuffer(FileService.apiUrl + '/download?uuid=' + uuid);
  }

  public downloadFilesAsZip(uuids: string[]): Observable<ArrayBuffer> {
    return this.jsonHttpService.getAsArrayBuffer(FileService.apiUrl + '/downloadAsZip?uuids=' + uuids);
  }

}



