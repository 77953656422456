import {HttpClient, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NouisliderModule} from 'ng2-nouislider';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {ToastrModule} from 'ngx-toastr';
import {AppComponent} from './app.component';
import {ROUTES} from './app.routes';
import {AcceptableDialogComponent} from './core/component/acceptable-dialog/acceptable-dialog.component';
import {CoreModule} from './core/core.module';
import {DocumentFindAllResolver} from './core/resolver/document-findAll.resolver';
import {InventoryFindAllResolver} from './core/resolver/inventory-findAll.resolver';
import {UserFindAllResolver} from './core/resolver/user-findAll.resolver';
import {SharedService} from './shared.service';
import {AdminConsoleComponent} from './views/admin-console/admin-console.component';
import {DashboardComponent} from './views/dashboard/dashboard.component';
import {DocumentSearchComponent} from './views/document-search/document-search.component';
import {DocumentComponent} from './views/document/document.component';
import {InitialLoginComponent} from './views/initial-login/initial-login.component';
import {InventoryComponent} from './views/inventory/inventory.component';
import {LoginComponent} from './views/login/login.component';
import {NewTaxUserComponent} from './views/new-tax-user/new-tax-user.component';
import {NewUserComponent} from './views/new-user/new-user.component';
import {ResetPwdComponent} from './views/reset-pwd/reset-pwd.component';
import {TaxUserDetailComponent} from './views/tax-user-detail/tax-user-detail.component';
import {UserDetailComponent} from './views/user-detail/user-detail.component';

export function translateLoader(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        DashboardComponent,
        UserDetailComponent,
        DocumentComponent,
        InitialLoginComponent,
        AdminConsoleComponent,
        InventoryComponent,
        ResetPwdComponent,
        AcceptableDialogComponent,
        NewUserComponent,
        DocumentSearchComponent,
        TaxUserDetailComponent,
        NewTaxUserComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        NgxDatatableModule,
        BrowserAnimationsModule,
        NgxChartsModule,
        NouisliderModule,
        ModalModule.forRoot(),
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        AccordionModule.forRoot(),
        ToastrModule.forRoot(),
        RouterModule.forRoot(ROUTES, { useHash: true }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (translateLoader),
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        SharedService,
        DocumentFindAllResolver,
        UserFindAllResolver,
        InventoryFindAllResolver
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
