import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {PageDto} from '../dto/page-dto';
import {UserDto} from '../dto/user-dto';
import {JsonHttpService} from './base/json-http';

@Injectable()
export class SearchService {
  public static apiUrl: string = '/api/search/doc';

  constructor(private jsonHttpService: JsonHttpService) {
  }

  findAll(query: string, documentGroup: string): Observable<PageDto> {
    return this.jsonHttpService.get<PageDto>(SearchService.apiUrl + '?g=' + documentGroup + '&q=' + query);
  }
}

