import {AfterViewInit, Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NewsService} from '../../api/services/news.service';
import {UserService} from '../../api/services/user.service';
import {AppComponent} from '../../app.component';
import {AcceptableDialogComponent} from '../../core/component/acceptable-dialog/acceptable-dialog.component';
import {BaseComponent} from '../../core/component/baseComponent';
import {LogService} from '../../core/log/LogService';
import {DatexPipe} from '../../core/pipes/datex.pipe';
import {SharedService} from '../../shared.service';
import {DashboardInformation} from './dashboard-information';
import {BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends BaseComponent implements OnInit, AfterViewInit {
  dashboardItems: DashboardInformation[] = [];
  newsFeedAvailable: boolean;

  constructor(private translateService: TranslateService, private sharedService: SharedService, private newsService: NewsService,
              private logService: LogService, private datexPipe: DatexPipe, private userService: UserService,
              private modalService: BsModalService, private appComponentHeader: AppComponent) {

    super(sharedService, translateService);
  }

  ngOnInit() {
    this.newsFeedAvailable = true;
    this.newsService.getNewsFeed().subscribe(res => {
      if (res.length === 0) {
        this.generateFallbackDashboardItems();
      } else {
        this.dashboardItems = [];
        res.forEach(elem => {
          this.dashboardItems.push(new DashboardInformation(elem.imageUrl,
            (this.datexPipe.transform(elem.publishedDate, 'DD.MM.YYYY') + ' ' + elem.title),
            elem.description, elem.url));
        });
      }
    }, errorRes => {
      this.generateFallbackDashboardItems();
      this.logService.log(errorRes);
    });
  }

  getUsername(): string {
    return this.sharedService.getUsernameAsString();
  }

  getUnreadDocumentCount(): number {
    return this.sharedService.unreadDocumentCount;
  }

  ngAfterViewInit(): void {
    this.showTermsOfSevice();
  }

  unreadLinkClicked(): void {
    this.appComponentHeader.homeNavigationItem.isActive = false;
  }

  private showTermsOfSevice(): void {
    if (!this.sharedService.isAdmin() && !this.sharedService.currentUser.useConditionsAccepted) {
      const initialState = {
        callback: {
          accept: () => {
            this.userService.setUseConditionsAccepted().subscribe(setUseConditionsAcceptedRes => {
              this.sharedService.currentUser.useConditionsAccepted = setUseConditionsAcceptedRes;
            }, error => {
              this.logService.log(error);
            });
          }
        },
        title: this.getMessage('GLOBAL_MESSAGES.TERMS_OF_SERVICE'),
        text: this.sharedService.applicationInformation.termsOfServiceAsHtml
      };
      this.modalService.show(AcceptableDialogComponent, {
        initialState,
        animated: true,
        keyboard: false,
        backdrop: false,
        ignoreBackdropClick: true
      });
    }
  }

  private generateFallbackDashboardItems(): void {
    this.newsFeedAvailable = false;
    this.dashboardItems.push(new DashboardInformation('assets/images/dashboard/Holli0358354957Godewind_1.jpg',
        '1082. Auktion', 'Katalog für den 15. Mai 2018 jetzt online!',
        'http://www.vostov.de/?m=1&sid=q5ksrfkrhqm8aisu24cg9p0u6fa57det&hid=80&bid=1016'),

      new DashboardInformation('assets/images/dashboard/Noel156580CharleyMotivfront.jpg',
        'Bullenkarte April', 'Jetzt die Online-Version ansehen',
        'http://www.vostov.de/?m=1&sid=q5ksrfkrhqm8aisu24cg9p0u6fa57det&hid=80&bid=1011'),

      new DashboardInformation('assets/images/dashboard/Noellow.jpg', 'Noel jetzt gesext verfügbar',
        'Simox und Khedira ab 15. Mai gesext im Einsatz',
        'http://www.vostov.de/?m=1&sid=q5ksrfkrhqm8aisu24cg9p0u6fa57det&hid=80&bid=1012'),

      new DashboardInformation('assets/images/dashboard/BeratungsgesprchEBBDennisHavemann.jpg',
        'Stellenausschreibung', 'Fachberater/in im EBB-Service',
        'http://www.vostov.de/?m=1&sid=q5ksrfkrhqm8aisu24cg9p0u6fa57det&hid=80&bid=1009'),

      new DashboardInformation('assets/images/dashboard/IMG20180416WA0000.jpg', 'Frühlingsfest der Kühe',
        '2. Auflage mit ca. 1.000 Besuchern ein voller Erfolg',
        'http://www.vostov.de/?m=1&sid=q5ksrfkrhqm8aisu24cg9p0u6fa57det&hid=80&bid=1007'),

      new DashboardInformation('assets/images/dashboard/GruppenfotoBezirksentscheid.jpg',
        'Bezirksentscheid', 'Wittmunder Jungzüchter räumen ab',
        'http://www.vostov.de/?m=1&sid=q5ksrfkrhqm8aisu24cg9p0u6fa57det&hid=80&bid=1008'));
  }
}
