import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {TaxUserDto} from '../../api/dto/tax-user-dto';
import {UserDto} from '../../api/dto/user-dto';
import {TaxUserService} from '../../api/services/tax.user.service';
import {UserService} from '../../api/services/user.service';
import {LogService} from '../../core/log/LogService';
import {SharedService} from '../../shared.service';

@Component({
  selector: 'app-initial-login',
  templateUrl: './initial-login.component.html',
  styleUrls: ['./initial-login.component.css']
})
export class InitialLoginComponent implements OnInit {
  currentUser: UserDto | TaxUserDto;
  isNewPasswordEquals: boolean = true;
  isNewPassValid: boolean = false;
  usernamePattern = '[a-zA-Z]+';
  displayUsername = '';
  isInitialLoginForTaxUser = true;

  constructor(private logService: LogService, private sharedService: SharedService, private userService: UserService, private router: Router,
              private toastrService: ToastrService, private translateService: TranslateService, private taxUserService: TaxUserService) {
    this.currentUser = sharedService.currentUser;
    if (this.isTaxUserNameValid()) {
      this.isInitialLoginForTaxUser = false;
    }
  }

  ngOnInit() {
    if (!this.sharedService.isSignedIn()) {
      this.router.navigateByUrl('/login');
    }
  }

  saveUser(): void {
    this.isUserNameInUse(this.currentUser.username);
  }

  passwordChangeValid(): boolean {
    return !(!this.currentUser.password || !this.currentUser.passwordNewOne || !this.currentUser.passwordNewTwo) && this.isNewPassValid;
  }

  checkNewPasswordEquals(): boolean {
    return this.currentUser.passwordNewOne === this.currentUser.passwordNewTwo;
  }

  setNewPasswordValid(valid: boolean): void {
    this.isNewPassValid = valid;
  }

  isTaxUser(): boolean {
    return this.sharedService.isTaxUser();
  }

  isTaxUserNameValid(): boolean {
    return this.currentUser && this.currentUser.username !== this.currentUser.email;
  }

  private isUserNameInUse(value: any) {
    if (this.isTaxUser()) {
      this.currentUser.username = this.displayUsername;
      this.taxUserService.isUserNameInUse(this.currentUser).subscribe(res => {
        this.updateUser();
      }, errorRes => {
        this.logService.log(errorRes);
        this.toastrService.error(this.translateService.instant('ERROR_MESSAGES.USERNAME_ALREADY_IN_USE'));
      });
    } else {
      this.updateUser();
    }
  }

  private updateUser() {
    this.isNewPasswordEquals = this.checkNewPasswordEquals();
    if (this.currentUser.password && this.isNewPasswordEquals) {
      this.userService.update(this.currentUser).subscribe(res => {
        this.sharedService.currentUser = res;
        this.router.navigateByUrl('/dashboard');
        this.toastrService.success(this.translateService.instant('SUCCESS_MESSAGES.PASSWORD_CHANGED'));
      }, errorRes => {
        this.logService.log(errorRes);
        this.toastrService.error(this.translateService.instant('ERROR_MESSAGES.CHANGING_PASSWORD'));
      });
    } else {
      this.isNewPasswordEquals = false;
    }
  }

  getErrorMessageForUsername(userName: string) {
    const regex = /\d/g;
    if (regex.test(userName)) {
      return 'VALIDATION_MESSAGES.USERNAME_NO_DIGITS';
    }
    if (userName != null && userName.indexOf(' ') < 0) {
      return 'VALIDATION_MESSAGES.USERNAME_INVALID';
    }
    if (!this.isTaxUserNameValid()) {
      return 'VALIDATION_MESSAGES.USERNAME_EMAIL_NOT_EQUALS';
    }
    return 'VALIDATION_MESSAGES.USERNAME_NO_WHITESPACE';
  }

}
