import {Routes} from '@angular/router';
import {CanActivateAdminGuard} from './core/guard/canActivateAdminGuard';
import {CanActivateGuard} from './core/guard/canActivateGuard';
import {DocumentFindAllResolver} from './core/resolver/document-findAll.resolver';
import {InventoryFindAllResolver} from './core/resolver/inventory-findAll.resolver';
import {UserFindAllResolver} from './core/resolver/user-findAll.resolver';
import {AdminConsoleComponent} from './views/admin-console/admin-console.component';
import {DashboardComponent} from './views/dashboard/dashboard.component';
import {DocumentComponent} from './views/document/document.component';
import {InitialLoginComponent} from './views/initial-login/initial-login.component';
import {InventoryComponent} from './views/inventory/inventory.component';
import {LoginComponent} from './views/login/login.component';
import {ResetPwdComponent} from './views/reset-pwd/reset-pwd.component';
import {UserDetailComponent} from './views/user-detail/user-detail.component';

export const ROUTES: Routes = [

  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'reset-pwd',
    component: ResetPwdComponent
  },
  {
    path: 'initial-login',
    component: InitialLoginComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [CanActivateGuard]
  },
  {
    path: 'document/:type',
    component: DocumentComponent,
    canActivate: [CanActivateGuard],
    resolve: {documents: DocumentFindAllResolver}
  },
  {
    path: 'inventory',
    component: InventoryComponent,
    canActivate: [CanActivateGuard],
    resolve: {inventories: InventoryFindAllResolver}
  },
  {
    path: 'user-detail',
    component: UserDetailComponent,
    canActivate: [CanActivateGuard]
  },
  {
    path: 'admin-console',
    component: AdminConsoleComponent,
    canActivate: [
      CanActivateGuard,
      CanActivateAdminGuard],
    resolve: {users: UserFindAllResolver}
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  }];




