import {Component, EventEmitter, forwardRef, HostListener, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';


/* tslint:disable */
const UI_SWITCH_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UiSwitchComponent),
  multi: true
};

/* tslint:enable */

@Component({
  selector: 'app-ui-switch',
  template: `
    <span class="switch" [class.checked]="checked" [class.disabled]="disabled" [class.switch-large]="size === 'large'" [class.switch-medium]="size === 'medium'"
          [class.switch-small]="size === 'small'" [style.background-color]="getColor()" [style.border-color]="getColor('borderColor')">
      <small [style.background]="getColor('switchColor')"></small>
    </span>
    <span [style.vertical-align]="'super'">{{text}}</span>
  `,
  styles: [`
    .switch {
      background: #f00;
      border: 1px solid #dfdfdf;
      position: relative;
      display: inline-block;
      box-sizing: content-box;
      overflow: visible;
      padding: 0;
      margin: 0;
      cursor: pointer;
      box-shadow: rgb(223, 223, 223) 0 0 0 0 inset;
      transition: 0.3s ease-out all;
      -webkit-transition: 0.3s ease-out all;
    }

    small {
      border-radius: 100%;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.3s ease-out all;
      -webkit-transition: 0.3s ease-out all;
    }

    .switch-large {
      width: 66px;
      height: 40px;
      border-radius: 40px;
    }

    .switch-large small {
      width: 40px;
      height: 40px;
    }

    .switch-medium {
      width: 50px;
      height: 30px;
      border-radius: 30px;
    }

    .switch-medium small {
      width: 30px;
      height: 30px;
    }

    .switch-small {
      width: 33px;
      height: 20px;
      border-radius: 20px;
    }

    .switch-small small {
      width: 20px;
      height: 20px;
    }

    .checked {
      background: rgb(100, 189, 99);
      border-color: rgb(100, 189, 99);
    }

    .switch-large.checked small {
      left: 26px;
    }

    .switch-medium.checked small {
      left: 20px;
    }

    .switch-small.checked small {
      left: 13px;
    }

    .disabled {
      opacity: .50;
      cursor: not-allowed;
    }
  `], /* tslint:disable */
  providers: [UI_SWITCH_CONTROL_VALUE_ACCESSOR]
  /* tslint:enable */
})
export class UiSwitchComponent implements ControlValueAccessor {
  @Input() size: string;
  @Output() change = new EventEmitter<boolean>();
  @Input() color: string;
  @Input() switchOffColor: string;
  @Input() switchColor: string;
  @Input() text: string;
  defaultBgColor: string;
  defaultBoColor: string;
  private isChecked: boolean;
  private isDisabled: boolean;
  private isReverse: boolean;

  /* tslint:disable */
  private onTouchedCallback = (v: any) => {
  };
  private onChangeCallback = (v: any) => {
  };

  /* tslint:enable */

  constructor() {
    this.size = 'medium';
    this.color = 'rgb(100, 189, 99)';
    this.switchOffColor = '';
    this.switchColor = '#fff';
    this.defaultBgColor = '#fff';
    this.defaultBoColor = '#dfdfdf';
  }

  get checked() {
    return this.isChecked;
  }

  @Input() set checked(v: boolean) {
    this.isChecked = v !== false;
  }

  get disabled() {
    return this.isDisabled;
  }

  @Input() set disabled(v: boolean) {
    this.isDisabled = v !== false;
  }

  get reverse() {
    return this.isReverse;
  }

  @Input() set reverse(v: boolean) {
    this.isReverse = v !== false;
  }

  /* tslint:disable-next-line */
  getColor(flag?) {
    if (flag === 'borderColor') {
      return this.defaultBoColor;
    }
    if (flag === 'switchColor') {
      if (this.reverse) {
        return !this.checked ? this.switchColor : this.switchOffColor || this.switchColor;
      } else {
        return this.checked ? this.switchColor : this.switchOffColor || this.switchColor;
      }
    }
    if (this.reverse) {
      return !this.checked ? this.color : this.defaultBgColor;
    }
    return this.checked ? this.color : this.defaultBgColor;
  }

  @HostListener('click') onToggle() {
    if (this.disabled) {
      return;
    }
    this.checked = !this.checked;
    this.change.emit(this.checked);
    this.onChangeCallback(this.checked);
    this.onTouchedCallback(this.checked);
  }

  writeValue(obj: any): void {
    if (obj !== this.checked) {
      this.checked = !!obj;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
